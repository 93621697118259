import { SearchClient } from 'typesense';

export const typesense = new SearchClient({
  nearestNode: {
    host: 'fpxjn9o34qewa60yp.a1.typesense.net',
    port: 443,
    protocol: 'https',
  },
  nodes: [
    {
      host: 'fpxjn9o34qewa60yp-1.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: 'fpxjn9o34qewa60yp-2.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: 'fpxjn9o34qewa60yp-3.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
  ],
  apiKey: 'hJHCyWQe1wUHRPuC4ZOj7N2M4kaDgYhy',
  connectionTimeoutSeconds: 10
});