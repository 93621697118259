import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  height?: number;
  width?: number;
  fillColor?: string;
}

export default function LockSVG({ height = 24, width = 24, fillColor = COLORS.copyDark }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 15'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2247 5.33073H9.55802V3.9974C9.55802 2.1574 8.06469 0.664062 6.22469 0.664062C4.38469 0.664062 2.89136 2.1574 2.89136 3.9974V5.33073H2.22469C1.49136 5.33073 0.891357 5.93073 0.891357 6.66406V13.3307C0.891357 14.0641 1.49136 14.6641 2.22469 14.6641H10.2247C10.958 14.6641 11.558 14.0641 11.558 13.3307V6.66406C11.558 5.93073 10.958 5.33073 10.2247 5.33073ZM4.22469 3.9974C4.22469 2.89073 5.11802 1.9974 6.22469 1.9974C7.33136 1.9974 8.22469 2.89073 8.22469 3.9974V5.33073H4.22469V3.9974ZM9.55802 13.3307H2.89136C2.52469 13.3307 2.22469 13.0307 2.22469 12.6641V7.33073C2.22469 6.96406 2.52469 6.66406 2.89136 6.66406H9.55802C9.92469 6.66406 10.2247 6.96406 10.2247 7.33073V12.6641C10.2247 13.0307 9.92469 13.3307 9.55802 13.3307ZM6.22469 11.3307C6.95802 11.3307 7.55802 10.7307 7.55802 9.9974C7.55802 9.26406 6.95802 8.66406 6.22469 8.66406C5.49136 8.66406 4.89136 9.26406 4.89136 9.9974C4.89136 10.7307 5.49136 11.3307 6.22469 11.3307Z'
        fill={fillColor}
      />
    </svg>
  );
}

