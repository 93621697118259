import { pluralize } from "./string";

export function fromNow(date: Date) {
  const today = new Date();
  const milliseconds_1 = date.getTime();
  const milliseconds_2 = today.getTime();
  const diff = milliseconds_2 - milliseconds_1;
  const seconds = diff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;
  const years = months / 12;

  if (years >= 1) {
    return `${Math.floor(years)} ${pluralize('year', Math.floor(years))} ago`;
  }

  if (months >= 1) {
    return `${Math.floor(months)} ${pluralize('month', Math.floor(months))} ago`;
  }

  if (days >= 1) {
    return `${Math.floor(days)} ${pluralize('day', Math.floor(days))} ago`;
  }

  if (hours >= 1) {
    return `${Math.floor(hours)} ${pluralize('hour', Math.floor(hours))} ago`;
  }

  if (minutes >= 1) {
    return `${Math.floor(minutes)} ${pluralize('minute', Math.floor(minutes))} ago`;
  }

  return 'just now';
}

interface FormatDateOptions {
  date?: Date | null;
  format: 'MM DD YYYY' | 'MM D YYYY' | 'D MM YYYY';
  includeTime?: boolean;
}
export function formatDate({ date, format, includeTime = false }: FormatDateOptions): string {
  if (!date || isNaN(date.getTime()) || !(date instanceof Date)) return '';
  let options = {};
  let formattedDate = '';

  switch (format) {
    case 'MM DD YYYY':
      options = { month: 'short', day: '2-digit', year: 'numeric' };
      formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      break;
    case 'MM D YYYY':
      options = { month: 'short', day: 'numeric', year: 'numeric' };
      formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      break;
    case 'D MM YYYY':
      options = { day: 'numeric', month: 'short', year: 'numeric' };
      formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      // Re-arrange the date components
      const parts = formattedDate.replace(',', '').split(' ');
      formattedDate = `${parts[1]} ${parts[0]}, ${parts[2]}`;
      break;
    default:
      throw new Error(`Unsupported date format: ${format}`);
  }

  if (includeTime) {
    formattedDate += ` ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  return formattedDate;
}

export function daysFrom(date1: Date, date2: Date) {
  if (!date1 || !date2) return 0;
  const fromDate = new Date(date1);
  const toDate = new Date(date2);

  const diffInTime = toDate.getTime() - fromDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  return diffInDays;
}

export function timeUntil({
  fromDate = new Date(),
  toDate,
  format = 'timestamp'
}: {
  fromDate?: Date,
  toDate: Date;
  format?: 'readable' | 'timestamp';
}) {
  if (!(fromDate instanceof Date) || !(toDate instanceof Date)) {
    return 0;
  }

  if (toDate < fromDate) {
    return 0;
  }

  const diffInTime = toDate.getTime() - fromDate.getTime();

  if (format === 'readable') {
    const diffInMinutes = Math.floor(diffInTime / (1000 * 60));
    const diffInHours = Math.floor(diffInTime / (1000 * 3600));
    const diffInDays = Math.floor(diffInHours / 24);

    const showHours = diffInHours % 24 > 0 && diffInDays < 3;
    const showMinutes = diffInMinutes % 60 > 0 && diffInHours < 5;

    let readableDiff = '';
    if (diffInDays > 0) {
      readableDiff += `${diffInDays} ${pluralize('day', diffInDays)}`;
      if (showHours) {
        readableDiff += ', ';
      }
    }
    if (showHours) {
      readableDiff += `${diffInHours % 24} ${pluralize('hour', diffInHours % 24)}`;
      if (showMinutes) {
        readableDiff += ', ';
      }
    }
    if (showMinutes) {
      readableDiff += `${diffInMinutes % 60} ${pluralize('minute', diffInMinutes % 60)}`;
    }

    return readableDiff;
  }

  const diffInMilliseconds = diffInTime;
  return diffInMilliseconds;
}

export function dateStringToYear(dateString: string) {
  // comes in format YYYY-MM-DD
  return dateString?.split?.('-')?.[0] || '';
}