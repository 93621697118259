import COLORS from '~/lib/helpers/color-helper';

interface Props {
  width?: number;
  height?: number;
  strokeColor?: string;
}
export default function HamburgerMenuIcon({ width = 22, height = 18, strokeColor = COLORS.grey }: Props) {
  return (
    <svg width={width} height={height} viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 9H21M1 1.5H21M1 16.5H21'
        stroke={strokeColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
