import classNames from 'classnames';
import styles from '~/styles/components/spinner.module.scss';

interface SpinnerProps {
  size?: 'small' | 'base' | 'large'
}
export default function Spinner({ size }: SpinnerProps) {
  return (
    <div className={classNames(styles.spinner, {
      [styles['spinner--small']]: size === 'small',
      [styles['spinner--large']]: size === 'large',
    })} />
  );
}
