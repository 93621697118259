'use client';

import { ICartItem } from "~/clients/types";
import { BookCard } from "./sold-by-card";
import styles from '../../styles/components/cart-components/sold-books.module.scss';
import { useMemo } from "react";
import useCart from "~/lib/hooks/use-cart";
import Button from "../button";
import Links from "~/lib/helpers/link-helper";
import { useGlobalState } from "~/state";
import { ActionType } from "~/state/types";

interface SoldBooksProps {
  items: ICartItem[];
  removeFromCart: (item: ICartItem) => Promise<void>;
  type: 'buy-now' | 'checkout';
}
export default function SoldBooks({
  items,
  removeFromCart,
  type
}: SoldBooksProps) {

  const { state, dispatch } = useGlobalState();

  const { cart } = useCart();

  const soldCount = useMemo(() => (items.filter(item => item.sold)).filter(item => Boolean(cart.find(b => b.id === item.id))).length, [items, cart]);

  return (
    <div>
      {soldCount === 0 ?
        <>
          <div className={styles.text}>
            {type === 'checkout' ?
              'We made the swap. You can now head to checkout.'
              :
              'We made the swap. Check your cart to confirm.'
            }
          </div>
          <Button
            text={type === 'checkout' ? 'Go to Checkout' : 'Close'}
            onPress={() => {
              dispatch({ type: ActionType.FLUSH_MODAL_DATA });
            }}
            link={type === 'checkout' && {
              href: Links.checkout({ ids: cart.map((item) => item.id), cid: state?.user?.data?.uid }).url
            }}
          />
        </>
        :
        <>
          {soldCount > 1 ?
            <div className={styles.text}>
              Some books in your cart are no longer available. Please remove or replace them before continuing.
            </div>
            :
            <div className={styles.text}>
              A book in your cart is no longer available. Please remove or replace it before continuing.
            </div>}
        </>
      }
      {items.map(item => {
        if (item.sold && Boolean(cart.find(b => b.id === item.id))) {
          return (
            <BookCard
              key={item.id}
              item={item}
              numItems={items.length}
              showBuyNow={false}
              removeFromCart={removeFromCart}
            />
          );
        }
        return null;
      })}
    </div>
  );
}