import { useState, useEffect } from 'react';
import Router from 'next/router';

export default function useRouteIsInTransition() {
  const [isInTransition, setIsInTransition] = useState(false);

  useEffect(() => {
    const start = () => {
      if (!isInTransition) {
        setIsInTransition(true);
      }
    };
    const end = () => {
      if (isInTransition) {
        setIsInTransition(false);
      }
    };

    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, [isInTransition]);

  return isInTransition;
}
