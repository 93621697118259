import { Book, Title, ProductViewSource, Shelf } from 'types';
import { getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '~/clients/firebase/client';

import { useGlobalState } from '../../state';
import { ActionType } from '../../state/types';
import router from 'next/router';

import { backendFunction, updateFirestoreDoc } from '~/clients/firebase/client';

import useAnalytics from './use-analytics';
import ShelvesListModal from '~/components/shelves/shelves-list-modal';

interface UseShelvesProps {
  book?: Book;
  title?: Title;
  source?: ProductViewSource;
}

export default function useShelves(props?: UseShelvesProps): any {
  const { state, dispatch } = useGlobalState();
  const { analyticEvents } = useAnalytics();

  const user = state.user.data;
  const uid = user.uid;

  const preference = state?.shelves?.data?.preference;
  const titleShelfIds = state?.shelves?.data?.titleShelfIds || [];

  interface Preference {
    pinned_copy: boolean;
    shelf: string;
  }

  const isTitleInShelf = (titleId: string) => {
    return Boolean(titleShelfIds.find((titleShelfId) =>
      titleShelfId.startsWith(titleId)
    ));
  };

  const isBookInShelf = (book: Book) => {
    return Boolean(titleShelfIds.find((titleShelfId) => titleShelfId.includes(book.id)));
  };

  const isShelfLiked = async ({ shelfId, userId }: { shelfId: string, userId: string; }) => {
    const shelfLikeDocRef = doc(db, `shelves/${userId}/shelves/${shelfId}/shelf_likes/${uid}`);
    const shelfLikeDoc = await getDoc(shelfLikeDocRef);

    return shelfLikeDoc.exists();
  };

  const handleLike = async ({ shelfId, userId }: { shelfId: string, userId: string; }) => {
    const isLiked = await isShelfLiked({ shelfId, userId });
    const shelfLikeDocRef = doc(db, `shelves/${userId}/shelves/${shelfId}/shelf_likes/${uid}`);

    if (isLiked) {
      await deleteDoc(shelfLikeDocRef);
    } else {
      await setDoc(shelfLikeDocRef, {
        timestamp: Date.now()
      });
      analyticEvents.likeShelf({ shelf: shelfId });
    }
  };

  const setPreference = async ({
    pinned_copy,
    shelf_id,
    shelf_title,
  }: {
    pinned_copy: boolean;
    shelf_id?: string;
    shelf_title?: string;
  }) => {
    await updateFirestoreDoc('shelves', uid, {
      preference: {
        ...preference,
        pinned_copy: pinned_copy,
        shelf: shelf_id ? shelf_id : preference?.shelf,
        shelf_title: shelf_title ? shelf_title : preference?.shelf_title,
      },
    });
  };

  const handleShelf = async ({
    book,
    title,
    type,
  }: {
    book?: Book;
    title?: Title;
    type: 'book' | 'title';
  }) => {
    const titleId = title?.id || book?.title_id;
    const bookId = book?.id || title?.selected_listing?.id;
    const titleText = book?.title || title?.title;
    const IN_SHELF =
      type === 'title' ? isTitleInShelf(titleId) : isBookInShelf(book);

    if (!IN_SHELF) {
      await handleAddToShelf({ book, title });
    } else {
      let shelfIds = [];

      if (type === 'title') {
        shelfIds = titleShelfIds
          .filter((titleShelfId) => titleShelfId.startsWith(titleId))
          .map((titleShelfId) => titleShelfId.split(':')[1]);
      } else {
        shelfIds = titleShelfIds
          .filter((titleShelfId) => titleShelfId.includes(bookId))
          .map((titleShelfId) => titleShelfId.split(':')[2]);
      }

      if (shelfIds.length === 1) {
        //only in one shelf so just remove it
        await removeFromShelves({ titleId, bookId, titleText, shelfIds });
      } else {
        //in multiple shelves so ask which one to remove from
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            title: 'Remove from shelves',
            component: (
              <ShelvesListModal
                titleId={titleId}
                bookId={bookId}
                shelfIds={shelfIds}
                op='remove'
              />
            ),
          },
        });
      }
    }
  };

  interface AddToShelfProps {
    title_id: string;
    book_id: string;
    preference: Preference;
    save_preference?: boolean;
  }
  const saveToShelf = async (
    preference: Preference,
    bookId: string,
    titleId: string,
    save_preference?: boolean
  ) => {
    const res = await backendFunction<any, AddToShelfProps>(
      'shelves-addToShelf',
      {
        book_id: bookId,
        title_id: titleId,
        preference,
        save_preference,
      }
    );

    if (res?.success) {
      const shelfTitle = res.shelf_data.shelf_title;
      const shelfTitleId = res.shelf_data.title_id;
      const shelfId = res.shelf_data.shelf_id;
      dispatch({
        type: ActionType.ADD_SNACK_DATA,
        payload: {
          title: `Added to ${shelfTitle}`,
          message: `${preference?.pinned_copy === false ? 'The Title ' : ''}${res.titleText}`,
          linkHref: `/shelves/${user.username}/${shelfId}`,
          buttonText: 'Change',
          buttonAction: () => {
            dispatch({
              type: ActionType.ADD_MODAL_DATA,
              payload: {
                title: 'Add to shelves',
                component: (
                  <ShelvesListModal
                    titleId={shelfTitleId}
                    bookId={bookId}
                    shelfIds={[shelfId]}
                    originalShelfData={{
                      id: shelfId,
                      title: shelfTitle,
                    }}
                    preference={preference}
                  />
                ),
              },
            });
          },
        },
      });
    }

    return res;
  };

  const handleAddToShelf = async ({
    book,
    title,
  }: {
    book?: Book;
    title?: Title;
  }) => {
    const bookId = book?.id || title?.selected_listing?.id;
    const titleId = title?.id || book?.title_id;
    await saveToShelf(preference, bookId, titleId, false);
    analyticEvents.addToShelf({
      source: props?.source,
      type: Boolean(preference?.pinned_copy) ? 'copy' : 'title',
      shelf: preference?.shelf,
      title_id: titleId,
    });
    dispatch({
      type: ActionType.FLUSH_MODAL_DATA,
    });
  };

  interface MoveShelfProps {
    title_id: string;
    book_id: string;
    shelf_id: string;
    new_shelves: string[];
    copy?: boolean;
    pinned_copy?: boolean;
  }

  const moveTitle = async ({
    titleId,
    bookId,
    shelfId,
    newShelvesIds,
    shelves,
    pinned_copy
  }: {
    titleId: string;
    bookId?: string;
    shelfId: string;
    newShelvesIds: string[];
    shelves: Shelf[];
    pinned_copy?: boolean;
  }) => {
    const res = await backendFunction<any, MoveShelfProps>(
      'shelves-moveShelf',
      {
        title_id: titleId,
        book_id: bookId,
        shelf_id: shelfId,
        new_shelves: newShelvesIds,
        copy: false,
        pinned_copy
      }
    );

    if (res.success) {
      const newShelves = shelves.filter((shelf) =>
        newShelvesIds.includes(shelf.id)
      );
      dispatch({
        type: ActionType.ADD_SNACK_DATA,
        payload: {
          title: `Moved to ${newShelves
            .map((shelf) => shelf.title)
            .join(', ')}`,
          message: res.titleText,
          linkHref: newShelves.length === 1 ? `/shelves/${user.username}/${newShelves[0].id}` : `/shelves/${user.username}`,
        },
      });

      dispatch({
        type: ActionType.FLUSH_MODAL_DATA,
      });
    }
  };

  const removeFromShelves = async ({
    titleId,
    bookId,
    titleText,
    shelfIds,
  }: {
    titleId: string;
    bookId: string;
    titleText: string;
    shelfIds: string[];
  }) => {
    const res = await backendFunction<any, any>('shelves-removeFromShelves', {
      shelf_ids: shelfIds,
      title_id: titleId,
      book_id: bookId,
    });

    if (res.success) {
      dispatch({
        type: ActionType.ADD_SNACK_DATA,
        payload: {
          title: `Removed from ${res.shelf_titles.map(
            (shelf_title) => shelf_title
          )}`,
          message: res.titleText || ''
        },
      });
      dispatch({
        type: ActionType.FLUSH_MODAL_DATA,
      });
    } else {
      //show snack error
    }
  };

  const updateShelf = async ({
    title_id,
    shelf_id,
    op,
    title,
    originalTitle,
    description,
    id,
    callback,
    is_public,
    notify,
  }: {
    title_id?: string;
    shelf_id?: string;
    title?: string;
    originalTitle?: string;
    description?: string;
    is_public?: boolean;
    notify?: boolean;
    id?: string;
    op: 'create' | 'edit' | 'delete';
    callback?: (data: any) => void;
  }) => {
    if (op === 'create' || op === 'edit') {
      const res = await backendFunction<any, any>('shelves-updateShelf', {
        title,
        description,
        id,
        is_public,
        notify,
        op: op === 'edit' ? 'edit' : 'create',
      });
      analyticEvents.shelfOp({
        op,
        notify,
        is_public,
      });

      if (res?.success) {
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: `${op === 'edit' ? 'Edited' : 'Created'} Shelf ${title}`,
          },
        });

        if (title_id && shelf_id) {
          //back to shelves modal to move title
          dispatch({
            type: ActionType.ADD_MODAL_DATA,
            payload: {
              title: 'Add to shelves',
              component: (
                <ShelvesListModal
                  titleId={title_id}
                  shelfIds={[res.shelf_id]}
                  originalShelfData={{ id: shelf_id, title: originalTitle }}
                />
              ),
            },
          });
        } else {
          //shelf created from shelves page so just go back to shelves page with new shelf
          dispatch({
            type: ActionType.FLUSH_MODAL_DATA,
          });
        }
        callback && callback({ shelfId: res.shelf_id });

      } else {
        //show snack error
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: `Error ${op === 'edit' ? 'editing' : 'creating'} Shelf`,
          },
        });
      }
    }

    if (op === 'delete') {
      const res = await backendFunction<any, any>('shelves-updateShelf', {
        id,
        op: 'delete',
      });
      if (res?.success) {
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: `Deleted Shelf ${res.shelf_title}`,
          },
        });
        dispatch({
          type: ActionType.FLUSH_MODAL_DATA,
        });
        router.push(`/shelves/${user.username}`);
      } else {
        //show snack error
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Error deleting shelf',
          },
        });
      }
    }
  };

  return {
    isTitleInShelf,
    isBookInShelf,
    handleShelf,
    saveToShelf,
    moveTitle,
    updateShelf,
    removeFromShelves,
    setPreference,
    isShelfLiked,
    handleLike
  };
}
