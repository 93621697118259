import { QueryConstraint } from 'firebase/firestore';
import { AdditionalQuery, BackendFuctions, CustomQueryProps, PangoCollections } from 'types';
import { BackendFunctionPaginationResponse, FetchCollectionDocsByQueryProps, InfiniteQueryResponse, backendFunction, backendFunctionWithPagination, customQuery, fetchCollectionDocByPath, fetchCollectionDocByQuery, fetchCollectionDocByUid, fetchCollectionDocsByInfiniteQuery, fetchCollectionDocsByQuery, fetchDataFromIds, multiFetchCollectionDocsByQuery } from './client';
import {
  useQuery as UseQuery,
  UseQueryOptions,
  useInfiniteQuery as UseInfiniteQuery,
} from '@tanstack/react-query';


const queryKeys = {
  all: ['query'] as const,
  fetchCollectionDocByUid: (id: string, collection: PangoCollections) =>
    [id, collection] as const,
  fetchCollectionDocsByQuery: (path: string, constraints: QueryConstraint[]) =>
    [path, constraints] as const,
  multiFetchCollectionDocsByQuery: (queries: FetchCollectionDocsByQueryProps[]) =>
    queries.map((query) => [query.path, query.constraints]).flat(),
  fetchCollectionDocByQuery: (path: string, constraints: QueryConstraint[]) =>
    [path, constraints] as const,
  fetchCollectionDocsByInfiniteQuery: (
    path: string,
    constraints: QueryConstraint[],
    additionalQuery?: AdditionalQuery
  ) => [path, constraints, additionalQuery] as const,
  fetchDataFromIds: (ids: string[], collection: PangoCollections) =>
    [ids, collection] as const,
  fetchCollectionDocByPath: (id: string, path: string) => [id, path] as const,
  customQuery: ({ queryType, queryProps, uid }: CustomQueryProps) => [queryType, queryProps, uid] as const,
  backendFunction: (name: BackendFuctions, props: any) => [name, props] as const,
  backendFunctionWithPagination: (name: BackendFuctions, props: any, startAfterCursorId: string) => [name, props, startAfterCursorId] as const,
};

export default function Firebase<D>() {
  return {
    fetchCollectionDocByUid: (
      id: string,
      collection: PangoCollections,
      parse: boolean = true,
      reactQueryOptions?: Omit<
        UseQueryOptions<any, any, any, any>,
        'queryKey' | 'queryFn'
      >
    ) =>
      UseQuery<D>(
        queryKeys.fetchCollectionDocByUid(id, collection),
        () => fetchCollectionDocByUid<D>(id, collection),
        { ...reactQueryOptions }
      ),
    fetchCollectionDocByQuery: (
      path: string,
      constraints: QueryConstraint[],
      reactQueryOptions?: Omit<
        UseQueryOptions<any, any, any, any>,
        'queryKey' | 'queryFn'
      >
    ) =>
      UseQuery<D>(
        queryKeys.fetchCollectionDocByQuery(path, constraints),
        () => fetchCollectionDocByQuery<D>(path, constraints),
        { ...reactQueryOptions }
      ),
    fetchCollectionDocsByQuery: (
      path: string,
      constraints: QueryConstraint[],
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
    ) =>
      UseQuery<D[]>(
        queryKeys.fetchCollectionDocsByQuery(path, constraints),
        () => fetchCollectionDocsByQuery<D>({ path, constraints }),
        { ...reactQueryOptions }
      ),
    multiFetchCollectionDocsByQuery: ({
      queries,
      reactQueryOptions
    }: {
      queries: FetchCollectionDocsByQueryProps[];
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) =>
      UseQuery<D[][]>(
        queryKeys.multiFetchCollectionDocsByQuery(queries),
        () => multiFetchCollectionDocsByQuery<D>({ queries }),
        { ...reactQueryOptions }
      ),
    fetchCollectionDocsByInfiniteQuery: ({
      path,
      constraints,
      limitNum = 10,
      additionalQuery,
      reactQueryOptions
    }: {
      path: string,
      constraints: QueryConstraint[],
      limitNum?: number,
      additionalQuery?: AdditionalQuery,
      reactQueryOptions?: Omit<
        UseQueryOptions<any, any, any, any>,
        'queryKey' | 'queryFn'
      >;
    }) =>
      UseInfiniteQuery<InfiniteQueryResponse<D & { id: string; }>>(
        queryKeys.fetchCollectionDocsByInfiniteQuery(path, constraints),
        ({ pageParam = undefined }) =>
          fetchCollectionDocsByInfiniteQuery<D>({ path, constraints, limitNum, additionalQuery, startAfterCursor: pageParam }),
        {
          getNextPageParam: (lastPage: InfiniteQueryResponse<D>, pages: InfiniteQueryResponse<D>[]) => lastPage?.hasMore ? lastPage?.nextCursor : null,
          ...reactQueryOptions
        }
      ),
    fetchDataFromIds: (
      ids: string[],
      collection: PangoCollections,
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
    ) =>
      UseQuery<D[]>(
        queryKeys.fetchDataFromIds(ids, collection),
        () => fetchDataFromIds<D>(ids, collection),
        { ...reactQueryOptions }
      ),
    fetchCollectionDocByPath: (
      id: string,
      path: string,
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
    ) =>
      UseQuery<D>(
        queryKeys.fetchCollectionDocByPath(id, path),
        () => fetchCollectionDocByPath<D>(id, path),
        { ...reactQueryOptions }
      ),
    customQuery: ({
      queryType,
      queryProps,
      uid,
      reactQueryOptions
    }: CustomQueryProps & {
      reactQueryOptions?: Omit<
        UseQueryOptions<any, any, any, any>,
        'queryKey' | 'queryFn'
      >;
    }) => UseQuery(
      queryKeys.customQuery({ queryType, queryProps, uid }),
      () => customQuery({ queryType, queryProps, uid }),
      { ...reactQueryOptions }
    ),
    backendFunctionWithPagination: (
      name: BackendFuctions,
      props: any,
      startAfterCursorId?: string,
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
    ) =>
      UseInfiniteQuery<BackendFunctionPaginationResponse<D>>(
        queryKeys.backendFunctionWithPagination(name, props, startAfterCursorId),
        ({ pageParam = undefined }) =>
          backendFunctionWithPagination<D, any>({ name, props, startAfterCursorId: pageParam }),
        {
          getNextPageParam: (lastPage: BackendFunctionPaginationResponse<D>, pages: BackendFunctionPaginationResponse<D>[]) => {
            return lastPage?.hasMore ? lastPage?.nextCursorId : null; //get rid of has more - return next
          },
          ...reactQueryOptions
        }
      ),
    backendFunction: (
      name: BackendFuctions,
      props: any,
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
    ) =>
      UseQuery<D>(
        queryKeys.backendFunction(name, props),
        () => backendFunction<D, any>(name, props),
        { ...reactQueryOptions }
      )
  };
}
