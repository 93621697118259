import Head from 'next/head';
import { useRouter } from 'next/router';
import { images, formatMoney } from 'sdk';
import { stripQueryParams } from '~/lib/helpers/url-helper';

import { Book } from 'types';

interface IPageMetaDataProps {
  title?: string;
  description?: string;
  children?: any;
  photoPath?: string;
  photoUrl?: string;
  twitterCard?:
  | 'summary'
  | 'photo'
  | 'video'
  | 'product'
  | 'app'
  | 'gallery'
  | 'summary_large_image';
  // TODO - the book page returns back a sold book or a live book.
  // update this once and if the book's availablity remains in the book collection
  book?: Book & { sold?: boolean; };
  noIndex?: boolean;
  canonicalUrl?: string;
  schema?: object;
}

export default function PageMetaData({
  twitterCard = 'app',
  ...props
}: IPageMetaDataProps) {
  let router = useRouter();

  const path = stripQueryParams(router.asPath);
  const canonicalUrl = props.canonicalUrl ?? `https://pangobooks.com${path}`;

  function metaType() {
    if (!!props.book) {
      return 'book';
    }

    return 'website';
  }

  /**
   * Placeholder for now to support a url, or a photopath
   * Replace this once all images are on photo_path
   */
  function image(key?: 'ogImage' | 'twitterImage') {
    if (props.photoUrl) {
      return props.photoUrl;
    } else if (props.photoPath) {
      return images(props.photoPath).seo[key];
    }

    return images('website_photos/stacked-books.jpg').seo[key];
  }

  function ogTags() {
    return (
      <>
        <meta property="og:type" content={metaType()} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:site_name" content="PangoBooks" />
        <meta
          property="og:image"
          itemProp="image primaryImageOfPage"
          content={image('ogImage')}
        />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        {/* if noIndex then add meta for no index */}
        {props.noIndex && <meta name="robots" content="noindex" />}
      </>
    );
  }

  /**
   * https://schema.org/Book
   */
  function bookTags() {
    if (!props.book) return;

    return (
      <>
        {props.book.isbn && (
          <meta property="book:isbn" content={props.book.isbn} />
        )}
        {props.book.author && (
          <meta property="book:author" content={props.book.author} />
        )}
      </>
    );
  }

  function twitterTags() {
    return (
      <>
        <meta name="twitter:image" content={image('twitterImage')} />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:site" content="@pangobooks" />
        <meta name="twitter:domain" content="pangobooks.com" />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
      </>
    );
  }

  /**
   * https://developers.facebook.com/docs/marketing-api/catalog/guides/microdata-tags
   * https://developers.facebook.com/docs/marketing-api/catalog/reference/#og-tags
   */
  function microDataTags() {
    if (!props.book) return;

    const availability = props.book.sold ? 'out of stock' : 'in stock';
    const condition = props.book.condition === 'New' ? 'new' : 'used';
    const price = props.book.price_drop
      ? props.book.price_drop.previous_amount
      : props.book.amount;

    return (
      <>
        <meta property="product:availability" content={availability} />
        <meta property="product:condition" content={condition} />
        <meta
          property="product:price:amount"
          content={formatMoney(price, false)}
        />
        {props.book.price_drop && (
          <meta
            property="product:sale_price:amount"
            content={formatMoney(props.book.amount, false)}
          />
        )}
        <meta property="product:price:currency" content="USD" />
        <meta
          property="product:retailer_item_id"
          content={props.book.id.substring(0, 100)}
        />
      </>
    );
  }

  function schemaMarkup() {

    if (!props.schema) return;

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(props.schema) }}
      />
    );
  }

  return (
    <Head>
      <title>{props.title}</title>
      <link rel="canonical" href={canonicalUrl} />
      <link rel="image_src" href={image('ogImage')} />
      <meta name="description" content={props.description} />
      <meta property="fb:app_id" content="393331851864036" />

      {ogTags()}
      {microDataTags()}
      {bookTags()}
      {twitterTags()}
      {schemaMarkup()}

      {props.children}
    </Head>
  );
}
