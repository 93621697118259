import styles from '@/styles/components/form/checkbox.module.scss';

interface CheckboxProps {
  text: string;
  checked: boolean;
  onPress: (e?: any) => void;
  disabled?: boolean;
}

export default function Checkbox({
  text,
  checked,
  onPress,
  disabled,
}: CheckboxProps) {
  return (
    <label className={styles.container}>
      <input
        type='checkbox'
        name='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={onPress}
      />
      <span>{text}</span>
    </label>
  );
}
