'use client';

import { PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import styles from '../styles/components/pill.module.scss';
import CloseSVG from '~/assets/svg/icons/close';
import COLORS from '~/lib/helpers/color-helper';

interface PillProps {
  text: string;
  small?: boolean;
  tiny?: boolean;
  large?: boolean;
  icon?: any;
  iconPosition?: 'left' | 'right';
  link?: PropsWithChildren<LinkProps>;
  active?: boolean;
  loading?: boolean;
  style?: 'primary' | 'secondary' | 'light' | 'green';
  onClick?: () => void;
  className?: string;
  shadow?: boolean;
  outline?: boolean;
  textColor?: 'primary' | 'copyDark';
  handleOnClose?: () => void;
}

export default function Pill(props: PillProps) {
  const [closeHovered, setCloseHovered] = useState<boolean>(false);

  function handleOnMouseEnter() {
    //props.link.href = 'author'
    setCloseHovered(true);
  }

  function handleOnMouseLeave() {
    setCloseHovered(false);
  }

  function basePill() {
    const base = (
      <>
        {props.iconPosition === 'right' ? (
          <>
            {props.text}
            {props.icon && (
              <div
                className={classNames(styles.icon, {
                  [styles['icon--large']]: props.large,
                })}
              >
                {props.icon}
              </div>
            )}
          </>
        ) : (
          <>
            {props.icon && (
              <div
                className={classNames(styles.icon, {
                  [styles['icon--large']]: props.large,
                })}
              >
                {props.icon}
              </div>
            )}
            {props.text}
          </>
        )}
        {props.handleOnClose && (
          <div
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            className={styles['close-icon-container']}
            onClick={props.handleOnClose}
          >
            <CloseSVG strokeColor={COLORS.copyLight} />
          </div>
        )}
      </>
    );

    const baseStyles = (link?: boolean) =>
      classNames(styles.pill, {
        [styles['pill--small']]: props.small,
        [styles['pill--tiny']]: props.tiny,
        [styles['pill--secondary']]: props.style === 'secondary',
        [styles['pill--active']]: props.active,
        [styles['pill--link']]: link,
        [styles['pill--loading']]: props.loading,
        [styles['pill--large']]: props.large,
        [styles['pill--light']]: props.style === 'light',
        [styles['pill--shadow']]: props.shadow,
        [styles['pill--outline']]: props.outline,
        [styles['close-icon']]: !!props.handleOnClose,
        [styles['pill--green']]: props.style === 'green',
        [styles[`pill--text-${props.textColor}`]]: !!props.textColor,
        [props.className]: !!props.className,
      });

    return !props.link ? (
      <span onClick={() => props.onClick && props.onClick()} className={baseStyles()}>
        {base}
      </span>
    ) : (
      <a onClick={() => props.onClick && props.onClick()} className={baseStyles(true)}>
        {base}
      </a>
    );
  }

  if (props.link && !closeHovered) {
    return (
      <Link href={props.link.href} passHref legacyBehavior>
        {basePill()}
      </Link>
    );
  }

  return basePill();
}
