import classNames from 'classnames';
import { LinkProps } from 'next/link';
import Link from 'next/link';
import styles from '../styles/components/list.module.scss';

interface IconProps {
  icon: React.ReactElement;
}

interface ListProps {
  topDivider?: boolean;
  bottomDivider?: boolean;
  onPress?: () => any;
  active?: boolean;
  showHover?: boolean;
  link?: React.PropsWithChildren<LinkProps>;
  externalLink?: JSX.IntrinsicElements['a'];
  children?: any;
  className?: string;
}

function ListItem(props: ListProps) {

  const {
    topDivider,
    bottomDivider,
    active,
    showHover,
    link,
    externalLink,
    onPress,
    children,
  } = props;

  function baseList() {
    return (
      <div
        className={classNames(styles.container, props.className, {
          [styles['container--top-divider']]: topDivider,
          [styles['container--bottom-divider']]: bottomDivider,
          [styles['container--clickable']]: (Boolean(onPress) || Boolean(link)),
          [styles['container--nohover']]: showHover === false,
          [styles['container--active']]: active,
        })}
        onClick={onPress}
      >
        {children}
      </div>
    );
  }

  if (link) {
    return <Link {...link} legacyBehavior>{baseList()}</Link>;
  }

  if (externalLink) {
    return <a {...externalLink}>{baseList()}</a>;
  }

  return baseList();
}

function Content({ children }) {

  return (
    <div className={styles.content}>
      {children}
    </div>
  );
}
ListItem.Content = Content;

function Title({ children }) {

  return (
    <div className={styles.title}>
      {children}
    </div>
  );
}
ListItem.Title = Title;

function Subtitle({ children }) {

  return (
    <div className={styles.subtitle}>
      {children}
    </div>
  );
}
ListItem.Subtitle = Subtitle;

function Icon(props: IconProps) {
  const { icon } = props;
  return (
    <div className={styles.icon}>
      {icon}
    </div>
  );
}
ListItem.Icon = Icon;

export default ListItem;
