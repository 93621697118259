import { Analytics } from '@segment/analytics-next';
import { Action, ActionType, GlobalState, initialState } from '../types';

export interface AnalyticsState {
  analytics: Analytics;
}

const analyticsReducer = (
  state: GlobalState,
  action?: Action<AnalyticsState>
): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.LOAD_ANALYTICS: {
      return {
        ...state,
        analytics: {
          data: {
            ...state.analytics.data,
            ...payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default analyticsReducer;
