'use client';

import { useGlobalState } from '@/state';
import useAnalytics from '@/lib/hooks/use-analytics';
import { useEffect, useRef } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

export default function ReferrerTracking() {
  const { analyticEvents, analyticsLoaded } = useAnalytics();
  const { state } = useGlobalState();
  const user = state?.user?.data;
  const campaignIdRef = useRef<string>('');
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    if (!analyticsLoaded || !user?.uid) return;

    const campaignId = searchParams.get('campaignId');
    if (!campaignId) return;
    if (campaignId === campaignIdRef.current) return;
    campaignIdRef.current = campaignId;

    analyticEvents.generalTrack('Ad Traffic', {
      type: 'google',
      campaignId,
      pathname,
      isLoggedIn: user?.isAnonymous === false,
    });

  }, [pathname, searchParams, user, analyticEvents, analyticsLoaded]);

  return null;
}