'use client';
import { FormEvent, useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';

import PangoUser from '../clients/pango';

import useAnalytics from '~/lib/hooks/use-analytics';

import { useGlobalState } from '../state';
import { ActionType } from '../state/types';

import Input from './form/input';
import Button from './button';
import ResetPasswordForm from './reset-password';
import Form from '../components/form';

import GoogleIcon from '../assets/svg/icons/google';
import AppleIcon from '../assets/svg/icons/apple';

import styles from '../styles/components/session-form.module.scss';
import { ProviderType, UserProfile } from 'types';
import { backendFunction } from '~/clients/firebase/client';

type FormOption = 'login' | 'signup';

/**
 * This component is responsible for logging in a user,
 * and setting a `token` cookie used to authenticate server side
 */
interface SessionFormProps {
  display?: FormOption;
  redirectUrl?: string;
  noModal?: boolean;
}
export default function SessionForm({ display, redirectUrl, noModal }: SessionFormProps) {
  const { dispatch } = useGlobalState();
  const { analyticEvents } = useAnalytics();
  const router = useRouter();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formView, setFormView] = useState<FormOption>(display ?? 'login');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [showCodeForm, setShowCodeForm] = useState<boolean>(false);

  // for security code form
  const [code, setCode] = useState<string>('');
  const [resendingCode, setResendingCode] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>();
  const [resendCodeSuccess, setResendCodeSuccess] = useState<boolean>(false);

  const isCodeReady = code.length === 6;

  useEffect(() => {
    if (error && error.includes('No Provider Email')) {
      const errorProvider = (provider: ProviderType) => error.includes(provider);
      let errorProviderText = 'linked';

      if (errorProvider('google.com')) {
        errorProviderText = 'Google';
      }

      return dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title: "Uh Oh!  We Couldn't Find Your E-Mail Address",
          message: `We weren't able to find an email with your ${errorProviderText} account.  This may be a setting on your ${errorProviderText} account.  You can try another provider, or sign up with an email and password`,
          component: <SessionForm />,
        },
      });
    }
  }, [error, dispatch]);

  async function handleEmailSubmit(e?: FormEvent<HTMLFormElement>) {
    if (e) e.preventDefault();
    setLoading(true);
    let user: Partial<UserProfile>;
    try {
      if (formView === 'login') {
        user = await PangoUser.loginWithEmail(email, password);
        analyticEvents.authEvent('Signed In', 'email', { email: user.email, id: user.uid });
      } else {
        user = await PangoUser.signUpWithEmail(name, email, password);
        analyticEvents.authEvent('Signed Up GA', 'email', { email: user.email, id: user.uid });
      }

      setLoading(false);
      if (redirectUrl) {
        return setTimeout(() => {
          router.replace(redirectUrl);
          dispatch({ type: ActionType.FLUSH_MODAL_DATA });
        }, 3000);
      }
      return dispatch({ type: ActionType.FLUSH_MODAL_DATA });
    } catch (e: any) {
      console.error(e);
      if (e.message === 'code_required') {
        // show the code form
        setShowCodeForm(true);
      } else {
        setError(e.message);
      }
      if (e.message === 'email-already-in-use') dispatch({ type: ActionType.FLUSH_MODAL_DATA });
    }

    setLoading(false);
  }

  async function handleProviderLogin(provider: 'facebook' | 'google' | 'apple') {
    let authResponse: { user: Partial<UserProfile>; isNewUser: boolean; } = { user: null, isNewUser: false };

    setLoading(true);

    try {
      if (provider === 'google') {
        authResponse = await PangoUser.loginWithGoogle();
      }

      if (provider === 'apple') {
        authResponse = await PangoUser.loginWithApple();
      }

      const { user, isNewUser } = authResponse;
      if (isNewUser) {
        await analyticEvents.authEvent('Signed Up GA', provider, {
          email: user.email,
          id: user.uid,
        });
      } else {
        await analyticEvents.authEvent('Signed In', provider, {
          email: user.email,
          id: user.uid,
        });
      }

      if (redirectUrl) {
        return setTimeout(() => {
          router.replace(redirectUrl);
          dispatch({ type: ActionType.FLUSH_MODAL_DATA });
        }, 3000);
      }
      return dispatch({ type: ActionType.FLUSH_MODAL_DATA });
    } catch (e: any) {
      setError(e.message);
      console.error(e);
    }

    setLoading(false);
  }

  const handleTerms = () => {
    router.push('/terms');
    dispatch({ type: ActionType.FLUSH_MODAL_DATA });
  };

  const handlePrivacyNotice = () => {
    router.push('/privacy');
    dispatch({ type: ActionType.FLUSH_MODAL_DATA });
  };

  const handleFormSwitch = () => {
    let title = 'Welcome back!';
    let message = '';

    if (formView === 'login') {
      title = 'Create an account';
      setFormView('signup');
    } else {
      setFormView('login');
    }

    if (noModal) return;

    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title,
        message,
      },
    });
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    setResendCodeSuccess(false);
    setCodeErrorMessage(undefined);
    if (verifying || resendingCode) return;
    try {
      setResendingCode(true);
      await backendFunction('auth-resendCode', { email });
      setResendCodeSuccess(true);
    } catch (e: any) {
      setCodeErrorMessage(e.message);
    }
    setResendingCode(false);
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setResendCodeSuccess(false);
    try {
      setVerifying(true);
      setCodeErrorMessage(undefined);
      await backendFunction('auth-submitCode', { email, codeEntered: code.trim() });
      // success. close form and login again.
      setShowCodeForm(false);
      await handleEmailSubmit();
    } catch (e: any) {
      setCodeErrorMessage(e.message);
    }
    setVerifying(false);
  };

  const handleCancelCode = (e) => {
    e.preventDefault();
    setShowCodeForm(false);
    setResendCodeSuccess(false);
    setCodeErrorMessage(undefined);
  };

  useEffect(() => {
    setCodeErrorMessage(undefined);
  }, [code]);

  if (showCodeForm) {
    return (
      <div className={styles['verify-form']}>
        <h2>Please check your email</h2>
        <p>We sent an email to {email}</p>

        <form onSubmit={handleVerifyCode}>
          <Input
            type='text'
            onChange={(e) => setCode(e.target.value)}
            placeholder={'Enter code'}
            value={code}
            required
            error={!!codeErrorMessage ? codeErrorMessage : undefined}
            className={classNames({ [styles['input-error']]: !!codeErrorMessage })}
          />

          <div className={styles['verify-form-buttons']}>
            <Button style='clear' onPress={handleCancelCode} text='Cancel' disabled={verifying || resendingCode} />

            <Button
              style='primary'
              type='submit'
              text='Verify'
              disabled={verifying || !isCodeReady || resendingCode}
              loading={verifying}
            />
          </div>
          <div className={styles['verify-form-resend-text']}>
            <p>
              {"Didn't get the code?"} <span onClick={handleResendCode}>Resend code</span>
            </p>
            {resendingCode && <p>Resending...</p>}
            {resendCodeSuccess && <p>Code resent!</p>}
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className={styles['session-form']}>
      <div className={styles['session-form-social-logins']}>
        <Button
          style='outline'
          onPress={() => handleProviderLogin('google')}
          icon={<GoogleIcon />}
          text='Continue with Google'
        />
        <Button
          style='outline'
          onPress={() => handleProviderLogin('apple')}
          icon={<AppleIcon />}
          text='Continue with Apple'
        />
      </div>

      <span className={styles['separator']}>or</span>

      <form
        onSubmit={(e) => {
          handleEmailSubmit(e);
        }}
      >
        {formView === 'signup' && (
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Input
              type='text'
              onChange={(e) => setName(e.target.value)}
              placeholder={'Enter your name'}
              value={name}
              required
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Input type='text' onChange={(e) => setEmail(e.target.value)} placeholder={'Email'} value={email} required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Input
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder={formView === 'login' ? 'Password' : 'Create a password'}
            value={password}
            required
            error={!!error ? error : undefined}
            className={classNames({ [styles['input-error']]: !!error })}
          />
          {formView === 'login' && (
            <div className={styles['session-form-forgot-password']}>
              <a
                onClick={() =>
                  dispatch({
                    type: ActionType.ADD_MODAL_DATA,
                    payload: {
                      title: 'Reset your password',
                      message: '',
                      component: <ResetPasswordForm />,
                    },
                  })
                }
              >
                Forgot password?
              </a>
            </div>
          )}
        </Form.Group>
        <Button type='submit' text={formView === 'login' ? 'Log in' : 'Create an account'} loading={loading} />
        {formView === 'signup' && (
          <p className={styles['session-form-terms']}>
            {"By signing up, you agree to PangoBooks'"} <a onClick={handleTerms}>Terms of Use</a> and{' '}
            <a onClick={handlePrivacyNotice}>Privacy Notice</a>.
          </p>
        )}
        <p className={styles['session-form-switch']}>
          {formView === 'login' ? (
            <>
              Don&apos;t have an account? <a onClick={handleFormSwitch}>Sign up</a>
            </>
          ) : (
            <>
              Already a member? <a onClick={handleFormSwitch}>Log in</a>
            </>
          )}
        </p>
      </form>
    </div>
  );
}
