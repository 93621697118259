import COLORS from "~/lib/helpers/color-helper";

interface Props {
  fillColor?: string;
}

export default function NotificationFilledIcon({ fillColor = COLORS.primary }: Props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.19 14.06L19.06 12.18C18.81 11.77 18.59 10.98 18.59 10.5V8.63C18.59 5 15.64 2.05 12.02 2.05C8.38996 2.06 5.43996 5 5.43996 8.63V10.49C5.43996 10.97 5.21996 11.76 4.97996 12.17L3.84996 14.05C3.41996 14.78 3.31996 15.61 3.58996 16.33C3.85996 17.06 4.46996 17.64 5.26996 17.9C6.34996 18.26 7.43996 18.52 8.54996 18.71C8.65996 18.73 8.76996 18.74 8.87996 18.76C9.01996 18.78 9.16996 18.8 9.31996 18.82C9.57996 18.86 9.83996 18.89 10.11 18.91C10.74 18.97 11.38 19 12.02 19C12.65 19 13.28 18.97 13.9 18.91C14.13 18.89 14.36 18.87 14.58 18.84C14.76 18.82 14.94 18.8 15.12 18.77C15.23 18.76 15.34 18.74 15.45 18.72C16.57 18.54 17.68 18.26 18.76 17.9C19.53 17.64 20.12 17.06 20.4 16.32C20.68 15.57 20.6 14.75 20.19 14.06ZM12.75 10C12.75 10.42 12.41 10.76 11.99 10.76C11.57 10.76 11.23 10.42 11.23 10V6.9C11.23 6.48 11.57 6.14 11.99 6.14C12.41 6.14 12.75 6.48 12.75 6.9V10Z" fill={fillColor} />
      <path d="M14.8301 20.01C14.4101 21.17 13.3001 22 12.0001 22C11.2101 22 10.4301 21.68 9.88005 21.11C9.56005 20.81 9.32005 20.41 9.18005 20C9.31005 20.02 9.44005 20.03 9.58005 20.05C9.81005 20.08 10.0501 20.11 10.2901 20.13C10.8601 20.18 11.4401 20.21 12.0201 20.21C12.5901 20.21 13.1601 20.18 13.7201 20.13C13.9301 20.11 14.1401 20.1 14.3401 20.07C14.5001 20.05 14.6601 20.03 14.8301 20.01Z" fill={fillColor} />
    </svg>
  );
}
