import Script from 'next/script';

/**
 * This is currently being imported as a global import so that we
 * have access to the client ID for Segment calls.
 *
 * Currently, without this imported globally, Segment does not have
 * the client ID which is needs to make the proper called to GA and other
 * Google destinations.
 *
 * TODO - Come back and look at this after speaking with Segment to see if we are able to not do this.
 *
 */
export default function GoogleTagManager() {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`}
      />
      <Script
        id='google-analytics'
        strategy="afterInteractive"
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());
          gtag("config", "${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}", {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </>
  );
}
