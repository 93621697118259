'use client';

import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

interface Props {
  children: any;
  onScrollEnd: () => any;
  loadingIndicator?: ReactElement;
  className?: any;
  style?: any;
}
export default function InfiniteScroll({
  children,
  onScrollEnd,
  loadingIndicator,
  style,
  className,
}: Props) {

  const containerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const [observer, setObserver] = useState<IntersectionObserver>(null);

  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const intersecting = entries[0].isIntersecting;
    if (intersecting) {
      onScrollEnd();
    }
  }, [onScrollEnd]);

  useEffect(() => {
    let mounted = true;
    if (!containerRef?.current || !loaderRef?.current || !handleObserver) return;
    const observerOptions: IntersectionObserverInit = {
      root: containerRef.current,
      rootMargin: '10px',
      threshold: 0,
    };
    const _observer = new IntersectionObserver(handleObserver, observerOptions);
    if (mounted) {
      setObserver(currentObserver => {
        if (currentObserver) {
          // disconnect the current observer
          // this ensures only one observer
          currentObserver.disconnect();
        }
        // start the new observer
        _observer.observe(loaderRef.current);
        return _observer;
      });
    }
    return () => {
      mounted = false;
    };
  }, [containerRef, handleObserver, loaderRef]);

  return (
    <div
      className={className}
      style={style}
      ref={containerRef}
    >
      {children}
      <div ref={loaderRef} />
      {loadingIndicator}
    </div>
  );
}
