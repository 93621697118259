export const ApiEndpoints = {
  imageUpload: 'https://images-upload-ws6pzoa3da-uc.a.run.app/'
  // 'https://images-upload-hz6t7lzria-uc.a.run.app' dev endpoint

}

export function endpoints() {
  return {
    imageUpload: `${ApiEndpoints.imageUpload}`
  }
}
