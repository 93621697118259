/**
 * Removes all query params from a URL and returns just the base paths
 */
export function stripQueryParams(url: string) {
  if (!url) return;

  return url.split('?')[0];
}

export function splitUrlToParts(url: string) {
  const urlToParse = new URL(url);
  const pathArr = urlToParse.pathname.split('/');
  pathArr.shift()
  const base = urlToParse.hostname

  return { base, pathArr }

}
