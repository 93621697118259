import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { mapQueryBy } from './algolia-to-typesense-options';

function matchPrefix(query: string): string | null {
  // Regex pattern to match '<word>:'
  query = query.trim();
  const pattern = /^(\w+):/;
  const match = query.match(pattern);

  if (match && match[1]) {
    // If a match is found, return the matched word without ':'
    return match[1];
  }

  return null;
}

function parseQuery(query: string | undefined): string {
  query = (query?.trim() === '') ? '*' : query?.trim() || '*';

  // Handle prefix queries
  const prefix = matchPrefix(query);
  if (prefix) query = query.replace(prefix + ':', '')?.trim();

  return query;
}

function parseQueryBy(params: SearchParams, index: string) {
  // Handle prefix queries
  const prefix = matchPrefix(params.q || '');

  if (prefix) return prefix;

  if (params.q?.startsWith('#') && Boolean(mapQueryBy(`${index}_tags`))) {
    return mapQueryBy(`${index}_tags`);
  }

  return params.query_by;
}

export function parseParams(params: SearchParams, index: string) {
  params.q = parseQuery(params.q);
  params.query_by = parseQueryBy(params, index);
  return params;
}