import { UserProfile } from 'types';

export function isFastShipper(user?: UserProfile) {
  if (!user || !user.seller_review?.avg_ship_time) return false;

  return user?.seller_review?.avg_ship_time <= 2;
}

export function isHighQualitySeller(user?: UserProfile) {
  if (!user || !user.seller_review?.avg_rating) return false;

  return (user.seller_review?.avg_rating as number) >= 4;
}

export function avatarPath(user?: UserProfile) {
  if (!user) return null;

  return {
    fastly: user.photo_path,
    url: user.thumb_url || user.photo_url,
  };
}

export function lastActiveTime(user?: UserProfile): string | undefined {
  const lastActive = user?.app_last_active || user?.web_last_active;
  if (!lastActive) return;

  const milliseconds = lastActive?.toDate?.()?.getTime() || (lastActive['_seconds'] || 0) * 1000 || lastActive?.seconds * 1000;

  if (!milliseconds) return;

  const now = new Date();
  const lastActiveDate = new Date(milliseconds);

  const diff = now.getTime() - lastActiveDate.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  // output should be one of 'Active Today', 'Active Yesterday', 'Active This Week', 'Active This Month'
  if (diffDays === 0) return 'Active Today';
  if (diffDays === 1) return 'Active Yesterday';
  if (diffDays < 7) return 'Active This Week';
  if (diffDays < 30) return 'Active This Month';

  return;
}