import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}

export default function InstagramIconSVG({ fillColor = COLORS.foreground }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 22'>
      <path
        fill={fillColor}
        d='M15.125 0h-8.25A6.876 6.876 0 000 6.875v8.25A6.876 6.876 0 006.875 22h8.25A6.876 6.876 0 0022 15.125v-8.25A6.876 6.876 0 0015.125 0zm4.813 15.125a4.818 4.818 0 01-4.813 4.813h-8.25a4.818 4.818 0 01-4.813-4.813v-8.25a4.818 4.818 0 014.813-4.813h8.25a4.818 4.818 0 014.813 4.813v8.25z'
      ></path>
      <path
        fill={fillColor}
        d='M11 5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm0 8.938A3.442 3.442 0 017.562 11 3.441 3.441 0 0111 7.562 3.441 3.441 0 0114.438 11 3.442 3.442 0 0111 14.438zM16.913 5.82a.733.733 0 100-1.465.733.733 0 000 1.465z'
      ></path>
    </svg>
  );
}

