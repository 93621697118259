'use client';

import { Suspense, useEffect, useRef, useState } from 'react';
import Link, { LinkProps } from 'next/link';
import classNames from 'classnames';
import LoadingTile from '@/components/tiles/loading';
import styles from '@/styles/components/tile-row.module.scss';
import ArrowRightBtnSVG from '@/assets/svg/icons/arrow-right-btn';
import ArrowLeftBtnSVG from '@/assets/svg/icons/arrow-left-btn';

export default function TileRow({
  title,
  description,
  link,
  children,
  tileStyle = 'book',
  hideScrollbar,
}: {
  title?: string;
  description?: string;
  link?: LinkProps;
  children: JSX.Element[];
  tileStyle?: 'book' | 'seller' | 'content';
  hideScrollbar?: boolean;
}) {
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState(false);
  const scrollContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = scrollContainer.current;
    function isOverflown(element) {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }

    if (isOverflown(container) || hasOverflow) {
      setHasOverflow(true);
      setShowRightScrollButton(true);
    } else {
      setHasOverflow(false);
      setShowRightScrollButton(false);
    }
  }, [setHasOverflow, hasOverflow, children?.length]);

  // On scroll of the tile container, watch to see if it is scrolled all of the way to one end.
  // If it is, hide that scroll button
  function handleOnScroll() {
    const container = scrollContainer.current;

    if (
      container &&
      Math.round(container.offsetWidth + container.scrollLeft) >=
      Math.round(container.scrollWidth)
    ) {
      setShowRightScrollButton(false);
    } else {
      setShowRightScrollButton(true);
    }

    if (
      container &&
      hasOverflow &&
      Math.round(container.offsetWidth + container.scrollLeft) <=
      Math.round(container.scrollWidth) &&
      container.scrollLeft > 30
    ) {
      setShowLeftScrollButton(true);
    } else {
      setShowLeftScrollButton(false);
    }
  }

  function scroll(direction: 'left' | 'right') {
    const container = scrollContainer.current;
    if (!container) return;

    const currentLeftScroll = container.scrollLeft;
    const scrollTravel = 1000;

    return container.scrollTo({
      left:
        direction === 'left'
          ? currentLeftScroll - scrollTravel
          : currentLeftScroll + scrollTravel,
      behavior: 'smooth',
    });
  }

  function renderLoadingTiles() {
    const numberOfTiles = tileStyle === 'content' ? 3 : 10;

    return Array.from({ length: numberOfTiles }).map((_, idx) => {
      return <LoadingTile key={idx} type={tileStyle} />;
    });
  }

  function renderTitle() {
    if (link) {
      return (
        <Link {...link} passHref>
          <h1 className={styles['header-title']}>{title}</h1>
        </Link>
      );
    }
    return <h1 className={styles['header-title']}>{title}</h1>;
  }

  function renderDescription() {
    if (!description) return null;

    return <h1 className={styles['header-desc']}>{description}</h1>;
  }

  function renderArrows() {
    return (
      <div className={styles['header-arrows']}>
        {showLeftScrollButton && (
          <div
            onClick={() => scroll('left')}
            className={classNames(
              styles['arrow-icon'],
              styles['arrow-icon-left']
            )}
          >
            <ArrowLeftBtnSVG />
          </div>
        )}
        {showRightScrollButton && (
          <div
            onClick={() => scroll('right')}
            className={classNames(
              styles['arrow-icon'],
              styles['arrow-icon-right']
            )}
          >
            <ArrowRightBtnSVG />
          </div>
        )}
      </div>
    );
  }

  return (
    <section className={classNames(styles.container)}>
      {(title || link) && (
        <div className={styles.header}>
          <div>
            {renderTitle()}
            {renderDescription()}
          </div>
          <div className={styles['header-right']}>
            {Boolean(link) && (
              <Link {...link} passHref className={styles['header-link']}>
                See all
              </Link>
            )}
            {renderArrows()}
          </div>
        </div>
      )}
      <div
        ref={scrollContainer}
        className={classNames(styles['scroll-container'], {
          [styles['scroll-container--with-scroll']]: hasOverflow,
          [styles['scroll-container--content']]: tileStyle === 'content',
          [styles['scroll-container--hide-scrollbar']]: hideScrollbar,
        })}
        onScroll={handleOnScroll}
      >
        <Suspense fallback={renderLoadingTiles()}>
          {Boolean(children) ? children : renderLoadingTiles()}
        </Suspense>
      </div>
    </section>
  );
}
