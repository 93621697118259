import { GroupedCartItem, ShippingData } from '../../clients/types';
import { Book, Discount, ShippingItem } from 'types';
import SoldByCard from './sold-by-card';

interface CartItemProps {
  cartItems: GroupedCartItem[];
  removeFromCart?: (item: Book) => any;
  markAsSold?: (item: Book, sold?: boolean) => any;
  shipping?: ShippingItem[] | null;
  style?: 'small';
  showBuyNow?: boolean;
  updateBookState?: React.Dispatch<React.SetStateAction<Book[]>>;
  discounts?: Discount[];
  noShadow?: boolean;
}

export default function CartItems({
  removeFromCart,
  markAsSold,
  cartItems,
  shipping,
  style,
  showBuyNow,
  updateBookState,
  discounts = [],
  noShadow,
}: CartItemProps) {
  return (
    <>
      {cartItems.map((group, index) => (
        <SoldByCard
          key={group.seller_id + index}
          seller_id={group.seller_id}
          items={group.items}
          removeFromCart={removeFromCart}
          markAsSold={markAsSold}
          showBuyNow={cartItems.length > 1 && showBuyNow}
          style={style}
          updateBookState={updateBookState}
          shipping={shipping?.find((item) => item.seller_id === group.seller_id)}
          discounts={discounts.filter((discount) => discount.seller.id === group.seller_id)}
          noShadow={noShadow}
        />
      ))}
    </>
  );
}
