import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}

export default function FacebookIconSVG({ fillColor = COLORS.foreground }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M13.566 22.5v-9.578h3.094l.465-3.734h-3.559V6.804c0-1.08.288-1.817 1.782-1.817l1.902-.001v-3.34a24.847 24.847 0 00-2.773-.146c-2.744 0-4.623 1.74-4.623 4.934v2.754H6.75v3.734h3.104V22.5h3.712z"
      ></path>
    </svg>
  );
}


