import COLORS from "~/lib/helpers/color-helper";

export default function ClockSvg({ width = 16, height = 16, fillColor = COLORS.grey }: { width?: number, height?: number, fillColor?: string; }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99992 14.6666C4.33325 14.6666 1.33325 11.6666 1.33325 7.99992C1.33325 4.33325 4.33325 1.33325 7.99992 1.33325C11.6666 1.33325 14.6666 4.33325 14.6666 7.99992C14.6666 11.6666 11.6666 14.6666 7.99992 14.6666ZM7.99992 2.66659C5.06659 2.66659 2.66659 5.06659 2.66659 7.99992C2.66659 10.9333 5.06659 13.3333 7.99992 13.3333C10.9333 13.3333 13.3333 10.9333 13.3333 7.99992C13.3333 5.06659 10.9333 2.66659 7.99992 2.66659Z" fill={fillColor} />
      <path d="M11.2666 8.66665H7.99992C7.59992 8.66665 7.33325 8.39998 7.33325 7.99998V4.73332C7.33325 4.33332 7.59992 4.06665 7.99992 4.06665C8.39992 4.06665 8.66659 4.33332 8.66659 4.73332V7.33332H11.2666C11.6666 7.33332 11.9333 7.59998 11.9333 7.99998C11.9333 8.39998 11.6666 8.66665 11.2666 8.66665Z" fill={fillColor} />
    </svg>
  );
}
