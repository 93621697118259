import styles from '../../styles/components/shelves-modals.module.scss';
import Button from '../button';

interface Props {
  pinned?: boolean;
  setPinned?: (pinned: boolean) => void;
}
export default function TitleCopySelector({
  pinned,
  setPinned,
}: Props) {

  return (
    <div className={styles['preference-button-group']}>
      <Button
        text='Save specific copy'
        style={'secondary'}
        onPress={() => setPinned(true)}
        className={!pinned && styles['preference-button--clear']}
      />
      <Button
        text='Save title'
        style={'secondary'}
        onPress={() => setPinned(false)}
        className={pinned && styles['preference-button--clear']}
      />
    </div>
  );
}