import { Action, ActionType, GlobalState } from '../types';

export interface SnackState {
  title?: string;
  message?: string;
  icon?: 'icon1';
  active?: boolean;
  buttonText?: string;
  buttonAction?: () => void;
  linkHref?: string;
}

const snackReducer = (
  state: GlobalState,
  action?: Action<SnackState>
): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_SNACK_DATA: {
      return {
        ...state,
        snack: {
          ...state.snack,
          data: {
            ...state.snack.data,
            active: true,
            ...payload,
          },
        },
      };
    }
    case ActionType.FLUSH_SNACK_DATA:
      return {
        ...state,
        snack: { data: { active: false } },
      };
    default:
      return state;
  }
};

export default snackReducer;
