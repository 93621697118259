'use client';

import classNames from 'classnames';
import Link from 'next/link';
import { categories } from '~/assets/data/categories';
import { navUrls } from '~/assets/data/nav-urls';
import SquaresSVG from '~/assets/svg/icons/squares';
import Pill from './pill';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import styles from '../styles/components/mobile-categories.module.scss';
import TileRow from './tile-row';
import { usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

export default function MobileCategories() {
  const { dispatch } = useGlobalState();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const categoryPaths = categories.map((category) => `${category.path}`);
  const categoriesSpacer = useMemo(
    () => categoryPaths.includes(pathname) || searchParams.get('appWebView'),
    [pathname, searchParams, categoryPaths]
  );

  const MAX_VISIBLE_CATEGORIES = 5;
  interface Category {
    path: string;
    title: string;
  }

  const handlePress = (category: Category) => {
    <Link key={category.title} href={category.path} legacyBehavior></Link>;
  };

  const renderBottomSheet = () => {
    return (
      <div>
        {categories.map((category) => {
          return (
            <Link key={category.title} href={category.path} legacyBehavior>
              <div
                onClick={() => {
                  dispatch({ type: ActionType.FLUSH_MODAL_DATA });
                }}
                className={classNames(styles['bottom-sheet-container-item'])}
              >
                <a
                  className={classNames(
                    styles['link-items'],
                    styles['link-items-bottom-sheet'],
                    styles['link-items-text']
                  )}
                >
                  {category.title}
                </a>
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  const handleOpenMore = () => {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'More Categories',
        component: renderBottomSheet(),
      },
    });
  };

  const renderPills = () => {
    const pills = categories.slice(0, MAX_VISIBLE_CATEGORIES).map((category, index) => {
      return (
        <Link key={category.path} href={category.path}>
          <Pill
            text={category.title}
            style='secondary'
            className={styles['pill-size']}
            outline={true}
            onClick={() => handlePress(category)}
          />
        </Link>
      );
    });

    return [
      ...pills,
      <div key={'more-categories'} style={{ paddingRight: '1rem' }}>
        <Pill
          onClick={handleOpenMore}
          style='secondary'
          text='More Categories'
          className={styles['pill-size']}
          outline={true}
          icon={
            <div className={styles['pill-size-icon']}>
              <SquaresSVG />
            </div>
          }
        />
      </div>,
    ];
  };

  if (
    pathname.startsWith('/checkout') ||
    pathname.startsWith('/cart') ||
    pathname.startsWith('/order/payment') ||
    pathname.startsWith('/titles') ||
    pathname.startsWith('/books')
  ) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles['container'], { [styles['container-category-page']]: categoriesSpacer })}>
        <TileRow hideScrollbar={true}>{renderPills()}</TileRow>
      </div>
    </>
  );
}
