interface Props {
  fillColor?: string;
  size?: number;
}

export default function InfoTruckSVG({ fillColor = '#A5ABC0', size = 16 }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.33325 1.94659V7.48659C9.33325 8.16658 8.77992 8.71992 8.09992 8.71992H1.99992C1.63325 8.71992 1.33325 8.41992 1.33325 8.05325V3.79325C1.33325 2.43325 2.43325 1.33325 3.79325 1.33325H8.71325C9.05992 1.33325 9.33325 1.60659 9.33325 1.94659Z" fill={fillColor} />
      <path d="M14.3333 10.3333C14.5199 10.3333 14.6666 10.4799 14.6666 10.6666V11.3333C14.6666 12.4399 13.7733 13.3333 12.6666 13.3333C12.6666 12.2333 11.7666 11.3333 10.6666 11.3333C9.56659 11.3333 8.66659 12.2333 8.66659 13.3333H7.33325C7.33325 12.2333 6.43325 11.3333 5.33325 11.3333C4.23325 11.3333 3.33325 12.2333 3.33325 13.3333C2.22659 13.3333 1.33325 12.4399 1.33325 11.3333V9.99992C1.33325 9.63325 1.63325 9.33325 1.99992 9.33325H8.33325C9.25325 9.33325 9.99992 8.58659 9.99992 7.66659V3.99992C9.99992 3.63325 10.2999 3.33325 10.6666 3.33325H11.2266C11.7066 3.33325 12.1466 3.59325 12.3866 4.00659L12.8133 4.75325C12.8733 4.85992 12.7933 4.99992 12.6666 4.99992C11.7466 4.99992 10.9999 5.74659 10.9999 6.66659V8.66659C10.9999 9.58659 11.7466 10.3333 12.6666 10.3333H14.3333Z" fill={fillColor} />
      <path d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z" fill={fillColor} />
      <path d="M10.6666 14.6667C11.403 14.6667 11.9999 14.0697 11.9999 13.3333C11.9999 12.597 11.403 12 10.6666 12C9.93021 12 9.33325 12.597 9.33325 13.3333C9.33325 14.0697 9.93021 14.6667 10.6666 14.6667Z" fill={fillColor} />
      <path d="M14.6667 8.35333V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.4933 7.69333C14.6067 7.89333 14.6667 8.12 14.6667 8.35333Z" fill={fillColor} />
    </svg>
  );
}

