import { ICartItem } from '../../clients/types';

import { Action, ActionType, GlobalState } from '../types';

export interface CartState {
  items: ICartItem[];
  loaded: boolean;
}

const cartReducer = (
  state: GlobalState,
  action?: Action<CartState>
): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.SYNC_CART: {
      let updatedCartItems = payload?.items ? payload.items : [];
      return {
        ...state,
        cart: {
          ...state.cart,
          data: {
            ...state.cart.data,
            items: updatedCartItems,
            loaded: true
          },
        },
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
