'use client';

import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';

export default function Segment() {
  const [writeKey] = useState(process.env.NEXT_PUBLIC_SEGMENT_KEY);

  const { state, dispatch } = useGlobalState();

  useEffect(() => {
    if (state?.analytics?.data?.analytics) return;

    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({
        writeKey,
      });
      dispatch({
        type: ActionType.LOAD_ANALYTICS,
        payload: { analytics: response },
      });
    };

    loadAnalytics();
  }, [writeKey, state?.analytics?.data?.analytics]);

  return null;
}
