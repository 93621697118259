import { HTMLAttributes, HTMLInputTypeAttribute } from 'react';
import { Formik, Form as FormikForm, useFormikContext, Field } from 'formik';

import InputLabel from './input-label';

import styles from '../styles/components/form.module.scss';
import inputStyles from '../styles/components/form/input.module.scss';
import classNames from 'classnames';

interface InputProps {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  name: string;
  required?: boolean;
  component?: any;
  disables?: boolean;
  className?: any;
  disabled?: boolean;
  value?: any;
  as?: any;
  options?: { name: string; value: string }[];
}

function Form(props: any) {
  return (
    <Formik {...props}>
      <FormikForm>{props.children}</FormikForm>
    </Formik>
  );
}

function Group({ children }) {
  return <div className={styles.group}>{children}</div>;
}
Form.Group = Group;

function Label({ children }) {
  return <InputLabel>{children}</InputLabel>;
}
Form.Label = Label;

function Input(props: InputProps) {
  const { values, handleChange, handleBlur } = useFormikContext();

  return (
    <Field
      as={props.as}
      type={props.type}
      placeholder={props.placeholder}
      name={props.name}
      value={values[props.name]}
      onChange={handleChange}
      onBlur={handleBlur}
      required={props.required}
      component={props.type === 'textarea' ? 'textarea' : props.component}
      className={classNames(props.className, inputStyles.input, {
        [inputStyles['input-disabled']]: props.disabled,
      })}
    >
      {props.options && props.options.map(op => <option key={op.value} value={op.value}>{op.name}</option>)}
    </Field>
  );
}
Form.Input = Input;

export default Form;
