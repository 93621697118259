
interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
  strokeColor?: string;
}
export default function ArrowRightBtnSVG({ width = 32, height = 32, fillColor = 'white', strokeColor = '#3B61EB' }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16' cy='16' r='16' fill={fillColor} />
      <circle
        cx='16'
        cy='16'
        r='15.5'
        stroke={strokeColor}
        strokeOpacity='0.1'
      />
      <path
        d='M15 20L19 16L15 12'
        stroke={strokeColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}