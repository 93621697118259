'use client';

/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import type { ImageProps } from 'next/image';

import styles from '@/styles/components/loadable-image.module.scss';
import { NO_IMAGE_PATH, images } from 'sdk';

export default function LoadableImage(props: ImageProps) {
  const [loaded, setLoaded] = useState(false);
  const [src, setSrc] = useState(props.src);

  const onError = () => {
    setSrc(images(NO_IMAGE_PATH).book.thumb);
  };

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  return (
    <div
      className={classNames(styles.image, {
        [styles.loaded]: loaded,
      })}
    >
      {src && (
        <Image
          {...props}
          src={src}
          alt={props.alt}
          loader={({ src }) => src}
          unoptimized
          onLoad={() => {
            setLoaded(true);
          }}
          onError={onError}
        />
      )}
    </div>
  );
}
