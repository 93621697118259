import { Title } from 'types';

import { pluralize, numberWithCommas } from 'sdk';

export function titlePhotoPath(title: Title): string {
  if (title.selected_listing?.photo_path) {
    return title.selected_listing.photo_path;
  }

  if (title.photos?.length) {
    return title.photos[0];
  }

  if (title.stock_photos?.length) {
    return title.stock_photos[0];
  }

  return '';
}

export function availableCopiesText(title: Title) {
  if (!title.copies || title.copies === 0) return null;
  return `${numberWithCommas(title.copies)} ${pluralize('copy', title.copies)} available`;
}
