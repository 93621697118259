import { useRef } from 'react';

export default function useInputFocus() {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    if (!htmlElRef.current) return;

    htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
}
