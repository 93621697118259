'use client';

import { useEffect } from 'react';
import { useGlobalState } from '@/state';
import { ActionType, Action } from '@/state/types';
import { realtimeCollectionListener } from '@/clients/firebase/client';
import { CartState } from '@/state/reducers/cart';

/**
 * @description This is responsible for listening to the database for cart changes and
 * syncing those changed with the local cart state
 * @returns None
 */
export default function CartManager() {
  const { state, dispatch } = useGlobalState();

  useEffect(() => {
    let unsubscribe = null;
    const uid = state?.user?.data?.uid;
    if (uid) {
      unsubscribe = realtimeCollectionListener(
        `cart/${uid}/cart`,
        [],
        (snapshot) => {
          const items = [];
          snapshot.forEach((doc) => {
            items.push(doc.data());
          });
          dispatch({
            type: ActionType.SYNC_CART,
            payload: { items },
          } as Action<CartState>);
        },
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, state?.user?.data?.uid]);

  return null;
}
