import { useRouter } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';

import styles from '../../styles/components/navigation.module.scss';

import useCart from '../../lib/hooks/use-cart';
import { formatMoney, pluralize } from 'sdk';

import CartSVG from '../../assets/svg/icons/cart';

import DropDown from '../dropdown';
import Badge from '../badge';
import ListItem from '../list';
import Button from '../button';
import CartItems from '../cart-components/cart-items';
import Links from '~/lib/helpers/link-helper';
import classNames from 'classnames';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import SoldBooks from '../cart-components/sold-books';
import { Flex } from '../layout/flex';
import { Box } from '../layout/box';
import CloseSVG from '../../assets/svg/icons/close';

export default function CartNav(props: { isMobile?: boolean; }) {
  // state
  const [showDropdown, setShowDropdown] = useState(false);

  const { state, dispatch } = useGlobalState();

  // hooks
  const router = useRouter();
  const { getCartWithSellerId, removeFromCart, markCartItemAsSold, cart, cartCount, cartTotal } =
    useCart();

  const containsSoldBook = useMemo(() => Boolean(cart.find(item => item.sold)), [cart]);

  // handlers
  const handleCheckout = useCallback(
    () => {
      if (containsSoldBook) {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            title: 'Sold Book',
            component:
              <SoldBooks
                items={cart}
                removeFromCart={removeFromCart}
                type='checkout'
              />
          }
        });
      }
    },
    [containsSoldBook, dispatch, cart],
  );

  const handleDropdown = () => {
    if (props.isMobile) {
      router.push('/cart');
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  return (
    <>
      <a
        className={classNames(styles[props.isMobile ? "link-items-mobile" : 'link-items'], {
          [styles[props.isMobile ? 'link-items-mobile--selected' : "'link-items--selected'"]]: showDropdown,
        })}
        onClick={handleDropdown}
      >
        <CartSVG />
        {cartCount ? (
          <Badge testclass="cart-badge" style="primary" text={cartCount} />
        ) : null}
      </a>
      <DropDown
        open={showDropdown}
        onHide={() => setShowDropdown(false)}
        size="small"
      >
        <div className={styles['cart-items-container']}>
          <Flex direction='row' align='center' justify='space-between' mb={1}>
            <Flex direction='row' align='flex-end'>
              <Box mr={0.25}>
                <span className={styles['cart-dropdown-title-bold']}>
                  My Cart,{' '}
                </span>
              </Box>
              <span className={styles['cart-dropdown-title-light']}>
                {cartCount} {pluralize('item', cartCount)}
              </span>
            </Flex>
            <Box>
              <Button
                text='Close'
                style='secondary'
                size='xtra-small'
                onPress={() => setShowDropdown(false)}
              />
            </Box>
          </Flex>
          <CartItems
            cartItems={getCartWithSellerId()}
            removeFromCart={removeFromCart}
            markAsSold={markCartItemAsSold}
            style="small"
            showBuyNow={true}
          />
        </div>

        <ListItem topDivider bottomDivider>
          <ListItem.Content>
            <ListItem.Title>Subtotal</ListItem.Title>
          </ListItem.Content>
          <div className={styles['cart-subtotal-text']}>
            {formatMoney(cartTotal)}
          </div>
        </ListItem>

        <div className={styles['cart-button-container']}>
          {cartCount ? (
            <>
              <div className={styles['cart-button']}>
                <Button
                  text="View Cart"
                  style="secondary"
                  link={{ href: '/cart' }}
                />
              </div>
              <div className={styles['cart-button']}>
                <Button
                  text="Checkout"
                  onPress={handleCheckout}
                  link={!containsSoldBook && {
                    href: Links.checkout({ ids: cart.map((item) => item.id), cid: state?.user?.data?.uid }),
                  }}
                  disabled={cartCount > 75}
                />
              </div>
            </>
          ) : null}
        </div>
      </DropDown>
    </>
  );
}
