import React, { useEffect, useState } from 'react';
import Firebase from '@/clients/firebase/firebase-react-query';
import { useGlobalState } from '../../state';
import { images, truncate } from 'sdk';
import Image from 'next/image';
import Checkbox from '../form/checkbox';
import { ActionType } from '~/state/types';
import Link from 'next/link';

import styles from '../../styles/components/shelves-modals.module.scss';

import CreateShelfModal from './create-shelf-modal';
import ShelfIcon from '~/assets/svg/icons/shelf-icon';
import PlusSVG from '~/assets/svg/icons/plus';
import Button from '../button';
import { Shelf, UserProfile } from 'types';
import { pluralize } from 'sdk';

import useShelves from '~/lib/hooks/use-shelves';
import Spinner from '../spinner';
import LockSVG from '~/assets/svg/icons/lock';
import TitleCopySelector from './title-copy-selector';

export const ShelfItem = ({
  photo_path,
  title,
  isShelfPublic,
  id,
  checked,
  onCheck,
  isNewShelf,
  fadeUnselected,
}: {
  photo_path: string;
  title: string;
  isShelfPublic?: boolean;
  id?: string;
  checked?: boolean;
  onCheck?: () => void;
  isNewShelf?: boolean;
  fadeUnselected?: boolean;
}) => {
  const { state } = useGlobalState();
  const { user } = state;
  const renderImageOrIcon = () => {
    // if book path exists, render book image
    if (photo_path) {
      return (
        <Link
          href={`/shelves/${user.data.username}/${id}`}
          passHref
          target='_blank'
        >
          <Image
            src={images(photo_path).book.thumb}
            width={70}
            height={70}
            alt='shelf image'
            className={styles['list-item-image']}
            unoptimized
          />
        </Link>
      );
    }
    // for 'create new shelf', render plus icon
    if (isNewShelf) {
      return (
        <div className={styles['list-item-plusIcon']}>
          <PlusSVG />
        </div>
      );
    }
    // render the shelf icon as default
    return (
      <div className={styles['list-item-shelfIcon']}>
        <ShelfIcon width={70} height={70} />
      </div>
    );
  };

  return (
    <div style={{ opacity: fadeUnselected && !checked && '25%' }}>
      <div className={styles['list-item-container']} onClick={onCheck}>
        <div className={styles['list-item-data']}>
          {renderImageOrIcon()}
          <div className={styles['list-item-title']}>
            <h3>{truncate(title, 35)}</h3>
            <span>{!isShelfPublic && <LockSVG width={16} height={16} />}</span>
          </div>
        </div>
        {onCheck && <Checkbox text='' checked={checked} onPress={onCheck} />}
      </div>
    </div>
  );
};

export default function ShelvesListModal({
  titleId,
  bookId,
  pinnedCopy,
  originalShelfData,
  shelfIds,
  callback,
  preference,
  op = 'move',
}: {
  titleId: string;
  bookId?: string;
  pinnedCopy?: boolean;
  originalShelfData?: { id: string; title: string; };
  shelfIds: string[];
  callback?: (props?: any) => any;
  preference?: { pinned_copy: boolean; shelf: string; };
  op?: 'move' | 'remove';
}) {

  const [shelves, setShelves] = useState<Shelf[]>(null);
  const [newShelvesIds, setNewShelvesIds] = useState<string[]>([...shelfIds]);
  const [pinned, setPinned] = useState(preference?.pinned_copy || pinnedCopy || false);

  useEffect(() => {
    setPinned(preference?.pinned_copy || pinnedCopy || false);
  }, [preference]);

  const { moveTitle, saveToShelf, removeFromShelves } = useShelves({});

  const { dispatch, state } = useGlobalState();
  const { user, shelves: shelvesState } = state;
  const { data: shelves_data, isLoading } = Firebase<{
    shelves: Shelf[];
    user: UserProfile;
  }>().backendFunction('shelves-getUserShelves', {
    request_user_id: user.data.uid,
    username: user.data.username,
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (op === 'remove') {
        setShelves(
          shelves_data?.shelves?.filter((shelf) => shelfIds.includes(shelf.id))
        );
      } else {
        setShelves(shelves_data?.shelves);
      }
    }
    return () => {
      mounted = false;
    };
  }, [shelves_data]);

  const handleCreateNewShelf = (e: any, titleId: string, shelfId: string) => {
    e.preventDefault();

    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Create a new shelf',
        component: (
          <CreateShelfModal
            shelfId={shelfId}
            titleId={titleId}
            originalTitle={originalShelfData.title}
          />
        ),
      },
    });
  };

  const handleMoveShelf = async () => {
    if (op === 'remove') {
      await removeFromShelves({
        titleId,
        shelfIds: newShelvesIds,
        bookId,
      });
    } else {
      await moveTitle({
        titleId,
        bookId,
        shelfId: originalShelfData.id,
        newShelvesIds,
        shelves,
        pinned_copy: pinned,
      });
    }
    callback && callback();
  };

  const renderText = () => {
    if (op !== 'move') {
      return null;
    }

    return (
      <div className={styles['preference-button-wrapper']}>
        <TitleCopySelector pinned={pinned} setPinned={setPinned} />
      </div>
    );
  };

  if (isLoading || !shelves) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {renderText()}
      {shelves.map((shelf) => {
        const SELECTED = newShelvesIds.includes(shelf.id);

        return (
          <ShelfItem
            key={shelf.id}
            isShelfPublic={shelf.public}
            photo_path={
              shelf?.selected_listings
                ? shelf?.selected_listings[0]?.photo_path
                : null
            }
            id={shelf.id}
            title={shelf.title}
            checked={SELECTED}
            onCheck={() => {
              SELECTED
                ? setNewShelvesIds(
                  newShelvesIds.filter((shelf_id) => shelf_id !== shelf.id)
                )
                : setNewShelvesIds([...newShelvesIds, shelf.id]);
            }}
          />
        );
      })}
      {op === 'move' && (
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) =>
            handleCreateNewShelf(e, titleId, originalShelfData.id)
          }
        >
          <ShelfItem
            photo_path={null}
            title='Create new shelf'
            isShelfPublic={true}
            isNewShelf={true}
          />
        </div>
      )}
      <div className={styles['list-button-group']}>
        <Button
          text='Clear'
          onPress={() => {
            setNewShelvesIds([]);
          }}
          style='clear'
        />
        <Button
          text={`${op === 'move' ? 'Move to' : 'Remove from'} ${newShelvesIds.length
            } ${pluralize('shelf', newShelvesIds.length)}`}
          onPress={handleMoveShelf}
          disabled={newShelvesIds.length === 0}
        />
      </div>
    </div>
  );
}
