import { ReadonlyURLSearchParams } from 'next/navigation';
import { NextRouter } from 'next/router';
import { parse, stringify } from 'qs';
import { typesenseOptionsToUrlQuery } from 'sdk';
import { Title, UserProfile, TypesenseSearchProps } from 'types';

type Paths = '/search' | '/seller-search';

export const Links = {
  appStore: {
    apple: 'https://apps.apple.com/us/app/pangobooks/id1546752104',
    appleReviews: 'https://apps.apple.com/us/app/pangobooks-buy-sell-books/id1546752104?see-all=reviews',
    google: 'https://play.google.com/store/apps/details?id=com.grantwsingleton.pangobookslive',
    dynamic: 'https://pangobooks.page.link/app',
  },
  career: {
    index: 'https://pangobooks-inc.breezy.hr/',
  },
  blog: {
    index: { pathname: '/blog' },
    show: (postId: string) => {
      return {
        pathname: `/blog/${postId}`,
      };
    },
  },
  book: (bookId: string) => {
    return { show: { pathname: `/books/${bookId}` } };
  },
  bookstore: (user: UserProfile) => {
    return {
      show: {
        pathname: `/bookstore/${user?.username}`,
      },
    };
  },
  faq: 'https://help.pangobooks.com',
  helpCenter: {
    index: 'https://help.pangobooks.com',
    contactUs: 'https://help.pangobooks.com/en/articles/6222077-how-can-i-contact-pangobooks',
  },
  home: {
    index: { pathname: '/' },
  },
  order: (order_id: string, loggedIn: boolean) => {
    if (loggedIn) {
      return {
        pathname: `/account/settings/purchases`,
        query: {
          s: 'purchases',
          id: order_id,
        },
        url: `/account/settings/purchases?s=purchases&id=${order_id}`,
      };
    }
    return {
      pathname: `/order/${order_id}`,
      url: `/order/${order_id}`,
    };
  },
  myShelves: (username: string) => {
    return {
      pathname: `/shelves/${username}`,
    };
  },
  account: {
    index: { pathname: '/account/settings/profile' },
    wishlist: { pathname: '/account/wishlist' },
    purchases: (orderId: string) => {
      return {
        pathname: '/account/settings/purchases',
        query: { s: 'purchases', id: orderId },
      };
    },
    notifications: { pathname: '/account/notifications' },
  },
  purchases: {
    index: { pathname: '/account/settings/purchases' },
  },
  review: (orderId: string) => {
    return { show: { pathname: `/reviews/${orderId}` } };
  },
  search: {
    index: { pathname: '/search' },
  },
  socialMedia: {
    facebook: 'https://www.facebook.com/pangobooks',
    twitter: 'https://twitter.com/pangobooks',
    instagram: 'https://www.instagram.com/pangobooks',
    youtube: 'https://www.youtube.com/hashtag/pangobooks',
    tiktok: 'https://www.tiktok.com/@pangobooks',
  },
  searchWithQuery: (searchOptions: { router: NextRouter; options: TypesenseSearchProps; path?: Paths }) => {
    return {
      pathname: searchOptions.path || searchOptions.router.route,
      query: {
        ...(!searchOptions.path && searchOptions.router.query),
        search: typesenseOptionsToUrlQuery(searchOptions.options),
      },
    };
  },
  searchWithQueryAppRouter: (searchOptions: {
    pathname: string;
    searchParams?: ReadonlyURLSearchParams;
    options: TypesenseSearchProps;
    path?: Paths;
  }) => {
    const pathname = searchOptions.path || searchOptions.pathname;
    const query = {
      ...(!searchOptions.path && parse(searchOptions.searchParams.toString())),
      search: typesenseOptionsToUrlQuery(searchOptions.options),
    };
    return {
      pathname,
      query,
      url: pathname + '?' + stringify(query),
    };
  },
  title: (title: Title, id?: string) => {
    return {
      show: {
        pathname: `/titles/${title?.slug}`,
        url: `/titles/${title?.slug}${id ? `/${id}` : ''}`,
      },
    };
  },
  messages: {
    admin: {
      pathname: '/account/messages',
      query: {
        uid: process.env.NEXT_PUBLIC_ADMIN_ACCOUNT_UID, // added prod
      },
    },
    general: (messageOptions: { type: 'chatId' | 'userId'; id: string; bookId?: string; orderId?: string }) => {
      let query = {};

      if (messageOptions.bookId) {
        query = {
          ...query,
          bookId: messageOptions.bookId,
        };
      }
      if (messageOptions.orderId) {
        query = {
          ...query,
          orderId: messageOptions.orderId,
        };
      }

      if (messageOptions.type === 'chatId') {
        return {
          pathname: '/account/messages',
          query: {
            ...query,
            cid: messageOptions.id,
          },
        };
      } else if (messageOptions.type === 'userId') {
        return {
          pathname: '/account/messages',
          query: {
            ...query,
            uid: messageOptions.id,
          },
        };
      }
    },
  },
  checkout: (checkoutOptions: { ids: string[]; cid: string }) => {
    const pathname = '/checkout';
    const query = {
      ids: checkoutOptions.ids,
      cid: checkoutOptions.cid,
    };
    const url = pathname + '?' + stringify(query, { arrayFormat: 'repeat' });
    return {
      pathname,
      query,
      url,
    };
  },
  cart: {
    pathname: '/cart',
  },
  shippingLabel: ({ order_id, label_id }: { order_id: string; label_id: string }) => {
    return {
      pathname: `/labels/${order_id}/${label_id}?full=true`,
    };
  },
  about: {
    index: { pathname: '/about' },
  },
  ambassador: {
    index: { pathname: '/ambassador' },
  },
  author: {
    index: { pathname: '/author' },
    slug: ({ slug }: { slug: string }) => {
      return {
        pathname: `/author/${slug}`,
      };
    },
  },
  categories: {
    index: { pathname: '/categories' },
  },
  collections: {
    index: { pathname: '/collections' },
  },
  heyPango: {
    index: { pathname: '/hey-pango' },
  },
  marketGuide: {
    index: { pathname: '/data/low-inventory-high-demand' },
  },
  sellerSearch: {
    index: { pathname: '/seller-search' },
  },
  shelves: {
    index: { pathname: '/shelves' },
  },
  series: {
    index: { pathname: '/series' },
  },
  sellersHub: {
    index: { pathname: '/pango-sellers-hub' },
  },
  trustAndSafety: {
    index: { pathname: '/pango-trust-and-safety' },
  },
  terms: {
    index: { pathname: '/terms' },
  },
  privacy: {
    index: { pathname: '/privacy' },
  },
  pangoBucks: {
    index: { pathname: '/pangobucks' },
  },
  w9: {
    index: { pathname: '/account/form-w9' },
  },
  payment: {
    index: { pathname: '/order/payment/t' },
  },
  paymentWithParams: ({
    payment_intent,
    payment_intent_client_secret,
  }: {
    payment_intent: string;
    payment_intent_client_secret: string;
  }) => {
    return {
      pathname: '/order/payment',
      query: { payment_intent, payment_intent_client_secret },
      url: `/order/payment?payment_intent=${payment_intent}&payment_intent_client_secret=${payment_intent_client_secret}`,
    };
  },
};

export default Links;
