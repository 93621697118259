export function validateAndParseUrl({ url }: { url: string; }): { valid: boolean; params: object; } {
  try {
    const urlObj = new URL(url);
    const params = Object.fromEntries(urlObj.searchParams.entries());
    return {
      valid: true,
      params,
    };
  } catch (e: any) {
    return {
      valid: false,
      params: {},
    };
  }
}