import p from 'pluralize';

export function slugify(s: string) {
  if (typeof s !== 'string') return;

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return s
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

/**
 * Given a string and how many of that thing exists,
 * this will return a plural form of the word if its applicable;
 *
 * eg 1 dog, 2 dogs
 * @param string
 * @param count
 * @returns plural or singular string
 */
export function pluralize(string: string, count: number) {
  return p(string, count);
}

export function possessive(s: string) {
  const APOSTROPHE_CHAR = '’';

  if (s == '' || !s) {
    return s;
  }
  let lastChar = s.slice(-1);
  let endOfWord = lastChar?.toLowerCase() == 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${s}${endOfWord}`;
}

export function truncate(string: string, length: number) {
  if (!string) return '';
  const trimmed = string.trim();
  let truncated = trimmed.substring(0, length);
  if (truncated.length < trimmed.length) {
    truncated += '...';
  }
  return truncated;
}

export function authorNameToSlug(name: string) {
  return slugify(name.replace(/\s*\(.*?\)\s*/g, '')); // Remove content within parentheses
}

export function capitalizeFirst(text: string) {
  if (!text || text.trim() === '') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function numberWithCommas(number: number) {
  if (!number) return;

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function lowerCaseDashes(str: string) {
  if (!str) return str;
  return str?.toLowerCase().replace(/ /g, '-');
}

export function removeLeadingAndTrailingSlashes(str: string) {
  return str.replace(/^\/+|\/+$/g, '');
}

export function removeNonAlphanumeric(s: string): string {
  const alphaNumericRegex = /[^a-zA-Z0-9]/g;
  return s?.replace(alphaNumericRegex, '');
}

export function stringDollarsToNumberCents(dollarsString: string) {
  const dollars = parseFloat(dollarsString);
  const cents = Math.round(dollars * 100);
  return cents;
}

export function doubleToStringPercentRounded(double: number) {
  return `${Math.round(double * 100)}%`;
}