import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import Footer from '~/components/footer';
import MobileCategories from '~/components/mobile-categories';
import { Navigation } from '~/components/navigation';
import { useGlobalState } from '~/state';

const Layout: React.FC = (props) => {
  const { state } = useGlobalState();
  const router = useRouter();

  // TODO - refactor both of these to be arguments provided to the layout
  const removeNav = useMemo(
    () =>
      ['/labels/[order_id]/[label_id]', '/auth/paypal/[uid]', '/hey-pango'].includes(
        router.pathname
      ) || router.query['appWebView'],
    [router]
  );

  const removeFooter = useMemo(
    () => ['/account/messages', '/hey-pango'].includes(router.pathname) || router.query['appWebView'],
    [router]
  );

  const removeMobileCategories = useMemo(
    () => ['/checkout', '/about'].includes(router.pathname) || router.query['appWebView'],
    [router]
  );

  return (
    <div
      className={classNames({
        content: true,
        'modal-visible': state.modal.data.active,
        'admin-editing': state.ui.data.adminPageEditing,
        loading: state.ui.data.routeIsTransitioning,
      })}
    >
      {removeNav ? null : <Navigation />}
      {removeNav || removeMobileCategories ? null : <MobileCategories />}
      {props.children as any}
      {removeNav || removeFooter ? null : <Footer />}
    </div>
  );
};

export default Layout;
