'use client';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import StarFull from '~/assets/svg/icons/star-full';
import StarHalf from '~/assets/svg/icons/star-half';
import COLORS from '~/lib/helpers/color-helper';
import styles from '../styles/components/star-rating.module.scss';

export default function StarRating({
  maxStars,
  onRatingChange,
  size = 24,
  emptyColor = COLORS.borderLight,
  initialRating = 0,
  disabled = false,
  enablePointer,
  disableHalfStars = false,
  onDisabledPress,
}: {
  maxStars: number;
  onRatingChange?: (rating: number) => void;
  size?: number;
  emptyColor?: string;
  initialRating?: number;
  disabled?: boolean;
  enablePointer?: boolean;
  disableHalfStars?: boolean;
  onDisabledPress?: () => void;
}) {
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const [rating, setRating] = useState<number>(0);
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window);
  }, []);

  useEffect(() => {
    setRating(disableHalfStars ? Math.round(initialRating) : Math.round(initialRating * 2) / 2);
  }, [initialRating, disableHalfStars]);

  const renderStars = () => {
    let stars = [];
    for (let i = 0; i < maxStars; i++) {
      let starDisplay = <StarFull size={size} fillColor={emptyColor} />;
      if (rating > i && hoverIndex === -1) {
        starDisplay =
          rating > i + 0.5 ? (
            <StarFull size={size} fillColor={COLORS.yellow} />
          ) : (
            <StarHalf size={size} leftHalfColor={COLORS.yellow} rightHalfColor={emptyColor} />
          );
      } else if (hoverIndex > i) {
        starDisplay =
          hoverIndex > i + 0.5 ? (
            <StarFull size={size} fillColor={COLORS.yellow} />
          ) : (
            <StarHalf size={size} leftHalfColor={COLORS.yellow} rightHalfColor={emptyColor} />
          );
      }
      stars.push(
        <div
          key={i}
          className={classNames(styles['star'], {
            [styles['star-disabled']]: disabled,
            [styles['star-enable-pointer']]: enablePointer,
          })}
          {...(!isTouchDevice && { onMouseEnter: () => !disabled && setHoverIndex(i + 1) })}
          {...(!isTouchDevice && {
            onMouseMove: (e) => {
              if (!disabled && e.nativeEvent.offsetX < e.currentTarget.clientWidth / 2 && !disableHalfStars) {
                setHoverIndex(i + 0.5);
              } else if (!disabled) {
                setHoverIndex(i + 1);
              }
            },
          })}
          {...(!isTouchDevice && { onMouseLeave: () => !disabled && setHoverIndex(-1) })}
          onClick={(e) => {
            e.stopPropagation();
            if (disabled) {
              onDisabledPress?.();
              return;
            }
            let finalRating = 0;
            if (isTouchDevice) {
              if (e.nativeEvent.offsetX < e.currentTarget.clientWidth / 2 && !disableHalfStars) {
                finalRating = Math.floor(i) + 0.5;
              } else {
                finalRating = Math.floor(i) + 1;
              }
            } else {
              finalRating = disableHalfStars ? Math.floor(hoverIndex) : hoverIndex;
            }
            // force final rating to be between 1 and 5
            if (finalRating <= 0) {
              finalRating = 1;
            } else if (finalRating > 5) {
              finalRating = 5;
            }
            setRating(finalRating);
            if (onRatingChange) {
              onRatingChange(finalRating);
            }
          }}
        >
          {starDisplay}
        </div>
      );
    }
    return stars;
  };

  return (
    <div className={styles['stars-container']}>
      {renderStars()}
    </div>
  );
}
