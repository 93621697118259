'use client';

import classNames from 'classnames';
import Link from 'next/link';
import { images, formatMoney, priceDropPrice, mapSortBy, mapQueryBy } from 'sdk';

import { Book, ProductAddedSource, ProductViewSource, UserProfile } from 'types';

import LoadableImage from '@/components/loadable-image';
import SessionForm from '@/components/session-form';
import Tile from '.';

import useCart from '@/lib/hooks/use-cart';
import useAuthenticatedAction from '@/lib/hooks/use-authenticated-action';
import { analyticsBookData } from 'sdk';
import useAnalytics from '@/lib/hooks/use-analytics';
import useShelves from '@/lib/hooks/use-shelves';

import styles from '@/styles/components/tiles/book.module.scss';
import Firebase from '@/clients/firebase/firebase-react-query';
import UserReviewStars from '@/components/user-review-stars';
import HardCoverIconSVG from '@/assets/svg/icons/hard-cover-icon';
import CartButtonFilledSVG from '@/assets/svg/icons/cart-button-filled';
import CartButtonEmptySVG from '@/assets/svg/icons/cart-button-empty';
import WishEmptySVG from '@/assets/svg/icons/wish-empty';
import WishFilledSVG from '@/assets/svg/icons/wish-filled-new';
import InfoMoneySVG from '@/assets/svg/icons/info-money';
import InfoTruckSVG from '@/assets/svg/icons/info-truck';
import { useMemo } from 'react';
import Button from '@/components/button';
import Tooltip from '@/components/tooltip';
import Links from '@/lib/helpers/link-helper';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export default function BookTile(props: {
  book: Book;
  source: ProductViewSource;
  cartAddSource?: ProductAddedSource;
  showSeller?: boolean;
  cartStyle?: 'default' | 'large';
  sold?: boolean;
}) {
  const { book, source, showSeller } = props;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { analyticEvents } = useAnalytics();

  const SHOW_LARGE_CART = useMemo(() => props.cartStyle === 'large', [props.cartStyle]);

  const { addToCart, isInCart, removeFromCart } = useCart();
  const { handleShelf, isBookInShelf } = useShelves({ book, source });
  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => handleShelf({ book, type: 'book' }),
  });

  const seller = Firebase<UserProfile>().fetchCollectionDocByUid(props.book?.seller_id, 'users', true, {
    enabled: showSeller,
  });

  function handleShelfClick(e) {
    e.preventDefault();

    handleAuthAction({
      title: 'Login to add to Your Shelves',
      message: "You need an account to favorite this book, please sign in with one of the methods below.",
      component: <SessionForm />,
    });
  }

  function renderSeeMoreButton() {
    return (
      <Button
        style='secondary'
        size='xtra-small'
        text={'See more like this'}
        onPress={(e) => {
          e.preventDefault();
          router.push(Links.searchWithQueryAppRouter({
            pathname,
            searchParams,
            options: {
              index: 'books',
              params: {
                q: '*',
                sort_by: mapSortBy('books'),
                query_by: mapQueryBy('books'),
                filter_by: `title_id:${book.title_id}`,
              },
              options: {}
            },
          }).url);
        }
        }
      />
    );
  }

  async function handleCartClick(e, book) {
    e.preventDefault();

    if (isInCart(book)) {
      await removeFromCart(book);
    } else {
      await addToCart({
        item: book,
        source: props.cartAddSource || 'tile'
      });
    }
  }

  function renderShelf() {
    return (
      <div className={styles['wish-position']}>
        <Tooltip
          content='Add this to a shelf'
          position='left'
        >
          <div onClick={(e) => handleShelfClick(e)} className={styles['wish']}>
            {isBookInShelf(book) ? <WishFilledSVG /> : <WishEmptySVG />}
          </div>
        </Tooltip>
      </div>
    );
  }

  function renderAddToCart() {
    const inCart = isInCart(book);

    if (SHOW_LARGE_CART) {
      return (
        <Button
          text='Add to Cart'
          style={inCart ? 'primary' : 'outline'}
          size='small'
          onPress={(e) => handleCartClick(e, book)}
        />
      );
    }

    return (
      <div className={styles['cart-position']}>
        <Tooltip
          content='Add this to your cart'
          position='left'
        >
          <div className={classNames(styles.cart)} onClick={(e) => handleCartClick(e, book)}>
            {inCart ? <CartButtonFilledSVG /> : <CartButtonEmptySVG />}
          </div>
        </Tooltip>
      </div>
    );
  }

  function renderSellerInfo() {
    if (!props.showSeller || !seller?.data?.username || !seller?.data?.photo_path) return;

    return (
      <div className={styles['seller-info']}>
        <div className={styles['seller-info-image']}>
          <LoadableImage
            src={images(seller?.data?.photo_path as string).user.profileImage}
            alt={book.title}
            width={25}
            height={25}
          />
        </div>
        <div className={styles['seller-info-content']}>
          <h2>@{seller.data.username}</h2>
          <UserReviewStars user={seller.data} />
        </div>
      </div>
    );
  }

  function renderInfoIcons() {
    const discounts = book?.discounts;
    const SHOULD_SHOW_SALES = discounts?.find(d => d.type === 'percent') || discounts?.find(d => d.type === 'value');
    const SHOULD_SHOW_SHIPPING = discounts?.find(d => d.type === 'shipping');
    const SHOULD_SHOW_HARDCOVER = book.format?.display === 'Hardcover';

    return (
      <div className={styles['sales-icon-container']} >
        {SHOULD_SHOW_SALES &&
          <Tooltip
            content='This seller is having a sale. Visit their shop for details.'
            position='left'
          >
            <div className={styles['info-icon']}>
              <InfoMoneySVG />
            </div>
          </Tooltip>
        }
        {SHOULD_SHOW_SHIPPING &&
          <Tooltip
            content='This seller is offering free shipping on qualifying orders. Visit their shop for details.'
            position='left'
          >
            <div className={styles['info-icon']}>
              <InfoTruckSVG />
            </div>
          </Tooltip>
        }
        {SHOULD_SHOW_HARDCOVER &&
          <Tooltip
            content="This icon means you're looking at a harcover copy. No icon means it's a paperback."
            position='left'
          >
            <div className={styles['info-icon']}>
              <HardCoverIconSVG />
            </div>
          </Tooltip>
        }
      </div>
    );
  }

  function handleAnalytics() {
    analyticEvents.productClick({
      product: analyticsBookData(book),
      source,
      index: 'books',
      objectID: book.id
    });
  }

  function renderSoldBanner() {
    // @ts-ignore
    if (!book.sold) return;
    return (
      <div className={styles['sold-banner']}>
        <div className={styles['sold-text']}>Sold</div>
      </div>
    );
  }

  if (!book) return null;

  return (
    <Tile>
      <Link
        href={`/books/${encodeURIComponent(book.id)}`}
        passHref
        data-testclass="book-link"
        onClick={() => handleAnalytics()}>

        <div className={styles['image-container']}>
          {images(book.photo_path).book.tile && (
            <LoadableImage
              src={images(book.photo_path).book.tile}
              alt={book.title}
              fill
            />
          )}
          {renderSoldBanner()}
          {renderShelf()}
        </div>
        {renderSellerInfo()}
        <div className={styles['content-container']}>
          <div className={styles['content-top']}>
            <h2 className={styles.author}>{book.author}</h2>
            {renderInfoIcons()}
          </div>
          <h1 className={styles.title}>{book.title}</h1>

          {!props.sold ? <div className={classNames(styles['content-bottom'], {
            [styles['content-bottom--large']]: SHOW_LARGE_CART
          })}>
            <div className={styles.info}>
              <div className={styles['info-price']}>
                <span>{formatMoney(book.amount)}</span>
                {priceDropPrice(book) && (
                  <span className={styles['info-price-drop']}>{formatMoney(priceDropPrice(book))}</span>
                )}
              </div>
            </div>
            {renderAddToCart()}
          </div> : renderSeeMoreButton()}
        </div>

      </Link>
    </Tile>
  );
}

export function BookTileSmall(props: { book: Book; source: ProductViewSource; }) {
  const { book, source } = props;

  const { analyticEvents } = useAnalytics();

  function handleAnalytics() {
    analyticEvents.productClick({
      product: analyticsBookData(book),
      source,
      index: 'books',
      objectID: book.id
    });
  }

  return (
    (<Link
      href={`/books/${encodeURIComponent(book.id)}`}
      passHref
      className={styles['tile-small']}
      onClick={() => handleAnalytics()}>

      <div className={styles['image-container']}>
        <LoadableImage
          src={book.thumb_url || book.photo_url}
          alt={book.title}
          fill
        />
      </div>

    </Link>)
  );
}
