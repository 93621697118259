export default function LogoMiniSVG() {
  return (
    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.29167C0 3.26459 3.148 0 7.03125 0H26.7188C30.602 0 33.75 3.26459 33.75 7.29167V27.7083C33.75 31.7354 30.602 35 26.7188 35H7.03125C3.148 35 0 31.7354 0 27.7083V7.29167Z"
        fill="#3B61EB"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.73437 4.08236C7.73437 3.76019 7.48253 3.49902 7.17187 3.49902H5.0625C4.13052 3.49902 3.375 4.28253 3.375 5.24902V7.43652C3.375 7.75869 3.62684 8.01986 3.9375 8.01986C4.24816 8.01986 4.5 7.75869 4.5 7.43652V5.24902C4.5 4.92686 4.75184 4.66569 5.0625 4.66569H7.17187C7.48253 4.66569 7.73437 4.40452 7.73437 4.08236ZM29.8125 26.9782C29.5018 26.9782 29.25 27.2394 29.25 27.5615V29.749C29.25 30.0712 28.9982 30.3324 28.6875 30.3324H26.5781C26.2675 30.3324 26.0156 30.5935 26.0156 30.9157C26.0156 31.2379 26.2675 31.499 26.5781 31.499H28.6875C29.6195 31.499 30.375 30.7155 30.375 29.749V27.5615C30.375 27.2394 30.1232 26.9782 29.8125 26.9782Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1829 6.70801H17.1017V17.4997H17.1025C17.1017 17.5386 17.1013 17.5776 17.1013 17.6168C17.1013 20.5872 19.3764 22.8953 22.1913 22.8953C25.0255 22.8953 27.2813 20.5872 27.2813 17.6168C27.2813 14.6263 25.0255 12.3383 22.1913 12.3383C21.0651 12.3383 20.0252 12.7046 19.1829 13.3356V6.70801ZM19.1836 17.6168C19.1836 19.4432 20.4947 20.788 22.1913 20.788C23.888 20.788 25.1991 19.4432 25.1991 17.6168C25.1991 15.7904 23.888 14.4457 22.1913 14.4457C20.4947 14.4457 19.1836 15.7904 19.1836 17.6168Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55 28.291H6.46875V17.4993H6.46992C6.46914 17.4604 6.46875 17.4214 6.46875 17.3822C6.46875 14.4118 8.74383 12.1037 11.5588 12.1037C14.393 12.1037 16.6488 14.4118 16.6488 17.3822C16.6488 20.3727 14.393 22.6607 11.5588 22.6607C10.4323 22.6607 9.39235 22.2944 8.55 21.6632V28.291ZM8.55103 17.3822C8.55103 15.5558 9.86209 14.2111 11.5588 14.2111C13.2554 14.2111 14.5665 15.5558 14.5665 17.3822C14.5665 19.2086 13.2554 20.5533 11.5588 20.5533C9.86209 20.5533 8.55103 19.2086 8.55103 17.3822Z"
        fill="white"
      />
    </svg>
  );
}
