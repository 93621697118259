'use client';

import { useEffect, useRef } from 'react';

import { useGlobalState } from '../state';
import { ActionType } from '../state/types';

import styles from '../../src/styles/components/snack.module.scss';
import CloseSVG from '~/assets/svg/icons/close';
import COLORS from '~/lib/helpers/color-helper';
import SnackSvgCheck from '~/assets/svg/icons/snack-svg-check';
import Button from './button';
import Link from 'next/link';

export default function Snack() {
  const { state, dispatch } = useGlobalState();
  const { data } = state.snack;

  const snackContainerRef = useRef<HTMLDivElement>(null);

  function closeSnack() {
    return dispatch({ type: ActionType.FLUSH_SNACK_DATA });
  }

  // close the snack after 5 seconds
  useEffect(() => {
    if (!data.active) return;

    const timer = setTimeout(() => {
      closeSnack();
    }, 5000);

    // reset the timer when the data changes
    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  if (!data.active) return null;

  const component = (
    <section className={styles.container}>
      <div onClick={() => closeSnack()} className={styles['close-icon']}>
        <CloseSVG strokeColor={COLORS.copyLight} />
      </div>
      <div>
        <SnackSvgCheck />
      </div>
      <div ref={snackContainerRef} className={styles.content}>
        {data.title && <h1 className={styles.title}>{data.title}</h1>}
        {data.message && <p className={styles.message}>{data.message}</p>}
      </div>
      <div className={styles.button}>
        {data.buttonText &&
          <Button
            text={data.buttonText}
            onPress={(e) => {
              e.preventDefault();
              data.buttonAction();
            }}
            style='outline'
            size='xtra-small'
          />
        }
      </div>
    </section >
  );
  if (data.linkHref) {
    return (
      <Link href={data.linkHref}>
        {component}
      </Link>
    );
  }
  return component;
}
