'use client';

import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';

import styles from '../styles/components/button.module.scss';
import Spinner from './spinner';

export interface ButtonProps {
  style?: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'danger' | 'clear';
  size?: 'base' | 'small' | 'xtra-small';
  shape?: 'circle';
  text?: string;
  icon?: React.ReactElement;
  iconPosition?: 'left' | 'right';
  onPress?: (e?: any) => void;
  blurOnPress?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  link?: LinkProps & { target?: string; };
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  className?: string;
  testclass?: string;
}

export default function Button({
  style = 'primary',
  size = 'base',
  ...props
}: ButtonProps) {
  const blurOnPress = (clickEvent: React.MouseEvent<HTMLElement>) => {
    if (props.blurOnPress) clickEvent.currentTarget.blur();
    return props.onPress;
  };

  function baseButton() {
    return (
      <button
        aria-label={props.text}
        type={props.type}
        onClick={props.blurOnPress ? blurOnPress : props.onPress}
        disabled={props.disabled}
        id={props.id}
        className={classNames(styles.button, {
          [styles['button--secondary']]: style === 'secondary',
          [styles['button--tertiary']]: style === 'tertiary',
          [styles['button--outline']]: style === 'outline',
          [styles['button--danger']]: style === 'danger',
          [styles['button--clear']]: style === 'clear',
          [styles['button--small']]: size === 'small',
          [styles['button--x-small']]: size === 'xtra-small',
          [styles['button--circle']]: props.shape === 'circle',
          [styles['button--disabled']]: props.disabled,
          [styles['button--loading']]: props.loading,
          [props.className]: !!props.className,
        })}
        {...(props.testclass && { 'data-testclass': props.testclass })}
      >
        {props.iconPosition === 'right' ? (
          <>
            {props.text && <span className={styles.text}>{props.text}</span>}
            {props.icon && <span className={styles.icon}>{props.icon}</span>}
          </>
        ) : (
          <>
            {props.icon && <span className={styles.icon}>{props.icon}</span>}
            {props.text && <span className={styles.text}>{props.text}</span>}
          </>
        )}
        {props.loading && props.text && (
          <div className={styles.spinner}>
            <Spinner size='small' />
          </div>
        )}
        {props.loading && !props.text && (
          <span className={styles['loading-indicator']} />
        )}
      </button>
    );
  }

  if (props.link) {
    return <Link {...props.link}>{baseButton()}</Link>;
  }

  return baseButton();
}