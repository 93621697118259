export function formatMoney(amountCents: number, withCurrencySymbol = true, roundToNearestDollar?: boolean) {
  if (!amountCents && amountCents !== 0) return `${withCurrencySymbol ? '$' : ''}-.--`;

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: roundToNearestDollar ? 0 : 2, // (causes 2500.99 to be printed as $2,501)
    minimumFractionDigits: roundToNearestDollar ? 0 : 2,
  };

  if (!withCurrencySymbol) {
    delete options.style;
  }

  const formatter = new Intl.NumberFormat('en-US', options);

  const amount = amountCents / 100;

  return formatter.format(amount); /* $2,500.00 */
}
