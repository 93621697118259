'use client';
import { UseQueryOptions, useQuery as UseQuery, useInfiniteQuery as UseInfiniteQuery } from '@tanstack/react-query';
import { TypesenseSearchProps, TypesenseMultiSearchProps } from 'types';
import { DocumentSchema, SearchResponse } from 'typesense/lib/Typesense/Documents';
import { multiSearch, search, searchWithFaceting } from './search';
import { MultiSearchResponse } from 'typesense/lib/Typesense/MultiSearch';

const queryKeys = {
  search: (props: TypesenseSearchProps) => [props.index, props.params, props.options].filter(Boolean),
  multiSearch: (props: TypesenseMultiSearchProps) => [props.searchRequests, props.commonParams].filter(Boolean),
};

export function Typesense<D extends DocumentSchema>() {
  return {
    search: ({
      props,
      reactQueryOptions
    }: {
      props: TypesenseSearchProps;
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) => UseQuery<SearchResponse<D>>(queryKeys.search(props), () => search(props), reactQueryOptions),
    searchWithFaceting: ({
      props,
      reactQueryOptions
    }: {
      props: TypesenseSearchProps;
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) => UseQuery<SearchResponse<D>>(queryKeys.search(props), () => searchWithFaceting(props), reactQueryOptions),
    multiSearch: ({
      props,
      reactQueryOptions
    }: {
      props: TypesenseMultiSearchProps;
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) => UseQuery<MultiSearchResponse<D[]>>(queryKeys.multiSearch(props), () => multiSearch(props), reactQueryOptions),
    infiniteSearch: ({
      props,
      reactQueryOptions
    }: {
      props: TypesenseSearchProps;
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) =>
      UseInfiniteQuery<SearchResponse<D>>(queryKeys.search(props), ({ pageParam = 1 }) => search({ ...props, params: { ...props.params, page: pageParam || 1 } }), {
        ...reactQueryOptions,
        getNextPageParam: (lastPage: SearchResponse<any>) => lastPage.hits?.length === (props.params?.per_page || 24) ? lastPage.page + 1 : null
      }),
    infiniteSearchWithFaceting: ({
      props,
      reactQueryOptions
    }: {
      props: TypesenseSearchProps;
      reactQueryOptions?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>;
    }) =>
      UseInfiniteQuery<SearchResponse<D>>(queryKeys.search(props), ({ pageParam = 1 }) => searchWithFaceting({ ...props, params: { ...props.params, page: pageParam || 1 } }), {
        ...reactQueryOptions,
        getNextPageParam: (lastPage: SearchResponse<any>) => lastPage.hits?.length === (props.params?.per_page || 24) ? lastPage.page + 1 : null
      }),
  };
}