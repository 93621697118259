import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  strokeColor?: string;
}

export default function ProfileSVG({ strokeColor = COLORS.copyDark }: Props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12.0001C14.7614 12.0001 17 9.76149 17 7.00006C17 4.23864 14.7614 2.00006 12 2.00006C9.23858 2.00006 7 4.23864 7 7.00006C7 9.76149 9.23858 12.0001 12 12.0001Z" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26003 15 3.41003 18.13 3.41003 22" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

