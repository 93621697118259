'use client';

import { useEffect, useRef } from 'react';

import { useGlobalState } from '../state';
import { ActionType } from '../state/types';

import styles from '../../src/styles/components/modal.module.scss';
import COLORS from '~/lib/helpers/color-helper';
import Button from './button';
import Animated from './animated';
import classNames from 'classnames';
import CloseSVG from '~/assets/svg/icons/close';
import { Box } from './layout/box';

export default function Modal() {
  const { state, dispatch } = useGlobalState();
  const { data } = state.modal;

  const modalContainerRef = useRef<HTMLDivElement>(null);

  function closeModal() {
    return dispatch({ type: ActionType.FLUSH_MODAL_DATA });
  }

  function handleClickOutsideModal(event) {
    if (data.persist) return;
    if (
      modalContainerRef &&
      !modalContainerRef?.current?.contains(event.target)
    ) {
      event.preventDefault();
      closeModal();
    }
  }

  useEffect(() => {
    if (data.active) {
      document.addEventListener('mousedown', handleClickOutsideModal);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
      document.body.style.overflow = '';
    };
  });

  if (!data.active) return null;

  return (
    <section className={styles.container}>
      <Animated animation='fadeInUp'>
        <div
          ref={modalContainerRef}
          className={classNames(styles.content, {
            [styles['content--large']]: data.large,
            [styles['content--full-screen']]: data.fullScreen,
            [styles['remove-bottom-padding']]: data.removeBottomPadding,
          })}
        >
          {!data.hideCloseButton && (
            <Box className={styles['close-button']}>
              <Button
                icon={<CloseSVG strokeColor={COLORS.grey} />}
                size={'xtra-small'}
                style={'tertiary'}
                onPress={() => closeModal()}
              />
            </Box>
          )}
          {data.icon && <div className={styles.icon}>{data.icon}</div>}
          {data.title && <h1 className={styles.title}>{data.title}</h1>}
          {data.message && <p className={styles.message}>{data.message}</p>}
          {data.component && data.component}
          {data.callback && (
            <div className={styles['content-button']}>
              <Button {...data.callback} />
            </div>
          )}
          {data.secondaryCallback && (
            <div className={styles['content-button']}>
              <Button {...data.secondaryCallback} />
            </div>
          )}
          {data.showDismiss && (
            <div className={styles['content-button']}>
              <Button
                onPress={closeModal}
                text={data.dismissText || 'Cancel'}
              />
            </div>
          )}
        </div>
      </Animated>
    </section>
  );
}
