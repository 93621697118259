import React, { useState } from 'react';
import InputLabel from '../input-label';
import Input from '../form/input';
import TextArea from '../form/text-area';
import Button from '../button';

import styles from '../../styles/components/shelves-modals.module.scss';

import { useGlobalState } from '../../state';
import useShelves from '~/lib/hooks/use-shelves';
import Checkbox from '../form/checkbox';

// callback ?: (shelfData: { id: string, title: string, description: string, selected_listings: any[]; count: number; }) => void;

export default function CreateShelfModal({
  titleId,
  shelfId,
  callback,
  edit,
  initData,
  originalTitle,
}: {
  titleId?: string;
  shelfId?: string;
  callback?: (props: any) => any;
  edit?: boolean;
  initData?: {
    id: string;
    title: string;
    description: string;
    public: boolean;
    notify: boolean;
  };
  originalTitle?: string;
}) {
  const [title, setTitle] = useState(
    Boolean(initData?.title) ? initData.title : ''
  );
  const [description, setDescription] = useState(
    Boolean(initData?.description) ? initData.description : ''
  );

  const { dispatch } = useGlobalState();
  const { updateShelf } = useShelves();
  const [isShelfPublic, setIsShelfPublic] = useState<boolean>(
    initData?.public !== undefined ? initData?.public : true
  );
  const [receivePriceDropAlerts, setReceivePriceDropAlerts] = useState<boolean>(
    initData?.notify !== undefined ? initData?.notify : true
  );

  const handleSave = async () => {
    await updateShelf({
      title,
      originalTitle,
      description,
      id: initData?.id,
      op: edit ? 'edit' : 'create',
      title_id: titleId,
      shelf_id: shelfId,
      callback,
      is_public: isShelfPublic,
      notify: receivePriceDropAlerts,
    });
  };

  const CheckboxWithSubtext = ({ text, subtext, checked, onPress }) => {
    return (
      <div>
        <Checkbox text={text} checked={checked} onPress={onPress} />
        <div className={styles['createShelf-preference-subtext']}>
          {subtext}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles['createShelf-input']}>
        <InputLabel>Name</InputLabel>
        <Input
          type='text'
          name='name'
          placeholder='Enter name'
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />
      </div>
      <div className={styles['createShelf-input']}>
        <InputLabel>Description</InputLabel>
        <TextArea
          name='description'
          placeholder='Enter description'
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </div>
      <div className={styles['createShelf-preference']}>
        <CheckboxWithSubtext
          text='Make shelf public'
          subtext='This will allow everyone to view your shelf'
          onPress={() => setIsShelfPublic(!isShelfPublic)}
          checked={isShelfPublic}
        />
        <CheckboxWithSubtext
          text='Get notified on price drops'
          subtext='Receive notifications when prices drop on books from this shelf'
          onPress={() => setReceivePriceDropAlerts(!receivePriceDropAlerts)}
          checked={receivePriceDropAlerts}
        />
      </div>

      <div className={styles['createShelf-button-group']}>
        <Button
          text='Clear'
          onPress={() => {
            setTitle('');
            setDescription('');
            setIsShelfPublic(true);
            setReceivePriceDropAlerts(true);
          }}
          style='clear'
        />

        <Button text='Save' onPress={handleSave} />
      </div>
    </div>
  );
}
