'use client';

import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';
import { useCallback, useMemo, useState } from 'react';
import MessageSVG from '../../assets/svg/icons/message';
import moment from 'moment';

import XIcon from '../../assets/svg/icons/x-circle';

import { capitalizeFirst, truncate, timeUntil } from 'sdk';
import useMessages from '../../lib/hooks/use-messages';
import useNotifications from '../../lib/hooks/use-notifications';
import { MessageListItem } from '../../pages/account/messages';

import styles from '../../styles/components/navigation-components/notifications.module.scss';

import Text from '../typography/text';
import navStyles from '../../styles/components/navigation.module.scss';
import { Flex } from '../layout/flex';
import SessionForm from '../session-form';
import DropDown from '../dropdown';
import Badge from '../badge';
import { HydratedMessage, Notification } from '../../clients/types';
import { fetchCollectionDocByPath } from '~/clients/firebase/client';
import Firebase from '@/clients/firebase/firebase-react-query';
import { Links } from '~/lib/helpers/link-helper';
import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';
import { useGlobalState } from '../../state';
import { ActionType } from '../../state/types';
import Button from '~/components/button';
import InfiniteScroll from '../infinite-scroll';
import { Order, UserProfile } from 'types';
import NotificationIcon from '../../assets/svg/icons/notifications';
import NotificationFilledIcon from '~/assets/svg/icons/notification-filled';
import { limit, orderBy, where } from 'firebase/firestore';
import StarSVG from '~/assets/svg/icons/star';
import COLORS from '~/lib/helpers/color-helper';
import { stringify } from 'qs';
import ClockSvg from '~/assets/svg/icons/clock';
import ChevronIcon from '~/assets/svg/icons/chevron';

export function NotificationHeader() {
  const { state } = useGlobalState();
  const router = useRouter();

  const allowsReviewNotifications = state.user?.data?.notification_settings?.review_recent_purch !== false;

  const { data: deliveredOrders } = Firebase<Order>().fetchCollectionDocsByQuery(
    'completed_sales',
    [where('buyer_id', '==', state?.user?.data?.uid), orderBy('delivered_on', 'desc'), limit(3)],
    {
      enabled: true,
    }
  );

  const orderToReview = useMemo(() => {
    if (!deliveredOrders?.length) return null;
    const cutofDate = new Date();
    cutofDate.setDate(cutofDate.getDate() - 90);
    const filteredOrders = deliveredOrders.filter(
      (order) => order.delivered_on && order.delivered_on.toDate() > cutofDate
    );
    const unreviewedOrders = filteredOrders.filter((order) => !order.buyer_review);
    if (!unreviewedOrders?.length) return null;
    return unreviewedOrders[0];
  }, [deliveredOrders]);

  const SHOULD_SHOW = orderToReview && allowsReviewNotifications;

  if (!SHOULD_SHOW) return null;

  return (
    <div
      className={styles['noti-container']}
      onClick={() => {
        const route = Links.account.purchases(orderToReview?.order_id);
        const url = route.pathname + '?' + stringify(route.query);
        router.push(url);
      }}
    >
      <div className={classNames(styles['noti-img'], styles['noti-img-review'])}>
        <div className={styles['review-icon']}>
          <StarSVG strokeColor={COLORS.yellow} />
        </div>
      </div>
      <div className={styles['noti-content-container']}>
        <div className={styles['noti-title-container']}>
          <div className={styles['noti-title']}>Review your recent purchase</div>
        </div>
        <div className={styles['noti-message']}>{`Leave a review for the seller of your recent purchase: "${truncate(
          orderToReview?.book_data?.title,
          100
        )}"`}</div>
      </div>
    </div>
  );
}

type TabSelector = 'notifications' | 'messages';

interface Props {
  label?: boolean;
}
export default function Notifications({ label }: Props) {
  // state
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TabSelector>('messages');

  // hooks
  const { recentMessages, unreadMessageCount, moreRecentMsgs, fetchMoreRecentMsgs, handleReadMessage } = useMessages();

  const { notifications, newNotificationCount, moreNotificatons, handleNotificationView, fetchMoreNotifications } =
    useNotifications();

  const router = useRouter();
  const pathname = usePathname();

  // handlers
  const handleDropdown = async () => {
    setShowDropdown(!showDropdown);
    try {
      await handleNotificationView();
    } catch (e) {
      // err
      // console.log(e)
    }
  };

  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: handleDropdown,
  });

  const handleOpenDropdown = async (e) => {
    e.preventDefault();
    await handleAuthAction({
      title: 'Login to view your Notifications',
      message: 'You need an account to view your Notifications, please sign in with one of the methods below.',
      component: <SessionForm />,
    });
  };

  const handleMsgPress = async (msg: HydratedMessage) => {
    try {
      await handleReadMessage(msg);
    } catch (e) { }
    const route = Links.messages.general({
      type: 'chatId',
      id: msg.chatId,
    });
    const url = route.pathname + '?' + stringify(route.query);
    router.push(url);
  };

  const { dispatch } = useGlobalState();

  const handleModal = (messageText) => {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Check this out on the app!',
        message: messageText,
        component: (
          <div className={styles['noti-modal-button']}>
            <Button size='small' text={'Get The App'} onPress={() => router.push(Links.appStore.dynamic)} />
          </div>
        ),
      },
    });
  };

  const getNotificationRoute = (notiPayload: Notification) => {
    const { type, tracking_url, order_id, book_id, follower_id, web_link } = notiPayload;

    switch (type) {
      case 'book_shipped':
        window.open(tracking_url, '_blank');
        break;

      case 'book_delivered':
        router.push(Links.review(order_id).show.pathname);
        break;

      case 'book_purchased':
        router.push(Links.purchases.index.pathname);
        break;

      case 'book_sold':
        handleModal('You can see the book you sold on the app!');
        break;

      case 'follower':
        (async () => {
          const res = await fetchCollectionDocByPath<UserProfile>(follower_id, 'users');
          router.push(Links.bookstore(res).show.pathname);
        })();
        break;

      case 'likes':
        handleModal('You can see who liked your book on the app!');
        break;

      case 'comment': //
        handleModal('You can see this comment on the app!');
        break;

      case 'thread_reply':
        handleModal('You can see this thread reply on the app!');
        break;

      case 'price_decrease':
        router.push(Links.book(book_id).show.pathname);
        break;

      case 'cancel':
        handleModal('You can see this cancel request on the app!');
        break;

      case 'cancel_complete':
        router.push(Links.purchases.index.pathname);
        break;

      case 'offer':
        if (web_link) router.push(web_link);
        break;

      case 'general':
        if (web_link) router.push(web_link);
        break;

      default:
        return '/';
    }
  };

  const handleMessagesScrollEnd = useCallback(() => {
    if (moreRecentMsgs) {
      fetchMoreRecentMsgs();
    }
  }, [moreRecentMsgs, fetchMoreRecentMsgs]);

  const handleNotificationScrollEnd = useCallback(() => {
    if (moreNotificatons) {
      fetchMoreNotifications();
    }
  }, [moreNotificatons, fetchMoreNotifications]);

  //Notification/Message Menu & Infinity Scroll Component

  const renderExpiration = (noti: Notification) => {
    const { expiration, link_text } = noti;
    if (!Boolean(expiration?.timestamp)) return null;

    const renderExpirationText = () => {
      if (expiration.replacement_text) {
        return <Text as='span' size='tiny' font='light' color='red'> {expiration.replacement_text}</Text>;
      }

      const timeUntilExpiration = timeUntil({ toDate: expiration.timestamp.toDate(), format: 'readable' });


      if (timeUntilExpiration === 0) {
        return <Text size="tiny" color="red">Expired</Text>;
      }
      return <Text as='span' size='tiny' font='light'>Expires in {timeUntilExpiration}.</Text>;
    };

    return <Flex direction='row' align='flex-end' gap={5}>
      <ClockSvg fillColor={COLORS.error} />
      {renderExpirationText()}
      <Flex direction='row' align='center'>
        <Text as='span' size='tiny' font='light' underline color='copy'> {link_text}</Text>
        <ChevronIcon size={12} direction='right' />
      </Flex>
    </Flex>;

  };


  const notficationsContainer = () => {
    return (
      <div className={styles['notifications-container']}>
        <div className={styles['notifications-header-container']}>
          <div className={styles['notifications-header']}>
            <div className={styles.title}>{capitalizeFirst(selectedTab)}</div>
            <div onClick={() => setShowDropdown(false)}>
              <XIcon />
            </div>
          </div>
          <div className={styles['tab-container']}>
            <div
              className={classNames(styles['tab-item'], {
                [styles['tab-item--selected']]: selectedTab === 'notifications',
              })}
              onClick={() => setSelectedTab('notifications')}
            >
              Notifications
            </div>
            <div
              className={classNames(styles['tab-item'], {
                [styles['tab-item--selected']]: selectedTab === 'messages',
              })}
              onClick={() => setSelectedTab('messages')}
            >
              Messages
            </div>
          </div>
        </div>
        <div>
          <InfiniteScroll
            className={classNames(styles['messages-list'], {
              [styles['messages-list--show']]: selectedTab === 'messages',
            })}
            onScrollEnd={handleMessagesScrollEnd}
            loadingIndicator={
              moreRecentMsgs && (
                <div className={styles['msg-loading-container']}>
                  <div className={styles.details}>
                    <div className={styles.avatar} />
                    <div className={styles['name-txt-container']}>
                      <div className={styles.name} />
                      <div className={styles.text} />
                      <div className={styles['text-2']} />
                    </div>
                  </div>
                </div>
              )
            }
          >
            {recentMessages?.length === 0 && (
              <div className={styles.empty}>
                <div className={styles['empty-title']}>Nothing here yet</div>
                <div className={styles['empty-icon']}>
                  <MessageSVG />
                </div>
              </div>
            )}
            {recentMessages?.map((msg) => (
              <MessageListItem
                message={msg}
                active={false}
                onPress={() => handleMsgPress(msg)}
                key={msg.chatId}
                simple
              />
            ))}
          </InfiniteScroll>

          <InfiniteScroll
            className={classNames(styles['notifications-list'], {
              [styles['notifications-list--show']]: selectedTab === 'notifications',
            })}
            onScrollEnd={handleNotificationScrollEnd}
            loadingIndicator={
              moreNotificatons && (
                <div className={styles['msg-loading-container']}>
                  <div className={styles.details}>
                    <div className={styles.avatar} />
                    <div className={styles['name-txt-container']}>
                      <div className={styles.name} />
                      <div className={styles.text} />
                      <div className={styles['text-2']} />
                    </div>
                  </div>
                </div>
              )
            }
          >
            {notifications?.length === 0 && (
              <div className={styles.empty}>
                <div className={styles['empty-title']}>Nothing here yet</div>
                <div className={styles['empty-icon']}>
                  <NotificationIcon />
                </div>
              </div>
            )}

            <NotificationHeader />

            {Boolean(newNotificationCount) && <div className={styles['new-title']}>New</div>}
            {notifications?.map((noti, idx) => {
              if (idx && idx === newNotificationCount) {
                return <hr key={idx} />;
              }

              return (
                <div key={noti.id} className={styles['noti-container']} onClick={() => getNotificationRoute(noti)}>
                  {noti.photo_url && (
                    <div className={styles['noti-img']}>
                      <Image src={noti.photo_url} alt={'image'} unoptimized fill />
                    </div>
                  )}
                  <div className={styles['noti-content-container']}>
                    <div className={styles['noti-title-container']}>
                      <div className={styles['noti-title']}>{noti.title}</div>
                      <div className={styles['noti-time']}>{moment(noti.timestamp.toDate()).fromNow(true)}</div>
                    </div>
                    <div className={styles['noti-message']}>{noti.message}</div>
                    {renderExpiration(noti)}
                  </div>

                </div>
              );
              // }
            })}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <>
      <a
        className={classNames(navStyles['link-items'], navStyles['link-items-bn'], {
          [navStyles['link-items--selected']]: showDropdown,
        })}
        onClick={handleOpenDropdown}
      >
        {label && pathname.startsWith('/account/notifications') ? (
          <NotificationFilledIcon />
        ) : (
          <NotificationIcon />
        )}
        {label && <div>Notifications</div>}
        {newNotificationCount + unreadMessageCount ? (
          <Badge style='primary' text={newNotificationCount + unreadMessageCount} />
        ) : null}
      </a>
      <DropDown open={showDropdown} onHide={() => setShowDropdown(false)} size='small'>
        {notficationsContainer()}
      </DropDown>
    </>
  );
}
