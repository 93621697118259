import COLORS from '~/lib/helpers/color-helper';

interface Props {
  strokeColor?: string;
  width?: number;
  height?: number;
}

export default function CloseSVG({ strokeColor = COLORS.copyDark, width = 20, height = 20 }: Props) {
  return (
    <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' width={width} height={height}>
      <path
        d='M14.4283 5.11381L14.4287 5.11345C14.5584 4.98371 14.7668 4.98371 14.8965 5.11345C15.0179 5.23485 15.0179 5.4516 14.8965 5.57301L10.8215 9.64801L10.468 10.0016L10.8215 10.3551L14.8965 14.4301C15.0263 14.5599 15.0263 14.7683 14.8965 14.898C14.7668 15.0277 14.5584 15.0277 14.4287 14.898L10.3537 10.823L10.0001 10.4695L9.64655 10.823L5.57154 14.898C5.44181 15.0277 5.23339 15.0277 5.10365 14.898C4.97391 14.7683 4.97391 14.5599 5.10365 14.4301L9.17865 10.3551L9.53221 10.0016L9.17865 9.64801L5.10365 5.57301C4.97391 5.44327 4.97391 5.23485 5.10365 5.10512C5.23339 4.97538 5.44181 4.97538 5.57154 5.10512L9.64655 9.18012L9.99974 9.53331L10.3533 9.18048L14.4283 5.11381Z'
        fill='none'
        stroke={strokeColor}
      />
    </svg>
  );
}
