import { useState } from 'react';

import PangoUser from '../clients/pango';

import Button from './button';
import Input from './form/input';

import styles from '../styles/components/reset-password.module.scss';

export default function ResetPassword() {

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErr(null);
    setSuccess(false);

    PangoUser.sendPwResetEmail(email)
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        setErr(e.message);
      })
      .finally(() => setLoading(false));

  };

  return (
    <div className={styles['reset-password-form']} >
      <form
        onSubmit={(e) => handleSubmit(e)}
      >
        <Input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your e-mail"
          value={email}
          required
          message={!err && (success ? 'We successfully sent you an email to reset your password' : 'We will send you a link to reset your password')}
          error={!!err && err}
        />
        <Button type="submit" text="Submit" loading={loading} />
      </form>
    </div>
  );
}
