
interface Props {
  fillColor?: string;
}
export default function CartButtonFilledSVG({ fillColor = '#3B61EB' }: Props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill={fillColor} fillOpacity="0.05" />
      <path d="M27.24 13.5801H26.84L23.46 10.2001C23.19 9.93006 22.75 9.93006 22.47 10.2001C22.2 10.4701 22.2 10.9101 22.47 11.1901L24.86 13.5801H15.14L17.53 11.1901C17.8 10.9201 17.8 10.4801 17.53 10.2001C17.26 9.93006 16.82 9.93006 16.54 10.2001L13.17 13.5801H12.77C11.87 13.5801 10 13.5801 10 16.1401C10 17.1101 10.2 17.7501 10.62 18.1701C10.86 18.4201 11.15 18.5501 11.46 18.6201C11.75 18.6901 12.06 18.7001 12.36 18.7001H27.64C27.95 18.7001 28.24 18.6801 28.52 18.6201C29.36 18.4201 30 17.8201 30 16.1401C30 13.5801 28.13 13.5801 27.24 13.5801Z" fill={fillColor} />
      <path d="M27.0499 20H12.8699C12.2499 20 11.7799 20.55 11.8799 21.16L12.7199 26.3C12.9999 28.02 13.7499 30 17.0799 30H22.6899C26.0599 30 26.6599 28.31 27.0199 26.42L28.0299 21.19C28.1499 20.57 27.6799 20 27.0499 20ZM19.9999 27.5C17.6599 27.5 15.7499 25.59 15.7499 23.25C15.7499 22.84 16.0899 22.5 16.4999 22.5C16.9099 22.5 17.2499 22.84 17.2499 23.25C17.2499 24.77 18.4799 26 19.9999 26C21.5199 26 22.7499 24.77 22.7499 23.25C22.7499 22.84 23.0899 22.5 23.4999 22.5C23.9099 22.5 24.2499 22.84 24.2499 23.25C24.2499 25.59 22.3399 27.5 19.9999 27.5Z" fill={fillColor} />
    </svg>
  );
}