import classNames from 'classnames';
import styles from '../styles/components/badge.module.scss';

export interface BadgeProps {
  text?: string | number;
  style?: 'primary' | 'secondary' | 'tertiary' | 'yellow' | 'red';
  testclass?: string;
}

export default function Badge(props: BadgeProps) {
  return (
    <div
      className={classNames(styles.badge, {
        [styles['badge-secondary']]: props.style === 'secondary',
        [styles['badge-tertiary']]: props.style === 'tertiary',
        [styles['badge-yellow']]: props.style === 'yellow',
        [styles['badge-red']]: props.style === 'red',
      })}
      {...(props.testclass && { 'data-testclass': props.testclass })}
    >
      {props.text}
    </div>
  );
}
