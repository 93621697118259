import classNames from "classnames";
import { orderBy } from "firebase/firestore";
import { useMemo } from "react";
import { BuyerReview as BR, UserProfile } from "types";
import { InfiniteQueryResponse } from "~/clients/firebase/client";
import styles from "../styles/components/buyer-reviews.module.scss";
import starStyles from '../styles/components/user-review-stars.module.scss';
import Button from './button';
import ListItem from "./list";
import UserProfileImage from './user-profile-image';
import { fromNow } from 'sdk';
import StarRating from './star-rating';
import Firebase from '../clients/firebase/firebase-react-query';
import Divider from './divider';
import Spacer from './spacer';

interface BuyerReviewProps {
  review: BR;
  buyer_id: string;
  seller: UserProfile;
}
function BuyerReview({ review, buyer_id, seller  }: BuyerReviewProps) {

  const {
    data: user
  } = Firebase<UserProfile>().fetchCollectionDocByPath(
    buyer_id,
    'users',
    {
      enabled: Boolean(buyer_id)
    }
  );

  if (!user) return null;

  const showReply = () => {
    if (review?.reply) {
      return (
        <div>
          <div className={styles['reply']}>
            <div className={styles['reply-text']}>
              <div className={styles['reply-name']}>
                <div className={styles['photo-name-container']}>
                  <div className={styles['reply-img']}>
                    <UserProfileImage user={seller} link={true} />
                  </div>
                  <div className={styles['reply-header']}>
                    Response from { seller.name ? seller.name : 'seller' } 
                    <div className={styles['reply-time']}>{fromNow(review?.reply?.timestamp?.toDate())}</div>
                  </div>
                </div>
              </div>
              <div>{review.reply.content}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <ListItem>
        <ListItem.Content>
          <div className={styles['name-container']}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
              <div className={styles['review-img']}>
                <UserProfileImage user={user} link={true} />
              </div>
              <div>{user.name}</div>
            </div>
            <div className={styles['time']}>{fromNow(review?.timestamp?.toDate())}</div>
          </div>
          <div style={{ marginLeft: '2rem' }}>
            <div className={styles['stars-cont']}>
              <div className={classNames(starStyles.stars, starStyles['stars-no-shift'])}>
                <StarRating maxStars={5} initialRating={review?.rating} disableHalfStars disabled size={5} />
              </div>
            </div>
            <div>{review.review}</div>
          </div>
          {showReply()}
        </ListItem.Content>
      </ListItem>
      <Spacer size='small' />
      <Divider style={{ opacity: 0.75 }} />
    </>
  );
}

interface Props {
  seller: UserProfile;
}
export default function BuyerReviews({ seller }: Props) {

  const {
    data: reviewData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = Firebase<BR>().fetchCollectionDocsByInfiniteQuery({
    path: `reviews/${seller.id}/reviews`,
    constraints: [orderBy('timestamp', 'desc')],
    reactQueryOptions: {
      enabled: Boolean(seller.id),
      getNextPageParam: (lastPage: InfiniteQueryResponse<BR>, pages: InfiniteQueryResponse<BR>[]) => lastPage?.hasMore ? lastPage?.nextCursor : null
    }
  });

  const SHOULD_SHOW_MORE = useMemo(() => hasNextPage, [hasNextPage]);

  return (
    <div>
      <div className={styles['header']}>Reviews</div>
      {reviewData?.pages?.map((page, i) => {
        return page?.data?.filter((review) => !review?.expired).map((review) => {
          return <BuyerReview key={review.id} review={review} buyer_id={review.buyer_id} seller={seller}  />;
        });
      })}
      {SHOULD_SHOW_MORE && <div className={styles['btn']}>
        <Button
          text="More"
          style='secondary'
          size='xtra-small'
          loading={isFetchingNextPage}
          disabled={isFetchingNextPage || !hasNextPage}
          onPress={fetchNextPage}
        />
      </div>}
    </div>
  );
}