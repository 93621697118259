import {
  DocumentSnapshot,
  limit,
  orderBy,
  serverTimestamp,
  startAfter,
} from 'firebase/firestore';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Notification } from '~/clients/types';
import {
  fetchCollectionDocsByQuery,
  realtimeCollectionListener,
  updateFirestoreDoc,
} from '../../clients/firebase/client';
import { useGlobalState } from '../../state';

const BATCH_SIZE = 10;

export default function useNotifications() {

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [notificationCursor, setNotificationCursor] = useState<DocumentSnapshot>(null);

  const { state } = useGlobalState();
  const uid = state.user?.data?.uid;
  const last_news_view = state.user?.data?.last_news_view;
  let newNotificationCount = 0;
  const lastReadIndex = notifications?.findIndex(n => n.timestamp?.toDate()?.getTime() < last_news_view?.toDate()?.getTime());
  if (lastReadIndex > -1) {
    newNotificationCount = lastReadIndex;
  }

  const handleNotificationView = async () => {
    if (!uid) return;
    try {
      await updateFirestoreDoc(
        'users',
        uid,
        { last_news_view: serverTimestamp() },
      );
    } catch (e) {
      // err
    }
  };

  useEffect(() => {
    let unsubscribe = null;
    if (uid) {
      unsubscribe = realtimeCollectionListener(
        `users/${uid}/news_events`,
        [orderBy('timestamp', 'desc'), limit(BATCH_SIZE)],
        (snapshot => {
          if (snapshot.empty) {
            setNotifications(null);
            setNotificationCursor(null);
          }
          const _notifications: Notification[] = [];
          for (const doc of snapshot.docs) {
            _notifications.push({ ...doc.data(), id: doc.id } as Notification);
          }
          let cursor = null;
          if (snapshot?.docs?.length >= BATCH_SIZE) {
            cursor = snapshot.docs[snapshot.docs.length - 1];
          }
          setNotifications(_notifications);
          setNotificationCursor(cursor);
        }),
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [uid]);


  const fetchMoreNotifications = useCallback(async () => {
    if (Boolean(notificationCursor)) {
      const _moreNotifications = await fetchCollectionDocsByQuery<Notification>({
        path: `users/${uid}/news_events`,
        constraints: [orderBy('timestamp', 'desc'), startAfter(notificationCursor), limit(BATCH_SIZE)],
        parse: false,
        returnDocSnap: true,
      });

      let cursor = null;
      if (_moreNotifications.length >= BATCH_SIZE) {
        cursor = _moreNotifications[_moreNotifications.length - 1].docSnap;
      }
      setNotifications([...notifications, ..._moreNotifications]);
      setNotificationCursor(cursor);
    }
  }, [notifications, notificationCursor, uid]);

  return {
    notifications,
    viewed: last_news_view,
    newNotificationCount,
    moreNotificatons: Boolean(notificationCursor),
    handleNotificationView,
    fetchMoreNotifications,
  };
} 
