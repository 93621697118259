import { AnalyticsState } from './reducers/analytics';
import { CartState } from './reducers/cart';
import { ModalState } from './reducers/modal';
import { SnackState } from './reducers/snack';
import { UiState } from './reducers/ui';
import { UserState } from './reducers/user';
import { WishlistState } from './reducers/wishlist';
import { ShelvesState } from './reducers/shelves';

export interface GlobalState {
  cart: StoreKey<CartState>;
  wishlist: StoreKey<WishlistState>;
  modal: StoreKey<ModalState>;
  user: StoreKey<UserState>;
  ui: StoreKey<UiState>;
  analytics: StoreKey<AnalyticsState>;
  snack: StoreKey<SnackState>;
  shelves: StoreKey<ShelvesState>;
}

export enum ActionType {
  // Cart
  ADD_ITEMS_TO_CART = 'ADD_ITEMS_TO_CART',
  REMOVE_ITEMS_FROM_CART = 'REMOVE_ITEMS_FROM_CART',
  SYNC_CART = 'SYNC_CART',

  // Wishlist
  ADD_WISHLIST_DATA = 'ADD_WISHLIST_DATA',

  // Modal
  ADD_MODAL_DATA = 'ADD_MODAL_DATA',
  FLUSH_MODAL_DATA = 'FLUSH_MODAL_DATA',

  // User
  ADD_USER_DATA = 'ADD_USER_DATA',
  FLUSH_USER_DATA = 'FLUSH_USER_DATA',
  SET_SHOULD_TRACK = 'SET_SHOULD_TRACK',

  // UI
  ADD_UI_DATA = 'ADD_UI_DATA',
  FLUSH_UI_DATA = 'FLUSH_UI_DATA',

  // Analytics
  LOAD_ANALYTICS = 'LOAD_ANALYTICS',

  // Snack
  ADD_SNACK_DATA = 'ADD_SNACK_DATA',
  FLUSH_SNACK_DATA = 'FLUSH_SNACK_DATA',

  // Shelves
  ADD_SHELVES_DATA = 'ADD_SHELVES_DATA',
}

export interface Action<Payload extends CartState | WishlistState | ModalState | UserState | UiState | SnackState | AnalyticsState | ShelvesState> {
  type: ActionType;
  payload?: Payload;
  error?: Error;
}

export type ActionDispatch = Action<CartState | WishlistState | ModalState | UserState | UiState | SnackState | AnalyticsState | ShelvesState>;

export interface StoreKey<Data> {
  loading?: boolean;
  error?: Error;
  data: Data;
}

// App is initialized with these values.
// This is exported if we ever need to reset
// any of the state keys back to default.
export const initialState: GlobalState = {
  cart: {
    data: { items: [], loaded: false },
  },
  wishlist: {
    loading: true,
    data: { ids: [] },
  },
  modal: {
    data: { active: false },
  },
  user: {
    data: { email: null, shouldTrack: false },
  },
  ui: {
    data: { routeIsTransitioning: false, adminPageEditing: false },
  },
  analytics: {
    data: { analytics: null },
  },
  snack: {
    data: { active: false },
  },
  shelves: {
    data: { titleShelfIds: [], preference: { pinned_copy: true, shelf: '', shelf_title: '' } },
  }
};

export enum CustomQuery {
  RECENT_VIEWS = 'recent-views',
  TOP_PICKS_FOR_USER = 'top-picks-for-user',
  NEW_LISTINGS_FOR_USER = 'new-listings-for-user',
  NEW_LISTINGS_FROM_USER_FOLLOWS = 'new-listings-from-user-follows',
  WISHLIST = 'wishlist',
  RELATED_TITLES = 'related-titles',
  SHELF = 'shelf',
}
