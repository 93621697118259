import classNames from 'classnames';

import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import styles from '../../styles/components/form/input.module.scss';

export interface InputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: React.ReactNode;
  onChange: any;
  className?: string;
  message?: string;
  error?: string;
}

export default function Input(props: InputProps) {
  return (
    <div style={{ width: '100%' }}>
      <input
        {...props}
        className={classNames(props.className, styles.input, {
          [styles['input-disabled']]: props.disabled,
        })}
      />
      {props.message !== undefined && (
        <label className={styles['message-label']}>{props.message}</label>
      )}
      {props.error !== undefined && (
        <label className={styles['error-label']}>{props.error}</label>
      )}
    </div>
  );
}
