import { Book, IBookGrouper } from 'types';

interface Props {
  books: Book[];
}
export default function groupCartBySeller({ books = [] }: Props) {
  const grouper: IBookGrouper = {};
  books.forEach((book) => {
    const { seller_id } = book;
    if (grouper[seller_id]) {
      grouper[seller_id].push(book);
    } else {
      grouper[seller_id] = [book];
    }
  });
  return Object.entries(grouper).map((entry) => {
    return { seller_id: entry[0], items: entry[1] };
  });
}