import { Book } from 'types';

/** returns nothing if there's no price drop,
 *  or the book amount is not lower than the previous price */
export function priceDropPrice(book: Book) {
  if (!shouldShowPriceDrop(book)) return;

  return book.price_drop?.previous_amount;
}

export function shouldShowPriceDrop(book: Book) {
  if (!book || !book.price_drop || book.price_drop.previous_amount <= book.amount) return false;

  return bookHasPriceDrop(book);
}

export function bookHasPriceDrop(book: Book) {
  if (book.price_drop && book.price_drop?.previous_amount) {
    return true;
  }

  return false;
}

export function getPriceDropPercentage(book: Book) {
  if (!shouldShowPriceDrop(book) || !book.price_drop?.previous_amount) return;

  let dropAmount = book.price_drop.previous_amount - book.amount;
  if (dropAmount && dropAmount > 0) {
    let decimal = dropAmount / book.price_drop.previous_amount;
    if (decimal && decimal > 0) {
      let percentage = `${Math.floor(decimal * 100)}%`;
      return percentage;
    }
  }

  return false;
}

export const groupBooksBySeller = (books: Book[]) => {

  interface BookGrouper {
    [key: string]: Book[];
  }

  const grouper: BookGrouper = {};
  books.forEach((book) => {
    const { seller_id } = book;
    if (grouper[seller_id]) {
      grouper[seller_id].push(book);
    } else {
      grouper[seller_id] = [book];
    }
  });
  return Object.entries(grouper).map((entry) => {
    return { seller_id: entry[0], items: entry[1] };
  });
};

