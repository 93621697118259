
interface Props {
  userAgent: string;
}
export function isBot({ userAgent }: Props) {
  const botPatterns = [
    // General bot identifiers
    'bot', 'crawler', 'spider', 'crawl', 'scan', 'scrape',

    // Major search engine bots
    'googlebot', 'bingbot', 'yandexbot', 'baiduspider', 'yahoo', 'slurp',

    // Other search engines and crawlers
    'duckduckbot', 'sogou', 'exabot', 'facebookexternalhit', 'ia_archiver',
    'alexabot', 'mj12bot', 'dotbot', 'ahrefsbot', 'semrushbot',
    'rogerbot', 'twitterbot', 'linkedinbot', 'applebot',

    // SEO and analytics tools
    'screaming frog', 'sistrix', 'majestic', 'seokicks', 'seomoz',
    'blexbot', 'megaindex', 'spbot', 'backlinkcrawler',

    // Content aggregators
    'feedly', 'flipboard', 'nuzzel', 'theoldreader', 'feedfetcher',

    // Monitoring services
    'pingdom', 'uptimerobot', 'statuscake', 'panopta', 'uptime',

    // Security scanners
    'nessus', 'nmap', 'acunetix', 'qualys', 'sucuri',

    // Archiving services
    'archive.org', 'wayback', 'mementobot', 'heritrix',

    // Miscellaneous bots
    'httrack', 'wget', 'curl', 'python-requests', 'perl',
    'phantomjs', 'headless', 'selenium', 'chrome-lighthouse',

    // Specific known bots
    'adidxbot', 'adsbot-google', 'apis-google', 'mediapartners-google',
    'proximic', 'qwantify', 'barkrowler', 'coccocbot', 'mail.ru_bot',
    'petalbot', 'seznambot', 'grapeshot', 'blexbot', 'cloudsystemnetworks',
    'comscore', 'dataforseo', 'diffbot', 'discordbot', 'facebook',
    'grapeshot', 'headless', 'hubspot', 'infernotions', 'instagram',
    'kraken', 'leiki', 'link-assistant.com', 'ltx71', 'neevabot',
    'netcraft', 'netvibes', 'nimbostratus-bot', 'omgili', 'openai',
    'outbrain', 'pinterest', 'pleskbot', 'pu_in', 'qwantify',
    'rambler', 'researchscan', 'riddler', 'seekport', 'serpstatbot',
    'shopify', 'slack', 'snapchat', 'spotify', 'telegram',
    'tiktok', 'trendsmap', 'whatsapp', 'wordpress', 'yacy',
    'zoominfobot'
  ];

  const lowerCaseUA = userAgent.toLowerCase();
  return botPatterns.some(pattern => lowerCaseUA.includes(pattern));
}