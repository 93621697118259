import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import useAnalytics from '../lib/hooks/use-analytics';

import useRouteIsInTransition from '../lib/hooks/use-route-transition';

import { useGlobalState } from '../state';
import { ActionType } from '../state/types';

import styles from '../styles/components/route-transition-manager.module.scss';

export default function RouteTransitionManager(props) {
  const { state, dispatch } = useGlobalState();
  const router = useRouter();
  const routeIsInTransition = useRouteIsInTransition();
  const { analyticEvents } = useAnalytics();
  const [routeLoading, setRouteLoading] = useState(false);
  const [routeLoaded, setRouteLoaded] = useState(true);
  const [loaderWidth, setLoaderWidth] = useState(0);
  const [loaderOpacity, setLoaderOpacity] = useState(1);

  // Used for page event tracking in analytics
  useEffect(() => {
    const handleRouteChange = (url) => {
      analyticEvents.pageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, analyticEvents]);

  useEffect(() => {
    if (routeIsInTransition && !state.ui.data.routeIsTransitioning) {
      dispatch({
        type: ActionType.ADD_UI_DATA,
        payload: { routeIsTransitioning: true },
      });
      setRouteLoading(true);
      setRouteLoaded(false);
    }
  }, [dispatch, routeIsInTransition, state.ui.data.routeIsTransitioning]);

  useEffect(() => {
    if (!routeIsInTransition && state.ui.data.routeIsTransitioning) {
      dispatch({
        type: ActionType.ADD_UI_DATA,
        payload: { routeIsTransitioning: false },
      });
      setRouteLoaded(true);
      setRouteLoading(false);
    }
  }, [routeIsInTransition, state.ui.data.routeIsTransitioning, dispatch]);

  // If the route is starting to transition, show the loading bar
  useEffect(() => {
    if (routeLoading) {
      setLoaderWidth(20);
      setLoaderOpacity(1);
    }
  }, [routeLoading]);

  // While the loading indictor active, slowly increment the loader width to
  // give the perception that loading is under way
  useEffect(() => {
    if (routeLoading && !routeLoaded) {
      const interval = setInterval(() => {
        setLoaderWidth(loaderWidth + loaderWidth * 0.5);
      }, 350);

      return () => clearInterval(interval);
    }
  }, [routeLoading, routeLoaded, loaderWidth]);

  // Handle a load completing
  useEffect(() => {
    async function finishLoadingIndicator() {
      if (routeLoaded) {
        // Make the loader full width to indicate that the load is complete
        setLoaderWidth(100);
        // Wait 20ms and fade the loading indicator out
        await wait(20);
        setLoaderOpacity(0);
        // Reset the loading indicator back to width 0 for the next load
        await wait(300);
        setLoaderWidth(0);
      }
    }

    finishLoadingIndicator();
  }, [routeLoaded]);

  async function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <span
      className={styles.loader}
      style={{ width: `${loaderWidth}%`, opacity: loaderOpacity }}
    />
  );
}
