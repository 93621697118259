import { useState, useEffect } from 'react';
import { useGlobalState } from '../../state';
import { ActionType } from '../../state/types';
import { ModalState } from '../../state/reducers/modal';
import { useUpdateEffect } from 'react-use';

interface useAuthorizedActionProps {
  actionToCall?: (...args: any[]) => any;
}

export default function useAuthenticatedAction(props: useAuthorizedActionProps) {
  const { actionToCall } = props;
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [didActionExecute, setDidActionExecute] = useState<boolean>(false);
  const [actionArgs, setActionArgs] = useState<any[]>([]);
  const { state, dispatch } = useGlobalState();
  const { user } = state;
  const { data } = user;
  const { isAnonymous } = data;



  const handleAuthAction = (props: ModalState, args: any[] = []) => {
    const { title, message, component, icon } = props;
    setIsClicked(true);
    setDidActionExecute(false);
    setActionArgs(args);
    if (isAnonymous) {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title,
          message,
          component,
          icon,
        },
      });
    } else {
      actionToCall(...args);
      setDidActionExecute(true);
    }
  };

  useUpdateEffect(() => {

    if (!isAnonymous && isClicked && !didActionExecute) {
      actionToCall(...actionArgs);
      setDidActionExecute(true);
    }

  }, [isAnonymous, isClicked, didActionExecute]);


  return { handleAuthAction };
}
