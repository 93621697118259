import React, { ComponentType, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  renderLink: (props: { url: string; text: string; }) => ReactNode;
  renderBold: (props: { text: string }) => ReactNode;
  renderItalic?: (props: { text: string }) => ReactNode;
  TextComponent: ComponentType<any>;
}
export function Markdown({ children, renderLink, renderBold, renderItalic, TextComponent }: Props) {
  if (!children) return null;

  const renderMarkdown = (text: string) => {
    // This regular expression matches the markdown link pattern
    const markdownLinkRegex = /\[([^\[]+)\]\(([^\)]+)\)/g;
    // This regular expression matches anything wrapped in triple backticks
    const codeBlockRegex = /```[\s\S]*?```/g;
    // This regular expression matches anything wrapped in double or quadruple asterisks
    const boldMarkdownRegex = /(\*\*\*\*|\*\*|____|__)(.*?)\1/g;

    // This regular expression matches anything wrapped in single asterisks
    const italicMarkdownRegex = /(\*|_)(.*?)\1/g;

    // This regular expression matches headings (up to 6 levels)
    const headingRegex = /^(#{1,6})\s+(.+)$/gm;

    // Remove code blocks
    const textWithoutCodeBlocks = text.replace(codeBlockRegex, '');

    // Process headings first
    const processedText = textWithoutCodeBlocks.replace(headingRegex, (_, __, content) => {
      return `**${content}**`;
    });

    const parts = processedText.split(markdownLinkRegex);

    return parts.map((part, index) => {
      // Every third element of the array is a link text, and the one following it is the URL
      const isLinkText = (index - 1) % 3 === 0;
      const isLinkUrl = (index - 2) % 3 === 0;
      if (isLinkText) {
        const url = parts[index + 1];
        // Remove asterisks from link text
        const isBold = part.includes('*');
        const cleanText = part.replace(/\*/g, '');
        if (isBold) {
          return renderBold({
            text: renderLink({ url, text: cleanText }) as string
          });
        } else {
          return renderLink({ url, text: cleanText });
        }
      } else if (isLinkUrl) {
        // Skip URL since it's already processed
        return null;
      }
      // Process bold text
      const boldParts = part.split(boldMarkdownRegex);
      return boldParts.map((boldPart, boldIndex) => {
        const isBoldIdentifier = boldPart === '**' || boldPart === '__' || boldPart === '****' || boldPart === '____';
        const isBold = boldParts?.[boldIndex - 1] === '**' || boldParts?.[boldIndex - 1] === '__' || boldParts?.[boldIndex -1] === '****' || boldParts?.[boldIndex -1] === '____'
        if (isBold) {
          return renderBold({ text: boldPart });
        } else if (isBoldIdentifier) {
          return null;
        }

        // Process italic text
        const italicParts = boldPart.split(italicMarkdownRegex);
        const processedBoldAndItalicParts = italicParts.map((italicPart, italicIndex) => {
          const isItalicIdentifier = italicPart === '*' || italicPart === '_';
          const isItalic = italicParts?.[italicIndex - 1] === '*' || italicParts?.[italicIndex - 1] === '_';
          if (isItalic) {
            return renderItalic ? renderItalic({ text: italicPart }): italicPart;
          } else if (isItalicIdentifier) {
            return null;
          }
          return italicPart;
        });

        return processedBoldAndItalicParts;
      });
    });
  };

  return <TextComponent>{renderMarkdown(children as string)}</TextComponent>;
}
