'use client';

import classNames from 'classnames';

import {
  useRef,
  useEffect,
} from 'react';
import useRouteIsInTransition from '../lib/hooks/use-route-transition';

import styles from '../styles/components/dropdown.module.scss';
import { usePathname } from 'next/navigation';

interface DropDownProps {
  open: boolean;
  onHide: () => void;
  children: any;
  size?: 'small' | 'med' | 'large';
  align?: 'left' | 'right';
}

export default function DropDown(props: DropDownProps) {

  // props
  const {
    open,
    onHide,
    children,
    size,
    align = 'right'
  } = props;

  // hooks
  const routeIsTransitioning = useRouteIsInTransition();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();
  const pathnameRef = useRef<string>('');

  // If the route is changing, hold the account dropdown
  useEffect(() => {
    const pathnameIsChanging = (pathnameRef.current !== pathname) && pathname && pathnameRef.current;
    pathnameRef.current = pathname;
    if (pathnameIsChanging || routeIsTransitioning) {
      onHide();
    }
  }, [routeIsTransitioning, onHide, pathname]);

  // Attach the click listeners for when a user clicks outside of the account dropdown
  useEffect(() => {
    // If the user clicks outside of the account dropdown, close it if it's open
    function handleClickOutsideDropdown(event) {
      if (!dropdownRef) return;

      if (open) {
        if (!dropdownRef.current?.contains(event.target)) {
          onHide();
        }
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutsideDropdown);
    }

    return () =>
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
  }, [open, onHide]);

  if (!open) return null;

  return (
    <div
      className={classNames(styles.container, {
        [styles['container-fixed-small']]: size === 'small',
        [styles['container-fixed-med']]: size === 'med',
        [styles['container-fixed-large']]: size === 'large',
        [styles['container-left']]: align === 'left',
      })}
      ref={dropdownRef}
    >
      {children}
    </div>
  );
}