'use client';

import { useEffect } from 'react';

import { realtimeDocumentListener } from '../../clients/firebase/client';
import { useGlobalState } from '../../state';
import { ActionType } from '../../state/types';

export default function ShelvesManager() {
  const { state, dispatch } = useGlobalState();

  useEffect(() => {
    let unsubscribe = null;
    const uid = state?.user?.data?.uid;

    if (uid) {
      unsubscribe = realtimeDocumentListener(`shelves`, uid, (shelvesDoc) => {
        const preference = shelvesDoc?.data()?.preference;
        const titleShelfIds = shelvesDoc?.data()?.title_shelf_ids || [];

        dispatch({
          type: ActionType.ADD_SHELVES_DATA,
          payload: { preference, titleShelfIds },
        });
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, state?.user.data?.uid]);

  return null;
}