export const navUrls = [
  {
    title: 'Bookstores',
    path: '/seller-search',
  },
  {
    title: 'Blog',
    path: '/blog',
  },
  {
    title: 'Collections',
    path: '/collections',
  },
  {
    title: 'Popular Authors',
    path: '/author',
  },
  {
    title: 'Popular Book Series',
    path: '/series',
  },

  {
    title: 'Shelves',
    path: '/shelves',
  },
  {
    title: 'Market Guide',
    path: '/data/low-inventory-high-demand',
  },
  {
    title: 'Pango AI',
    path: '/hey-pango',
  },
];
