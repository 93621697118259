'use client';

import classNames from "classnames";
import { ReactNode, useState } from "react";
import styles from '@/styles/components/tooltip.module.scss';

interface TooltipProps {
  content: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactNode;
}
export default function Tooltip({
  content,
  position,
  children,
}: TooltipProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };


  return (
    <div className={styles["tooltip-container"]} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {isTooltipVisible &&
        <div
          className={classNames(styles['tooltip-content'], styles[`tooltip-content-${position}`])}
        >
          {content}
        </div>
      }
      {children}
    </div>
  );
}