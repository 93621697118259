interface Props {
  fillColor?: string;
}

export default function HardCoverIconSVG({ fillColor = '#A5ABC0' }: Props) {
  return (
    <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 7.25C3.75 4.35156 6.10156 2 9 2H26.5C27.468 2 28.25 2.78203 28.25 3.75V21.25C28.25 22.218 27.468 23 26.5 23V26.5C27.468 26.5 28.25 27.282 28.25 28.25C28.25 29.218 27.468 30 26.5 30H9C6.10156 30 3.75 27.6484 3.75 24.75V7.25ZM9 23C8.03203 23 7.25 23.782 7.25 24.75C7.25 25.718 8.03203 26.5 9 26.5H23V23H9ZM12.9104 16.8537C13.0704 17.0217 13.3024 17.1057 13.6064 17.1057C13.9104 17.1057 14.1384 17.0217 14.2904 16.8537C14.4504 16.6857 14.5304 16.4497 14.5304 16.1457V13.4817H18.4664V16.1457C18.4664 16.4497 18.5464 16.6857 18.7064 16.8537C18.8664 17.0217 19.0944 17.1057 19.3904 17.1057C19.6944 17.1057 19.9264 17.0217 20.0864 16.8537C20.2464 16.6857 20.3264 16.4497 20.3264 16.1457V9.38969C20.3264 9.08569 20.2464 8.84969 20.0864 8.68169C19.9264 8.51369 19.6944 8.42969 19.3904 8.42969C19.0944 8.42969 18.8664 8.51369 18.7064 8.68169C18.5464 8.84969 18.4664 9.08569 18.4664 9.38969V11.9337H14.5304V9.38969C14.5304 9.08569 14.4504 8.84969 14.2904 8.68169C14.1304 8.51369 13.9024 8.42969 13.6064 8.42969C13.3024 8.42969 13.0704 8.51369 12.9104 8.68169C12.7504 8.84969 12.6704 9.08569 12.6704 9.38969V16.1457C12.6704 16.4497 12.7504 16.6857 12.9104 16.8537Z"
        fill={fillColor}
      />
    </svg>
  );
}

