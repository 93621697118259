import { AlgoliaOptions, TypesenseIndex, TypesenseSearchProps } from 'types';
import { parsePossiblyStringifiedAlgoliaOptions } from './saved-search';

export const indexMap: Record<string, TypesenseIndex> = {
  'books': 'books',
  'order_by_newest': 'books',
  'price_low_to_high': 'books',
  'books_score_desc': 'books',
  'price_high_to_low': 'books',
  'books_wishes_desc': 'books',
  'books_feed': 'books',
  'order_by_oldest': 'books',
  'titles': 'titles',
  'titles_popularity_desc': 'titles',
  'titles_copies_sold_desc': 'titles',
  'author_content': 'authors',
  'author_books_sold': 'authors',
  'hashtags': 'hashtags',
  'users': 'users',
  'order_by_signup': 'users',
  'shelves_alltime_popularity': 'shelves'
};

const queryByMap: Record<string, string> = {
  'books': 'title, author, authors.name, hashtags, isbn',
  'books_tags': 'hashtags',
  'titles': 'title, authors.name, hashtags',
  'titles_tags': 'hashtags',
  'authors': 'fullname, title_names',
  'hashtags': 'tag',
  'users': 'name, username',
  'shelves': 'title, description',
  'series': 'seriesTitle,titles.title,authors.name'
};

export const sortByMap: Record<string, string> = {
  'books': '_text_match:desc,score:desc',
  'order_by_newest': 'timestamp:desc,_text_match:desc,score:desc',
  'price_low_to_high': 'amount:asc,_text_match:desc,score:desc',
  'books_score_desc': 'score:desc,_text_match:desc',
  'price_high_to_low': 'amount:desc,_text_match:desc,score:desc',
  'books_wishes_desc': 'wishes:desc,_text_match:desc,score:desc',
  'books_feed': 'feed_timestamp:desc,_text_match:desc,score:desc',
  'order_by_oldest': 'timestamp:asc,_text_match:desc,score:desc',
  'titles': '_text_match(buckets:10):desc,popularity.score:desc,copies:desc',
  'titles_popularity_desc': '_text_match:desc,popularity.score:desc,copies:desc',
  'title_popularity_desc_high': 'popularity.score:desc,_text_match:desc,copies:desc',
  'titles_copies_sold_desc': '_text_match:desc,popularity.copies_sold:desc,copies:desc',
  'author_content': '_text_match:desc,sold:desc,copies:desc',
  'authors': '_text_match:desc,sold:desc,copies:desc',
  'author_books_sold': '_text_match:desc,sold:desc',
  'hashtags': 'count:desc,_text_match:desc',
  'users': '_text_match:desc,seller_score:desc,books_listed:desc',
  'order_by_signup': '_text_match:desc,time_created:desc',
  'books_cart_count': 'cart_count:desc,_text_match:desc,score:desc',
  'shelves_alltime_popularity': 'scores.alltime_weighted_score:desc, _text_match:desc,updated:desc',
  'series': '_text_match:desc,popularity_score:desc'
};

export const SAVED_SEARCH_SORT_BY = 'timestamp:desc,_text_match:desc,score:desc';

export const presetFilters: Record<string, string> = {
  'hashtags': 'count:>100',
  'users': 'time_created:>0'
};

const mapIndex = (index: string): TypesenseSearchProps['index'] => indexMap[index] || index;
export const mapSortBy = (index: string): string => sortByMap[index] || '';
export const mapQueryBy = (index: string): string => queryByMap[index] || '';
export const mapPresetFilter = (filterName: string): string => presetFilters[filterName] || ''; // filter name may be index but doesnt have to be

function parseQuery(query: string): string {
  return query || '';
}

function convertFilter(filter: string) {
  // eslint-disable-next-line prefer-const
  let [key, value] = filter.split(':');

  // Handle negation
  if (value.startsWith('-')) {
    value = value.substring(1);
    return `${key}:!=${value}`;
  }

  // Handle escaping commas and equals
  if (value.includes(',') || value.includes('=')) {
    value = '`' + value + '`';
  }

  return `${key}:${value}`;
}

function convertNumericFilter(filter: string) {
  if (filter.includes(' TO ')) {
    // Handle range cases
    const [key, range] = filter.split(':');
    const [min, max] = range.split(' TO ');
    return `${key}:[${min}..${max}]`;
  } else {
    // Handle comparison cases
    const operatorRegex = /(<=|>=|=|<|>)/;
    const operatorMatch = filter.match(operatorRegex);
    if (!operatorMatch) {
      throw new Error(`Invalid operator in filter: ${filter}`);
    }
    const operator = operatorMatch[0];
    const [key, value] = filter.split(operator);
    return `${key.trim()}:${operator}${value.trim()}`;
  }
}


export function algoliaFiltersToTypesense(filters: string[] | string[][]): string {
  let filter_by = '';

  filters.forEach((filter, index) => {
    // Add " && " between each filter condition, but not at the start
    if (index !== 0) {
      filter_by += ' && ';
    }

    if (Array.isArray(filter)) {
      if (!filter.length) return;
      // Handle OR cases
      // Extract filter name and options, assuming format "filterName:option"
      const orOptions = filter.map(f => {
        const [filterName, option] = convertFilter(f).split(':');
        return `"${option}"`;
      });
      const [filterName] = convertFilter(filter[0]).split(':'); // Get filter name from the first item
      filter_by += `${filterName}:[${orOptions.join(',')}]`;
    } else {
      // Handle AND cases
      filter_by += convertFilter(filter);
    }
  });

  return filter_by;
}

function algoliaNumericToTypesense(filters: string[] | string[][]): string {
  let filter_by = '';

  filters.forEach((filter, index) => {
    // Add " && " between each filter condition, but not at the start
    if (index !== 0) {
      filter_by += ' && ';
    }

    if (Array.isArray(filter)) {
      // Handle OR cases
      const orOptions = filter.map(f => {
        const [filterName, option] = convertNumericFilter(f).split(':');
        return option;
      });
      const [filterName] = convertNumericFilter(filter[0]).split(':'); // Get filter name from the first item
      filter_by += `${filterName}:${orOptions.join(',')}`;
    } else {
      // Handle AND cases
      filter_by += convertNumericFilter(filter);
    }
  });

  return filter_by;
}

export function combineFilters(facetFilters: string[] | string[][], numericFilters: string[] | string[][]): string {
  const facetFilterString = algoliaFiltersToTypesense(facetFilters);
  const numericFilterString = algoliaNumericToTypesense(numericFilters);

  let combinedFilter = '';
  if (facetFilterString) {
    combinedFilter += facetFilterString;
  }
  if (numericFilterString) {
    if (combinedFilter) {
      combinedFilter += ' && ';
    }
    combinedFilter += numericFilterString;
  }

  return combinedFilter;
}

function mapFilters(facetFilters: string | string[] | string[][] = [], numericFilters: string | string[] | string[][] = []): string {
  const facetFiltersArray = Array.isArray(facetFilters) ? facetFilters : [facetFilters];
  const numericFiltersArray = Array.isArray(numericFilters) ? numericFilters : [numericFilters];

  const combinedFilter = combineFilters(facetFiltersArray, numericFiltersArray);

  return combinedFilter;
}

function mapParams(algoliaOptions: AlgoliaOptions, typesenseIndex: string): TypesenseSearchProps['params'] {
  const q = parseQuery(algoliaOptions.options?.query as string);
  const query_by = mapQueryBy(typesenseIndex);
  const filter_by = mapFilters(algoliaOptions.options?.facetFilters as string | string[] | string[][], algoliaOptions.options?.numericFilters as string | string[] | string[][]);
  const sort_by = mapSortBy(algoliaOptions.index);

  return {
    q,
    query_by,
    filter_by,
    sort_by
  };
}

export function algoliaToTypesenseOptions({ algoliaOptions }: { algoliaOptions: AlgoliaOptions; }): TypesenseSearchProps {
  const typesenseIndex = mapIndex(algoliaOptions.index);
  const typesenseParams = mapParams(algoliaOptions, typesenseIndex);

  return {
    index: typesenseIndex,
    params: typesenseParams,
    options: {}
  };
}

export function algoliaOrTypesenseOptionsToTypesenseOptions({ options }: { options: AlgoliaOptions | TypesenseSearchProps; }) {
  if (typeof options === 'string') {
    options = JSON.parse(options);
  }
  if ((options as any)?.params) {
    return options as TypesenseSearchProps;
  }
  return algoliaToTypesenseOptions({ algoliaOptions: parsePossiblyStringifiedAlgoliaOptions({ algoliaOptions: options as AlgoliaOptions }) });
}