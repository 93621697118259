import nookies from 'nookies'

export async function setUserToken(token: string) {
  // Fetch requires an absolute URL
  // https://stackoverflow.com/questions/44342226/next-js-error-only-absolute-urls-are-supported
  if (!global.window) return;

  nookies.set(null, 'tokenFromClient', token)

  await fetch('/api/login', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  });
}

export async function clearUserToken() {
  // Fetch requires an absolute URL
  // https://stackoverflow.com/questions/44342226/next-js-error-only-absolute-urls-are-supported
  if (!global.window) return;

  nookies.destroy(null, 'tokenFromClient')

  await fetch('/api/logout', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
  });
}
