interface Props {
  strokeColor?: string;
}

export default function SearchIcon({ strokeColor = '#40444E' }: Props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16" y="17.6263" width="2.3" height="9" rx="1.15" transform="rotate(-45 16 17.6263)" fill={strokeColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 16.6C13.6451 16.6 16.6 13.6451 16.6 10C16.6 6.35495 13.6451 3.40003 10 3.40003C6.35495 3.40003 3.40002 6.35495 3.40002 10C3.40002 13.6451 6.35495 16.6 10 16.6Z" fill={strokeColor} />
    </svg>
  );
}
