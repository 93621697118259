'use client';

import { useRef, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';

import Logo from '../assets/svg/logo';
import LogoMini from '~/assets/svg/logo-mini';
import HamburgerMenuIcon from '~/assets/svg/icons/hambuger-menu';
import WishlistIcon from '../assets/svg/icons/wishlist';

import PangoUser from '../clients/pango';
import SessionForm from './session-form';
import SearchInput from './search-input';
import Button from './button';

import Categories from './categories';
import { useGlobalState } from '../state';
import { ActionType } from '../state/types';

import styles from '../styles/components/navigation.module.scss';
import ProfileSVG from '../assets/svg/icons/profile';

import Notifications from './navigation-components/notifications';
import CartNav from './navigation-components/cart';

import DropDown from './dropdown';
import { Links } from '~/lib/helpers/link-helper';
import { images } from 'sdk';
import { clearUserToken } from '~/lib/session';
import COLORS from '~/lib/helpers/color-helper';
import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';
import LoadableImage from './loadable-image';
import CloseSVG from '~/assets/svg/icons/close';
import ProfileFilledIcon from '~/assets/svg/icons/profile-filled';
import { TopNavMenu } from './navigation-components/nav-menu';

export function Navigation() {
  const router = useRouter();
  const pathName = usePathname();
  const { state, dispatch } = useGlobalState();
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [searchInputIsFocused, setSearchInputIsFocused] = useState(false);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const accountLinkRef = useRef<HTMLDivElement>(null);
  const menuLinkRef = useRef(null);
  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => state.user.data.username && router.push(Links.myShelves(state.user.data.username).pathname),
  });

  const logos = [
    { className: styles['logo--desktop'], shouldDisplay: () => true },
    { className: styles['logo--mobile'], shouldDisplay: () => !currentPath('checkout') },
    { className: styles['logo--checkout'], shouldDisplay: () => currentPath('checkout') },
    { className: styles['logo--checkout-mini'], shouldDisplay: () => currentPath('checkout'), LogoComponent: LogoMini },
    { className: styles['logo--mobile-mini'], shouldDisplay: () => true, LogoComponent: LogoMini },
  ];

  function userIsLoggedIn() {
    return Boolean(state.user.data.uid) && !state.user.data.isAnonymous;
  }

  function renderLogo() {
    return (
      <Link href='/' passHref aria-label='Go to homepage' className={classNames(styles.logo)}>
        {logos.map(
          // Rendering the appropriate logo if the shouldDisplay function returns true
          ({ className, shouldDisplay, LogoComponent = Logo }, index) =>
            shouldDisplay() && (
              <div className={className} key={index}>
                <LogoComponent />
              </div>
            )
        )}
      </Link>
    );
  }

  async function handleLogout() {
    setShowAccountDropdown(false);

    // TODO - need a more fail safe way to reset the token once a user signs out
    try {
      // Flush the user data
      dispatch({ type: ActionType.FLUSH_USER_DATA });
      // Logout the user
      await PangoUser.logout(() => clearUserToken());
      // Reload the page
      router.push('/');
    } catch (e: any) {
      throw new Error(e);
    }
  }

  function handleAccountLinkClick(e) {
    if (userIsLoggedIn()) {
      return setShowAccountDropdown(!showAccountDropdown);
    }

    return dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Welcome back!',
        component: <SessionForm />,
      },
    });
  }

  function accountLink(label?: boolean) {
    return (
      <div
        className={classNames(styles['link-items'], {
          [styles['link-items--selected']]: showAccountDropdown,
        })}
        ref={accountLinkRef}
        onClick={(e) => handleAccountLinkClick(e)}
      >
        <div>{profilePhoto()}</div>
        {label && <div>Account</div>}
      </div>
    );
  }

  function renderMenuIcon() {
    return (
      <div
        className={styles['hamburger-icon']}
        ref={menuLinkRef}
        onClick={() => setShowSideMenu((prevState) => !prevState)}
      >
        <HamburgerMenuIcon />
      </div>
    );
  }

  function profilePhoto() {
    const photoURL = images(state.user.data?.photo_path).user.profileImage;
    return !!photoURL ? (
      <div className={styles['profile-photo']}>
        <LoadableImage alt='Profile Image' src={photoURL} width={250} height={250} />
      </div>
    ) : (
      <>{currentPath('profile') ? <ProfileFilledIcon /> : <ProfileSVG />}</>
    );
  }

  function accountDropdown() {
    if (!showAccountDropdown) return null;

    return (
      <DropDown open={showAccountDropdown} onHide={() => setShowAccountDropdown(false)}>
        <section className={styles['profile-dropdown']} ref={dropdownContainerRef}>
          <div className={styles['profile-dropdown-user']}>
            <Link
              href={state.user.data ? Links.bookstore(state.user.data).show : ''}
              passHref
              className={styles['profile-dropdown-user-photo']}
            >
              {profilePhoto()}
              <h2>{state.user.data?.name}</h2>
            </Link>
          </div>
          <nav>
            <Link href='/account/settings' passHref>
              Account
            </Link>
            <a onClick={handleLogout}>Logout</a>
          </nav>
        </section>
      </DropDown>
    );
  }

  function renderSearchInput(containerStyle: string) {
    return (
      <div
        className={classNames(styles[containerStyle], {
          [`${styles['search-container-desktop--hide']}, ${styles['search-container-mobile--hide']}`]:
            currentPath('checkout'),
        })}
      >
        <div className={styles['search-input-and-cancel-icon']}>
          <SearchInput searchInputIsFocused={searchInputIsFocused} setSearchInputIsFocused={setSearchInputIsFocused} />
          <div
            className={classNames(styles['cancel-icon'], {
              [styles['cancel-icon-search-focused']]: searchInputIsFocused,
            })}
          >
            Cancel
          </div>
        </div>
      </div>
    );
  }

  function mobileTopNavigation() {
    return (
      <>
        <div
          className={classNames({
            [styles['links-mobile']]: true,
            [styles['nav-mobile']]: true,
          })}
        >
          {!userIsLoggedIn() && (
            <div
              id={styles['login-button']}
              onClick={() =>
                dispatch({
                  type: ActionType.ADD_MODAL_DATA,
                  payload: {
                    title: 'Welcome back!',
                    component: <SessionForm />,
                  },
                })
              }
            >
              Log in
            </div>
          )}
          {userIsLoggedIn() && (
            <>
              <div
                onClick={handleShelves}
                className={classNames(styles['link-items-mobile'], {
                  [styles['link-items-mobile--selected']]: currentPath('shelves'),
                })}
              >
                <WishlistIcon strokeColor={COLORS.copy} />
              </div>
              <div className={classNames(styles['link-items-mobile'])} onClick={handleMobileNotifications}>
                <Notifications />
              </div>
              <div
                onClick={() => {
                  router.push(Links.account.index.pathname);
                }}
                className={classNames(styles['link-items-mobile'], {
                  [styles['link-items-mobile--selected']]: currentPath('profile'),
                })}
              >
                {profilePhoto()}
              </div>
            </>
          )}
          <div>
            <CartNav isMobile={true} />
          </div>
          <div className={classNames(styles['link-items-mobile'], styles['topNavMenuActive'])}>
            {renderMenuIcon()}
            {TopNavMenu({
              currentPath,
              showSideMenu,
              showCategories,
              setShowSideMenu,
              setShowCategories,
              handleLogout,
            })}
          </div>
        </div>
      </>
    );
  }

  const handleMobileNotifications = async (e) => {
    e.preventDefault();
    router.push(Links.account.notifications.pathname);
  };

  const handleShelves = async (e: any) => {
    e.preventDefault();

    handleAuthAction({
      title: 'Login to view your Shelves',
      message: 'You need an account to manage your Shelves, please sign in with one of the methods below.',
      component: <SessionForm />,
    });
  };

  function currentPath(path: 'checkout' | 'cart' | 'wishlist' | 'profile' | 'home' | 'shelves', exact?: boolean) {
    let pathMatch;
    switch (path) {
      case 'wishlist':
        pathMatch = '/account/wishlist';
        break;
      case 'shelves':
        pathMatch = Links.myShelves(state.user.data?.username).pathname;
        break;
      case 'checkout':
        pathMatch = '/checkout';
        break;
      case 'profile':
        pathMatch = '/account/settings/profile';
        break;
      case 'cart':
        pathMatch = '/cart';
        break;
      case 'home':
        pathMatch = '/';
        break;
    }

    if (exact) return pathName === pathMatch;
    return pathName.startsWith(pathMatch);
  }

  return (
    <>
      <nav
        className={classNames(styles.container, {
          [styles['container--nopadbottom']]: !(currentPath('checkout') || currentPath('cart')),
        })}
      >
        <div className={styles.content}>
          {renderLogo()}
          {renderSearchInput('search-container-desktop')}
          <div
            className={classNames(styles['secure-checkout'], {
              [styles['secure-checkout--show']]: currentPath('checkout'),
            })}
          >
            Secure Checkout
          </div>

          <div className={classNames(styles.links, styles['nav-desktop'])}>
            <Link href='/sell' passHref className={classNames(styles['link-items'], styles['link-items-button'])}>
              <Button size='small' text='Sell Your Books' />
            </Link>
            {!userIsLoggedIn() && (
              <Link href='/about' className={classNames(styles['link-items'], styles['link-items-text'])}>
                About
              </Link>
            )}
            <Link href={Links.faq} passHref className={classNames(styles['link-items'], styles['link-items-text'])}>
              FAQ
            </Link>
            <li
              onClick={handleShelves}
              className={classNames(styles['link-items'], {
                [styles['link-items--selected']]: currentPath('shelves'),
              })}
            >
              <WishlistIcon strokeColor={COLORS.copyDark} />
            </li>
            {userIsLoggedIn() && <Notifications />}
            <CartNav />
            {userIsLoggedIn() ? (
              <>
                {accountLink()}
                {accountDropdown()}
              </>
            ) : (
              <a
                className={classNames(styles['link-items'], styles['link-items-text'])}
                style={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  dispatch({
                    type: ActionType.ADD_MODAL_DATA,
                    payload: {
                      title: 'Welcome back!',
                      component: <SessionForm />,
                    },
                  })
                }
              >
                Log in
              </a>
            )}
          </div>
          <div className={classNames({ [styles['top-nav-checkout']]: currentPath('checkout') })}>
            {mobileTopNavigation()}
          </div>
        </div>
        <div>{renderSearchInput('search-container-mobile')}</div>
        <div className={styles['show-categories']}>
          {!currentPath('checkout') && !currentPath('cart') && <Categories />}
        </div>
      </nav>
    </>
  );
}
