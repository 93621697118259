'use client';

import classNames from 'classnames';
import CartSVG from '~/assets/svg/icons/cart';
import DollarSignSVG from '../../assets/svg/icons/dollar-sign';
import styles from '../../styles/components/tiles/loading.module.scss';

export default function LoadingTile({
  type,
}: {
  type: 'book' | 'seller' | 'content';
}) {
  if (type === 'content') {
    return (
      <div className={classNames(styles['tile--content'])}>
        <div className={styles['image-container']}>
          <div />
        </div>
        <div className={styles['content-container']}>
          <div />
          <div />
        </div>
      </div>
    );
  }

  if (type === 'seller') {
    return (
      <div className={classNames(styles['tile--seller'])}>
        <div className={styles['image-container']}>
          <div />
          <div />
          <div />
        </div>
        <div className={styles['content-container']}>
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.tile}>
      <div className={styles['image-container']}>
        <div />
      </div>
      <div className={styles['content-container']}>
        <div className={styles.author} />
        <div className={styles.title}>
          <div />
          <div />
        </div>
        <div className={styles.info}>
          <div className={styles.price}>
            <DollarSignSVG fillColor={'var(--borderLight)'} />
            <div />
          </div>
          <span className={styles.like}>
            <CartSVG strokeColor={'var(--borderLight)'} />
          </span>
        </div>
      </div>
    </div>
  );
}
