'use client';

import { UserProfile } from 'types';
import Link from 'next/link';
import Image from 'next/image';

import { Links } from '../lib/helpers/link-helper';
import { images } from 'sdk';
import COLORS from '~/lib/helpers/color-helper';

import ProfileSVG from '~/assets/svg/icons/profile';

import styles from '../styles/components/user-profile-image.module.scss';

export default function UserProfileImage({
  user,
  link = true,
}: {
  user: UserProfile;
  link: boolean;
}) {
  if (!user) {
    return <div className={styles.container} />;
  }

  function renderImage() {
    const image = user.photo_path ? (
      <Image
        src={images(user.photo_path)?.user?.profileImage}
        alt={user.name}
        fill
        unoptimized
      />
    ) : (
      <ProfileSVG strokeColor={COLORS.primary} />
    );

    if (link) {
      return (
        <Link href={Links.bookstore(user).show} passHref>
          {image}
        </Link>
      );
    }

    return image;
  }

  return (
    <div className={styles.container}>
      {renderImage()}
    </div>
  );
}
