'use client';

import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import DropDown from './dropdown';
import { categories } from '~/assets/data/categories';
import { navUrls } from '~/assets/data/nav-urls';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';

import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';

import styles from '../styles/components/categories.module.scss';

function Categories() {
  const [visibleTextArray, setVisibleTextArray] = useState([]);
  const [dropdownArray, setDropdownArray] = useState([]);
  const [visibleNavUrls, setVisibleNavUrls] = useState([]);
  const [exploreNavUrls, setExploreNavUrls] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const { dispatch } = useGlobalState();
  const containerRef = useRef(null);

  const MOBILE_BREAKPOINT = 992;

  useEffect(() => {
    function handleResize() {
      // Get the container width
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const mobile = containerWidth < MOBILE_BREAKPOINT;
        setIsMobile(mobile);

        // Slice the array to show limited links
        setVisibleTextArray(categories.slice(0, 6));
        setDropdownArray(categories.slice(6));
        setVisibleNavUrls(navUrls.slice(0, 2));
        setExploreNavUrls(navUrls.slice(2));
      }
    }
    // Call handleResize initially and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [categories, navUrls]);

  const DropdownMenu = ({ title, items, isMobile, dispatch }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
      if (!isMobile) {
        setIsOpen(true);
      }
    };

    const handleMouseLeave = () => {
      if (!isMobile) {
        setIsOpen(null);
      }
    };

    const handleClick = () => {
      if (isMobile) {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            title,
            component: (
              <div className={classNames(styles['dropdown-container'])}>
                {items.map((item) => (
                  <Link key={item.title} href={item.path} legacyBehavior>
                    <div
                      onClick={() => {
                        if (isMobile) dispatch({ type: ActionType.FLUSH_MODAL_DATA });
                      }}
                      className={classNames(styles['dropdown-container-item'])}
                    >
                      <a
                        className={classNames(
                          styles['link-items'],
                          styles['link-items-dropdown'],
                          styles['link-items-text']
                        )}
                      >
                        {item.title}
                      </a>
                    </div>
                  </Link>
                ))}
              </div>
            ),
          },
        });
      }
    };
    return (
      <div
        className={classNames(styles['more-container'])}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={classNames(styles['more-link'])}>
          <h3
            className={classNames(styles['link-items'], styles['link-items-text'], {
              [styles['link-items-active']]: isOpen,
            })}
          >
            {title}
          </h3>
          <div className={styles.arrow}>
            {isOpen ? <ArrowUpBtnSVG /> : <ArrowDownBtnSVG strokeColor={COLORS.copy} />}
          </div>
        </div>
        {!isMobile && (
          <div className={classNames(styles['dropdown-content'], { [styles.open]: isOpen })}>
            <DropDown open={isOpen} onHide={() => setIsOpen(null)}>
              <div className={classNames(styles['dropdown-container'])}>
                {items.map((item) => (
                  <Link key={item.title} href={item.path} legacyBehavior>
                    <div className={classNames(styles['dropdown-container-item'])} onClick={() => setIsOpen(false)}>
                      <a
                        className={classNames(
                          styles['link-items'],
                          styles['link-items-dropdown'],
                          styles['link-items-text']
                        )}
                      >
                        {item.title}
                      </a>
                    </div>
                  </Link>
                ))}
              </div>
            </DropDown>
          </div>
        )}
      </div>
    );
  };

  return (
    <div ref={containerRef} id='container' className={styles.container}>
      {visibleTextArray.map((category) => {
        return (
          <Link key={category.title} href={category.path} legacyBehavior>
            <div className={classNames(styles['visible-container'])}>
              <a className={classNames(styles['link-items'], styles['link-items-text'])}>{category.title}</a>
            </div>
          </Link>
        );
      })}

      {!!dropdownArray.length && (
        <DropdownMenu title='More Categories' items={dropdownArray} dispatch={dispatch} isMobile={isMobile} />
      )}

      <div className={styles.divider} style={{ margin: isMobile ? '0 1rem' : 'none' }} />

      {visibleNavUrls.map((navUrl) => (
        <Link key={navUrl.title} href={navUrl.path} legacyBehavior>
          <div className={classNames(styles['visible-container'])}>
            <a className={classNames(styles['link-items'], styles['link-items-text'])}>{navUrl.title}</a>
          </div>
        </Link>
      ))}

      {!!exploreNavUrls.length && (
        <DropdownMenu title='Explore' items={exploreNavUrls} dispatch={dispatch} isMobile={isMobile} />
      )}
    </div>
  );
}

export default Categories;
