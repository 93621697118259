import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
  size?: number;
  direction?: 'right' | 'left' | 'up' | 'down';
}

export default function ChevronIcon({ fillColor = COLORS.copyDark, size = 24, direction = 'right' }: Props) {
  const rotate = direction === 'up' ? '-90deg' : direction === 'down' ? '90deg' : direction === 'left' ? '180deg' : '0deg';

  return (
    <svg height={size} width={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${rotate})` }}>
      <path
        d='M9 18L15 12L9 6'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
