import { useCallback, useEffect, useState } from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useGlobalState } from '~/state';

import { ActiveConversation, HydratedMessage, Message } from '~/clients/types';
import { Book, Order, UserProfile } from 'types';

import {
  backendFunction,
  fetchCollectionDocByPath,
  fetchCollectionDocsByQuery,
  realtimeCollectionListener,
  sendMessageBatch,
  updateFirestoreDoc,
} from '~/clients/firebase/client';
import {
  DocumentSnapshot,
  limit,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { Links } from '../helpers/link-helper';
import { stringify } from 'qs';

// number of messages to fetch in a batch
const BATCH_SIZE = 5;
const MESSAGE_PATH = '/account/messages';

interface MsgProps {
  text: string;
  book?: Book;
  order?: Order;
  sellerToBuyer?: boolean;
}
export default function useMessages() {
  // exported state
  const [activeConversation, setActiveConversation] =
    useState<ActiveConversation>(null);
  const [recentMessages, setRecentMessages] = useState<HydratedMessage[]>([]);

  // local state
  const [msgsCursor, setMsgsCursor] = useState<DocumentSnapshot>(null);
  const [recentMsgsCursor, setRecentMsgsCursor] =
    useState<DocumentSnapshot>(null);

  const router = useRouter(); // for getting active conversation from the url
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const { state } = useGlobalState();
  const isAnonymousUser = state.user?.data?.isAnonymous;

  const myUid = state.user?.data?.uid;
  const unreadMessageCount = recentMessages?.length
    ? recentMessages.reduce(
      (count, currMsg) => (!currMsg.read ? count + 1 : count),
      0
    )
    : 0;

  // callback for hydrating recent messages with user data
  const hydrateMessage = useCallback(
    (msg: Message) => {
      return new Promise<HydratedMessage>(async (res, rej) => {
        try {
          const user = await fetchCollectionDocByPath<UserProfile>(
            msg.fromId === myUid ? msg.toId : msg.fromId,
            'users',
            false
          );
          res({ ...msg, user });
        } catch (e) {
          rej(e);
        }
      });
    },
    [myUid]
  );

  // set a listener on recent messages
  useEffect(() => {
    let unsubscribe = null;
    if (myUid) {
      unsubscribe = realtimeCollectionListener(
        `users/${myUid}/recent_messages`,
        [orderBy('timestamp', 'desc'), limit(BATCH_SIZE)],
        async (snapshot) => {
          if (snapshot.empty) {
            setRecentMessages([]);
            setRecentMsgsCursor(null);
            return;
          }
          const promises: Promise<HydratedMessage>[] = [];
          for (const doc of snapshot.docs) {
            const msg = { ...doc.data(), id: doc.id } as Message;
            promises.push(hydrateMessage(msg));
          }
          let messages = await Promise.all(promises);
          let cursor = null;
          if (snapshot?.docs.length >= BATCH_SIZE) {
            cursor = snapshot.docs[snapshot.docs.length - 1];
          }
          // removed any matching messages from recentMessages
          setRecentMessages(messages);
          setRecentMsgsCursor(cursor);
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [myUid, hydrateMessage]);

  // a uid can be passed to the url
  // 1. Check to see if a chatId exists with this user
  // 2. Create a chatId if it doesn't
  // 3. push the chatId to the url
  useEffect(() => {
    const uid = searchParams.get('uid');
    const bookId = searchParams.get('bookId');
    const orderId = searchParams.get('orderId');
    if (pathname !== MESSAGE_PATH) return;
    if (uid && typeof uid === 'string' && !searchParams.get('cid') && myUid) {
      const getChatId = async () => {
        const chatIdGuess = `${myUid}:${uid}`;
        const msgs = await fetchCollectionDocsByQuery({
          path: `messages/${chatIdGuess}/messages`,
          constraints: [limit(1)],
          parse: false
        });
        let chatId = null;
        if (msgs?.length) {
          chatId = chatIdGuess;
        } else {
          chatId = `${uid}:${myUid}`;
        }
        const route = Links.messages.general({
          type: 'chatId',
          id: chatId,
          bookId: typeof bookId === 'string' && bookId,
          orderId: typeof orderId === 'string' && orderId,
        });
        const url = route.pathname + '?' + stringify(route.query);
        router.replace(url);
      };
      getChatId();
    }
  }, [pathname, router, myUid, searchParams]);

  // fetch user data
  useEffect(() => {
    let mounted = true;
    const chatId = searchParams.get('cid');
    if (pathname !== MESSAGE_PATH) return;
    if (chatId && typeof chatId === 'string' && myUid) {
      // separate the chatId into the user_ids
      const user_ids = chatId.trim().split(':');
      // filter out my uid
      const filtered = user_ids.filter((id) => id !== myUid);
      if (!filtered.length) return;
      const their_id = filtered[0];
      if (
        !activeConversation?.user ||
        activeConversation?.user.id !== their_id
      ) {
        // see if that user has been fetched
        const user = recentMessages.find(
          (msg) => msg.fromId === their_id || msg.toId === their_id
        )?.user;
        if (user) {
          if (mounted) {
            setActiveConversation((currentState) => {
              return {
                ...currentState,
                user,
              };
            });
          }
        } else {
          // fetch that users data from db
          fetchCollectionDocByPath<UserProfile>(their_id, 'users', false).then(
            (_user) => {
              if (_user?.name && mounted) {
                setActiveConversation((currentState) => {
                  return {
                    ...currentState,
                    user: _user,
                  };
                });
              }
            }
          );
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    searchParams,
    pathname,
    myUid,
    activeConversation,
    recentMessages,
  ]);

  // set listener on messages
  useEffect(() => {
    let unsubscribe = null;
    const chatId = searchParams.get('cid');
    if (pathname !== MESSAGE_PATH) return;
    if (chatId) {
      // set a listener on the messages
      unsubscribe = realtimeCollectionListener(
        `messages/${chatId}/messages`,
        [orderBy('timestamp', 'desc'), limit(BATCH_SIZE)],
        (snapshot) => {
          const newMessages: Message[] = [];
          for (const doc of snapshot.docs) {
            const msg = { ...doc.data(), id: doc.id } as Message;
            newMessages.push(msg);
          }
          setActiveConversation((currentState) => {
            return {
              ...currentState,
              chatId: chatId as string,
              messages: newMessages,
            };
          });
          if (newMessages.length >= BATCH_SIZE) {
            setMsgsCursor(snapshot.docs[snapshot.docs.length - 1]);
          } else {
            setMsgsCursor(null);
          }
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [searchParams, pathname]);

  const handleReadMessage = useCallback(
    async (msg: HydratedMessage) => {
      try {
        await updateFirestoreDoc(`users/${myUid}/recent_messages`, msg.id, {
          read: true,
        });
      } catch (e: any) {
        // err
      }
    },
    [myUid]
  );


  const sendMessage = useCallback(
    async (props: MsgProps) => {
      try {
        const { text, book, order, sellerToBuyer } = props;

        //if the message isn't coming from the messenger page then build the chatId
        let chatId = '';
        let sellerId = '';
        let toId = activeConversation?.user.id; //get to id from messenger page
        if (book || order) {
          sellerId =
            book !== undefined ? book.seller_id : order.book_data.seller_id;
          toId = sellerToBuyer ? order.buyer_id : sellerId;
          const getChatId = async () => {
            const chatIdGuess = `${myUid}:${toId}`;
            const msgs = await fetchCollectionDocsByQuery({
              path: `messages/${chatIdGuess}/messages`,
              constraints: [limit(1)],
              parse: false
            });
            let chatId = null;
            if (msgs?.length) {
              chatId = chatIdGuess;
            } else {
              chatId = `${toId}:${myUid}`;
            }
            return chatId;
          };
          chatId = await getChatId();
        } else if (
          !activeConversation?.chatId?.includes(activeConversation?.user.id) ||
          !myUid
        ) {
          // quick sanity check
          return; // guarantees sync
        }
        if (!text || text.trim() === '') {
          return; // don't send empty message
        }

        if (sellerToBuyer && !order) {
          return; // don't send a message to a buyer if there is no order
        }

        await sendMessageBatch({
          message: text,
          chatId: activeConversation?.chatId || chatId,
          fromId: myUid,
          toId,
          ...(book && { book }),
          ...(order && { order }),
        });
      } catch (e) {
        //err
      }
    },
    [activeConversation, myUid]
  );

  const sendMessageAnonymous = async (props: MsgProps) => {
    const { text, order } = props;
    if (!order || !text || text.trim() === '') return;

    return backendFunction<void, { order_id: string; message: string; }>(
      'orders-sendMsgFromGuestBuyer',
      {
        order_id: props.order.order_id,
        message: text,
      }
    );
  };

  const fetchMoreMsgs = useCallback(async () => {
    if (Boolean(msgsCursor) && activeConversation?.chatId) {
      const _moreMsgs = await fetchCollectionDocsByQuery<Message>({
        path: `messages/${activeConversation.chatId}/messages`,
        constraints: [
          orderBy('timestamp', 'desc'),
          startAfter(msgsCursor),
          limit(BATCH_SIZE),
        ],
        parse: false
      });
      if (_moreMsgs) {
        setActiveConversation((currentState) => ({
          ...currentState,
          messages: [...currentState.messages, ..._moreMsgs],
        }));
      }
      if (_moreMsgs?.length >= BATCH_SIZE) {
        setMsgsCursor(_moreMsgs[_moreMsgs.length - 1].docSnap);
      } else {
        setMsgsCursor(null);
      }
    }
  }, [activeConversation, msgsCursor, setActiveConversation]);

  const fetchMoreRecentMsgs = useCallback(async () => {
    if (Boolean(recentMsgsCursor)) {
      const _moreRecentMsgs = await fetchCollectionDocsByQuery<Message>({
        path: `users/${myUid}/recent_messages`,
        constraints: [
          orderBy('timestamp', 'desc'),
          startAfter(recentMsgsCursor),
          limit(BATCH_SIZE),
        ],
        parse: false,
        returnDocSnap: true,
      });
      const promises: Promise<HydratedMessage>[] = [];
      let messages: HydratedMessage[] = [];
      if (_moreRecentMsgs) {
        for (const msg of _moreRecentMsgs) {
          promises.push(hydrateMessage(msg));
        }
        messages = await Promise.all(promises);
      }
      let cursor = null;
      if (_moreRecentMsgs?.length >= BATCH_SIZE) {
        cursor = _moreRecentMsgs[_moreRecentMsgs.length - 1].docSnap;
      }
      setRecentMessages([...recentMessages, ...messages]);
      setRecentMsgsCursor(cursor);
    }
  }, [recentMessages, recentMsgsCursor, myUid, hydrateMessage]);

  return {
    recentMessages,
    activeConversation,
    moreMsgs: Boolean(msgsCursor),
    moreRecentMsgs: Boolean(recentMsgsCursor),
    unreadMessageCount,
    handleReadMessage,
    sendMessage: isAnonymousUser ? sendMessageAnonymous : sendMessage,
    fetchMoreRecentMsgs,
    fetchMoreMsgs,
  };
}
