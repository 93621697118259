import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Layout from '~/layouts';
import RouteTransitionManager from '~/components/route-transition-manager';
import '~/styles/global/index.global.scss';
import Providers from '../components/providers';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & any;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Head>
        {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Head>
      <Providers>
        <RouteTransitionManager key='route-manager' />
        <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
      </Providers>
    </>
  );
}
