/**
 * These are the color value that we use if we have to hardcode a color into JS
 * These values are CSS Variables that are exposted globally here (src/styles/global/index.global.scss)
 *
 * Those CSS variables are defined in the SASS config file here (src/styles/config/_colors.scss)
 */
export const COLORS = {
  primary: 'var(--primary)',
  foreground: 'var(--foreground)',
  background: 'var(--background)',
  copy: 'var(--copy)',
  copyLight: 'var(--copyLight)',
  copyDark: 'var(--copyDark)',
  grey: 'var(--grey)',
  error: 'var(--error)',
  lightGrey: 'var(--lightGrey)',
  light: 'var(--light)',
  borderLight: 'var(--borderLight)',
  yellow: 'var(--yellow)',
  green: 'var(--green)',
  lighterGrey: 'var(--lighterGrey)',
};

export default COLORS;
