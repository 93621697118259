import Image from 'next/image';
import classNames from 'classnames';

import type { Order } from 'types';
import styles from '~/styles/pages/settings.module.scss';
import { images } from 'sdk';

interface CondensedImageProps {
  order: Order;
  size?: 'sm' | 'md' | 'lg';
}

export default function CondensedImage(props: CondensedImageProps) {
  const {
    order: { book_data, books },
    size,
  } = props;

  const imageCount = books?.length ? books.length : 1;

  if (imageCount >= 4) {
    return (
      <div className={styles['stacked-image-grid']}>
        <div
          className={classNames(
            styles['stacked-image'],
            styles['stacked-image--tl'],
            { [styles['stacked-image--sm']]: size === 'sm' }
          )}
        >
          {books[0].thumb_url || books[0].photo_url ? (
            <Image
              alt="image"
              src={books[0].photo_path ? images(books[0].photo_path).book.thumb : books[0].thumb_url || books[0].photo_url}
              unoptimized
              fill
            />
          ) : null}
        </div>
        <div
          className={classNames(
            styles['stacked-image'],
            styles['stacked-image--tr'],
            { [styles['stacked-image--sm']]: size === 'sm' }
          )}
        >
          {books[1].thumb_url || books[1].photo_url ? (
            <Image
              alt="image"
              src={books[1].photo_path ? images(books[1].photo_path).book.thumb : books[1].thumb_url || books[1].photo_url}
              unoptimized
              fill
            />
          ) : null}
        </div>
        <div
          className={classNames(
            styles['stacked-image'],
            styles['stacked-image--bl'],
            { [styles['stacked-image--sm']]: size === 'sm' }
          )}
        >
          {books[2].thumb_url || books[2].photo_url ? (
            <Image
              alt="image"
              src={books[2].photo_path ? images(books[2].photo_path).book.thumb : books[2].thumb_url || books[2].photo_url}
              unoptimized
              fill
            />
          ) : null}
        </div>
        <div
          className={classNames(
            styles['stacked-image'],
            styles['stacked-image--br'],
            { [styles['stacked-image--sm']]: size === 'sm' }
          )}
        >
          {books[3].thumb_url || books[3].photo_url ? (
            <Image
              alt="image"
              src={books[3].photo_path ? images(books[3].photo_path).book.thumb : books[3].thumb_url || books[3].photo_url}
              unoptimized
              fill
            />
          ) : null}
        </div>
      </div>
    );
  }

  const imageUrl = books?.length
    ? books[0].photo_path ? images(books[0].photo_path).book.thumb
      : books[0]?.thumb_url
        ? books[0].thumb_url
        : books[0].photo_url
    : book_data.photo_path ? images(book_data.photo_path).book.thumb
      : book_data?.thumb_url
        ? book_data.thumb_url
        : book_data.photo_url;

  return (
    <div
      className={classNames(styles['single-image'], {
        [styles['single-image--sm']]: size === 'sm',
      })}
    >
      {imageUrl && <Image alt="image" src={imageUrl} unoptimized fill />}
      {imageCount > 1 ? (
        <div className={styles['more-text']}>+{imageCount - 1} more</div>
      ) : null}
    </div>
  );
}
