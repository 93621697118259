
export default function AppleFill() {

  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2471_38336)">
        <path d="M17.4958 12.0607C17.4863 10.3458 18.2731 9.05137 19.8657 8.0981C18.9746 6.84109 17.6285 6.1495 15.8511 6.01399C14.1685 5.88315 12.3294 6.98128 11.6564 6.98128C10.9454 6.98128 9.31496 6.06072 8.03523 6.06072C5.39045 6.10277 2.57977 8.14016 2.57977 12.285C2.57977 13.5093 2.80728 14.7741 3.2623 16.0794C3.86899 17.7944 6.05875 22 8.34331 21.9299C9.53773 21.9018 10.3814 21.0934 11.936 21.0934C13.4433 21.0934 14.2253 21.9299 15.5572 21.9299C17.8607 21.8972 19.842 18.0747 20.4202 16.3551C17.3299 14.9205 17.4958 12.1495 17.4958 12.0607ZM14.8131 4.38782C16.107 2.8738 15.9885 1.4953 15.9506 0.999969C14.8083 1.06539 13.4859 1.76632 12.7323 2.63081C11.9029 3.55604 11.4147 4.7009 11.5189 5.99062C12.756 6.08408 13.8841 5.45791 14.8131 4.38782Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2471_38336">
          <rect width="19" height="21" fill="white" transform="translate(2 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}