import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}

export default function YoutubeIconSVG({ fillColor = COLORS.foreground }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M21.654 7.252c-.244-1.068-1.114-1.856-2.162-1.974-2.48-.278-4.99-.28-7.489-.278-2.499-.001-5.01 0-7.49.278-1.046.118-1.916.906-2.16 1.974C2.002 8.773 2 10.434 2 12c0 1.566 0 3.227.348 4.748.245 1.068 1.115 1.856 2.162 1.974C6.99 19 9.5 19.002 12 19c2.499.001 5.009 0 7.488-.278 1.047-.118 1.917-.906 2.162-1.974C22 15.226 22 13.566 22 12c0-1.566.003-3.227-.346-4.748zM9.42 15.118V8.475l6.358 3.332-6.358 3.31z"
      ></path>
    </svg>
  );
}
