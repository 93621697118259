import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}

export default function QualitySVG({ fillColor = COLORS.yellow }: Props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 11.25C11.7959 11.25 14.0625 9.06739 14.0625 6.375C14.0625 3.68261 11.7959 1.5 9 1.5C6.20406 1.5 3.9375 3.68261 3.9375 6.375C3.9375 9.06739 6.20406 11.25 9 11.25Z"
        fill={fillColor}
      />
      <path
        d="M11.8425 11.7068C12.09 11.5793 12.375 11.7668 12.375 12.0443V15.6818C12.375 16.3568 11.9025 16.6868 11.3175 16.4093L9.3075 15.4568C9.135 15.3818 8.865 15.3818 8.6925 15.4568L6.6825 16.4093C6.0975 16.6793 5.625 16.3493 5.625 15.6743L5.64 12.0443C5.64 11.7668 5.9325 11.5868 6.1725 11.7068C7.02 12.1343 7.98 12.3743 9 12.3743C10.02 12.3743 10.9875 12.1343 11.8425 11.7068Z"
        fill={fillColor}
      />
    </svg>
  );
}
