import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}
export default function FastShipperSVG({ fillColor = COLORS.yellow }: Props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99994 3.48828C5.41494 3.48828 2.49744 6.40578 2.49744 9.99078C2.49744 13.5758 5.41494 16.5008 8.99994 16.5008C12.5849 16.5008 15.5024 13.5833 15.5024 9.99828C15.5024 6.41328 12.5849 3.48828 8.99994 3.48828ZM9.56244 9.75078C9.56244 10.0583 9.30744 10.3133 8.99994 10.3133C8.69244 10.3133 8.43744 10.0583 8.43744 9.75078V6.00078C8.43744 5.69328 8.69244 5.43828 8.99994 5.43828C9.30744 5.43828 9.56244 5.69328 9.56244 6.00078V9.75078Z"
        fill={fillColor}
      />
      <path
        d="M11.1674 2.5875H6.83236C6.53236 2.5875 6.29236 2.3475 6.29236 2.0475C6.29236 1.7475 6.53236 1.5 6.83236 1.5H11.1674C11.4674 1.5 11.7074 1.74 11.7074 2.04C11.7074 2.34 11.4674 2.5875 11.1674 2.5875Z"
        fill={fillColor}
      />
    </svg>
  );
}
