import { BackendFuctions, CreateNewUserProps, FirebaseConfig, ProviderType, UserPrivate, UserProfile } from 'types';
import type { FirebaseApp } from 'firebase/app';
import type { User, Auth } from 'firebase/auth';
import {
  collection,
  doc,
  onSnapshot,
  serverTimestamp,
  writeBatch,
  getFirestore,
  Firestore,
} from 'firebase/firestore';
import { FirebaseError } from '@firebase/util';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  linkWithCredential,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import * as auth from 'firebase/auth';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { getObjectFromString } from '../helpers/object';
export class PangoUser {
  private app: FirebaseApp;
  private db: Firestore;
  private cloudFunctions: Functions;
  auth: Auth;

  constructor(firebaseConfig: FirebaseConfig) {
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.app);
    this.cloudFunctions = getFunctions(this.app);
    this.auth = getAuth(this.app);
  }

  /********************
   *  PRIVATE METHODS
   ********************/

  private getDisplayName(name: string | null): string {
    if (typeof name === 'string') {
      return name;
    } else {
      return 'Pangoer';
    }
  }

  private generateShortId(): string {
    const random_num = Math.floor(Math.random() * 1000000);
    return random_num.toString();
  }

  private createUsername(name: string): string {
    const split = name.split(' ')[0];
    const short_id = this.generateShortId();
    return `${split}${short_id}`;
  }

  private async logActive(uid: string) {
    try {
      const batch = writeBatch(this.db);
      const shallow_ref = doc(this.db, 'web_active', uid);
      const deep_ref = doc(collection(this.db, `web_active/${uid}/web_active`));
      batch.set(
        shallow_ref,
        {
          last_active: serverTimestamp(),
        },
        { merge: true }
      );
      batch.set(deep_ref, {
        timestamp: serverTimestamp(),
      });
      await batch.commit();
    } catch (e) {
      console.error(`Err: logActive: `, e);
    }
    return;
  }

  private getProviderFromType(providerType: ProviderType): OAuthProvider {
    let provider: any;

    switch (providerType) {
      case 'google.com':
        provider = new GoogleAuthProvider();
        break;
      case 'facebook.com':
        provider = new FacebookAuthProvider();
        break;
      case 'apple.com':
        provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        break;
      default:
        throw new Error(`No provider found for ${providerType}`);
    }

    return provider;
  }

  private readableTextForProviderId(providerType: ProviderType) {
    switch (providerType) {
      case 'google.com':
        return 'Google';
      case 'facebook.com':
        return 'Facebook';
      case 'apple.com':
        return 'Apple';
      case 'password':
        return 'e-mail and password';
      default:
        throw new Error(`No provider found for ${providerType}`);
    }
  }

  private async createUserFromProvider(userFromProvider: User, anonymous_uid?: string) {
    try {
      const name = this.getDisplayName(userFromProvider.displayName);
      const email = userFromProvider.email as string;

      try {
        const uid = userFromProvider.uid;

        const functionName: BackendFuctions = 'users_manager-createNewUser';
        const requestData: CreateNewUserProps = {
          email,
          name,
          type: 'web',
          uid,
          ...(anonymous_uid && { anonymous_uid }),
        };

        const createNewUserCF = httpsCallable<CreateNewUserProps, { newUser: boolean; success: boolean; }>(this.cloudFunctions, functionName);
        const cloudResponse = await createNewUserCF(requestData);

        return { isNewUser: cloudResponse.data.newUser };
      } catch (e: any) {
        console.error(e);
        throw new Error(e);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }

  private async getSignInMethodsForEmail(email: string) {
    const existingProviders = await auth.fetchSignInMethodsForEmail(this.auth, email);

    return existingProviders;
  }

  private async handleAccountExistsWithCredentialsError(e: FirebaseError, provider: OAuthProvider) {
    let authResult: auth.UserCredential;
    const existingEmail: any = e.customData?.email;
    const attemptedCredentials = OAuthProvider.credentialFromError(e);
    const existingProviders = await this.getSignInMethodsForEmail(existingEmail);

    if (attemptedCredentials) {
      if (attemptedCredentials.providerId === provider.providerId && this.auth.currentUser) {
        try {
          authResult = await linkWithCredential(this.auth.currentUser, attemptedCredentials);
        } catch (e: any) {
          // Handle the error if the current user is not logged in.
          // In order to link the accounts, the user but first be logged in
          // with a provider that has valid credentials.
          const existingValidProviders = (joinWord: any) =>
            existingProviders.map((p) => this.readableTextForProviderId(p)).join(joinWord);
          const attemptedCredentialProvider = this.readableTextForProviderId(attemptedCredentials.providerId);

          throw new Error(
            `Looks like you previously logged in using ${existingValidProviders(
              'and '
            )}.  In order to sign in using ${attemptedCredentialProvider}, you must first sign in using ${existingValidProviders(
              'or '
            )} to then link your account to ${attemptedCredentialProvider}`
          );
        }

        return authResult.user;
      }
    }
  }

  private async providerLogin(providerType: ProviderType) {
    const provider = this.getProviderFromType(providerType);
    let providerSignInResult: auth.UserCredential;

    try {
      const currentUser = this.auth.currentUser;
      const anonymous_uid = currentUser?.uid;
      providerSignInResult = await signInWithPopup(this.auth, provider);

      if (!providerSignInResult?.user?.email) {
        throw new Error(`${this.getProviderFromType(providerType).providerId} - No Provider Email`);
      }

      if (providerSignInResult) {
        const { isNewUser } = await this.createUserFromProvider(providerSignInResult.user, anonymous_uid);

        return { user: providerSignInResult.user, isNewUser };
      }
    } catch (e: any) {
      if (e.message.includes('No Provider Email')) {
        throw new Error(e.message);
      }

      try {
        const userFromExistingCredentials = await this.handleAccountExistsWithCredentialsError(e, provider);

        if (userFromExistingCredentials) return { user: userFromExistingCredentials, isNewUser: false };
      } catch (e: any) {
        throw new Error(e);
      }

      throw new Error(e);
    }
  }

  /********************
   *  PUBLIC METHODS
   ********************/
  async loginWithEmail(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email.trim(), password.trim());

      const user = userCredential.user;

      return user;
    } catch (e: any) {
      const errObj = getObjectFromString(e.message);
      if (errObj?.error?.details?.custom_code === 'code_required') {
        throw new Error('code_required');
      }
      throw new Error(e.code.replace('auth/', ''));
    }
  }

  async signUpWithEmail(name: string, email: string, password: string) {
    try {
      const currentUser = this.auth.currentUser;
      const anonymous_uid = currentUser?.uid;
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email.trim()?.toLowerCase(),
        password.trim()
      );
      const user = userCredential.user;
      const uid = user.uid;

      const functionName: BackendFuctions = 'users_manager-createNewUser';
      const requestData: CreateNewUserProps = {
        email,
        name,
        type: 'web',
        uid,
        ...(anonymous_uid && { anonymous_uid }),
      };

      const createNewUserCF = httpsCallable<CreateNewUserProps, { newUser: boolean; success: boolean; }>(this.cloudFunctions, functionName);
      await createNewUserCF(requestData);

      return user;
    } catch (e: any) {
      if (e.code === 'auth/email-already-in-use') {
        this.loginWithEmail(email, password);
      }

      throw new Error(e.code.replace('auth/', ''));
    }
  }

  loginWithGoogle() {
    return this.providerLogin('google.com');
  }

  loginWithFacebook() {
    return this.providerLogin('facebook.com');
  }

  loginWithApple() {
    return this.providerLogin('apple.com');
  }

  async logout(callback?: () => void) {
    try {
      await this.auth.signOut();
      if (callback) callback();
      return;
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async sendPwResetEmail(email: string) {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (e: any) {
      throw new Error(e.code.replace('auth/', ''));
    }
  }

  onUserChange(callback: (user: User | null) => void) {
    return onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        // logged in
        if (!user.isAnonymous) await this.logActive(user.uid);
        callback(user);
      } else {
        // logged out
        const anonymousCredential = await signInAnonymously(this.auth);
        const anonymousUser = anonymousCredential.user;
        callback(anonymousUser);
      }
    });
  }

  onUserProfile(userId: string, callback: (profile: UserProfile | null) => void) {
    const unsubscribe = onSnapshot(doc(this.db, 'users', userId), (doc) => {
      if (doc.exists()) {
        const profile = doc.data() as UserProfile;
        if (profile.email) delete profile.email;
        if (profile.new_email) delete profile.new_email;
        if (profile.original_email) delete profile.original_email;
        callback({ ...profile, uid: userId });
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }

  onUserPrivate(userId: string, callback: (profile: UserPrivate | null) => void) {
    const unsubscribe = onSnapshot(doc(this.db, 'user_private', userId), (doc) => {
      if (doc.exists()) {
        const userPrivate = doc.data() as UserPrivate;
        callback(userPrivate);
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }
}
