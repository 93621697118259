import { useEffect, useMemo, useState } from 'react';
import { PageNames, ProductAdded, ProductAddedSource, ProductClick, ProductListView, ProductRemoved, ProductView, ProductViewSource } from 'types';
import { useGlobalState } from '~/state';
import { UserState } from '~/state/reducers/user';
import * as Sentry from '@sentry/nextjs';
import { getCookie } from 'cookies-next';

export default function useAnalytics() {
  const [ga4ClientId, setGa4ClientId] = useState<string>('');

  const { state } = useGlobalState();
  const analytics = useMemo(() => state?.analytics?.data?.analytics, [state.analytics]);
  const shouldTrack = useMemo(() => state?.user?.data?.shouldTrack, [state.user]);

  useEffect(() => {
    const gtag = window.gtag;
    if (gtag) {
      gtag('get', process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID, 'client_id', (client_id) => {
        setGa4ClientId(client_id);
      });
    }
  }, []);

  async function authEvent(
    name: 'Signed Up GA' | 'Signed In',
    method: 'apple' | 'google' | 'facebook' | 'email',
    attributes: { email: string; id: string; }
  ) {
    analytics?.user().id(attributes.id);
    // if (!shouldTrack) return;
    await analytics?.track(name, {
      method,
      ...attributes,
      ga4ClientId,
      currentUrl: window.location.href,
    });
  }

  async function identifyUser({ user, anonymous = false, ipAddress }: { user: UserState, anonymous?: boolean; ipAddress?: string; }) {
    if (anonymous) {
      // segment will assign an anonymous id and keep track of it. 
      // All track calls etc will be attributed to the anonymous user.
      // if they create an account segment will merge the anonymous account with the new account.
      return analytics?.identify(
        null,
        {
          firestoreAnonymousId: user.uid,
          userAgent: window?.navigator?.userAgent || 'none',
          ipAddress
        });
    }

    try {
      Sentry.setUser({ id: user.uid });
    } catch (e: any) {
      // not throwing. Dont want to fail here
    }

    return analytics?.identify(user.uid, {
      userToken: user.uid,
      email: user.email,
      enablePersonalization: true,
      referrer: document.referrer,
      currentUrl: window.location.href,
      username: user.username as string,
      createdAt: user.time_created?.toDate()?.toISOString(),
      name: user.name,
      main_interest: user.main_interest ?? 'not_set',
      books_listed: user.books_listed ?? 0,
      seller_score: user.score?.value ?? 0,
      userAgent: window?.navigator?.userAgent || 'none',
      ipAddress
    });
  }

  async function productAdded(attributes: ProductAdded) {
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));
    // if (!shouldTrack) return;
    await analytics?.track('Product Added', {
      ...attributes,
      id: attributes.product.id,
      price: value,
      value,
      currency: 'USD',
      ga4ClientId,
      fbc: getCookie('_fbc') || '',
      fbp: getCookie('_fbp') || '',
      objectID: attributes.product.id,
      currentUrl: window.location.href,
      index: attributes?.product?.index,
      objectIDs: attributes?.product?.objectIDs,
      userToken: state?.user?.data?.uid
    });
  }

  async function productRemoved(attributes: ProductRemoved) {
    // if (!shouldTrack) return;
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));
    await analytics?.track('Product Removed', {
      ...attributes,
      id: attributes.product.id,
      price: value,
      value,
      currency: 'USD',
      ga4ClientId,
      currentUrl: window.location.href,
    });
  }

  async function productView(attributes: ProductView) {
    // if (!shouldTrack) return;
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));
    await analytics?.track('Product Viewed', {
      ...attributes,
      id: attributes.product.id,
      price: value,
      value,
      ga4ClientId,
      fbc: getCookie('_fbc') || '',
      fbp: getCookie('_fbp') || '',
      currency: 'USD',
      objectID: attributes.product.id,
      currentUrl: window.location.href,
      index: attributes?.product?.index,
      objectIDs: attributes?.product?.objectIDs,
      userToken: state?.user?.data?.uid
    });
  }

  // TODO
  async function cartProcess(
    name: 'Cart Viewed' | 'Checkout Started' | 'Checkout Step Viewed' | 'Checkout Step Completed' | 'Optimize Cart Click',
    attributes: any // TODO - decide what and how to track cart events
  ) {
    // if (!shouldTrack) return;
    await analytics?.track(name, {
      ...attributes,
      ga4ClientId,
      fbc: getCookie('_fbc') || '',
      fbp: getCookie('_fbp') || '',
      currency: 'USD'
    });
  }

  async function checkoutProcess(
    name: 'Payment Info Entered' | 'Order Updated' | 'Order Completed GA' | 'Order Refunded' | 'Order Cancelled' | 'Buy It Now',
    attributes: any // TODO - decide what and how to track checkout events
  ) {
    // if (!shouldTrack) return;
    await analytics?.track(name, {
      ...attributes,
      currency: 'USD',
      ga4ClientId,
      fbc: getCookie('_fbc') || '',
      fbp: getCookie('_fbp') || ''
    });
  }

  async function productClick(attributes: ProductClick) {
    // if (!shouldTrack) return;
    await analytics?.track('Product Clicked', {
      ...attributes,
      ga4ClientId,
      currentUrl: window.location.href,
      index: attributes?.product?.index,
      objectIDs: attributes?.product?.objectIDs,
      userToken: state?.user?.data?.uid,
    });
  }

  async function heyPangoProductClick(attributes: any) {
    // if (!shouldTrack) return;
    await analytics?.track('Hey Pango Product Clicked', {
      ...attributes,
      ga4ClientId,
      userToken: state?.user?.data?.uid,
    });
  }

  async function productListView(attributes: ProductListView) {
    // if (!shouldTrack) return;
    return analytics?.track('Product List Viewed', {
      ...attributes,
      currentUrl: window.location.href,
      userToken: state?.user?.data?.uid
    });
  }

  function pageName(url: string): PageNames {
    switch (true) {
      case url.startsWith('/books/'):
        return 'Book';
      case url.startsWith('/bookstore'):
        return 'Bookstore';
      case url.startsWith('/search'):
        return 'Search';
      case url.startsWith('/titles'):
        return 'Title';
      case url.startsWith('/author/'):
        return 'Author';
      case url.startsWith('/author'):
        return 'Author Index';
      case url.startsWith('/account/settings/mysales'):
        return 'MySales';
      case url.startsWith('/series/'):
        return 'Series';
      case url.startsWith('/series'):
        return 'Series Index';
      case url === '/':
        return 'Home';
      default:
        return null;
    }
  }

  // Nextjs router events will return back just the URL of the page.
  async function pageView(url: string) {
    // if (!shouldTrack) return;
    const name = pageName(url);

    return analytics?.page('Page', {
      pageType: name,
      url: window.location.href,
      path: window.location.pathname,
      referrer: document.referrer,
      ga4ClientId,
      fbc: getCookie('_fbc') || '',
      fbp: getCookie('_fbp') || '',
    });
  }

  async function addToShelf({
    source,
    type,
    shelf = ''
  }: {
    source: ProductViewSource | ProductAddedSource;
    type: 'copy' | 'title';
    title_id: string;
    shelf?: string;
  }) {
    // if (!shouldTrack) return;
    await analytics?.track('Add To Shelf', {
      source,
      type,
      shelf,
      userToken: state?.user?.data?.uid
    });
  }

  async function shareShelf({
    type
  }: {
    type: 'email' | 'link';
  }) {
    // if (!shouldTrack) return;
    await analytics?.track('Share Shelf', {
      type,
      userToken: state?.user?.data?.uid
    });
  }

  async function likeShelf({
    shelf
  }: {
    shelf: string;
  }) {
    // if (!shouldTrack) return;
    await analytics.track('Like Shelf', {
      shelf
    });
  }

  async function shelfOp(params: {
    op: string;
    notify: boolean;
    is_public: boolean;
  }) {
    // if (!shouldTrack) return;
    await analytics?.track('Shelf Op', params);
  }

  async function viewShelf({
    shelf_id,
    username
  }: {
    shelf_id: string;
    username: string;
  }) {
    // if (!shouldTrack) return;
    await analytics?.track('View Shelf', { shelf_id, username });
  }

  async function searched(attributes: {
    query: string;
    titles: number;
    books: number;
    hashtags: number;
    users: number;
  }) {
    // if (!shouldTrack) return;
    await analytics?.track('Searched', {
      ...attributes,
      ga4ClientId,
      currentUrl: window.location.href,
    });
  }

  async function generalTrack(name: string, attributes: any) {
    // if (!shouldTrack) return;
    return analytics?.track(name, attributes);
  }

  return {
    analytics,
    analyticsLoaded: !!analytics && !!ga4ClientId,
    analyticEvents: {
      authEvent,
      cartProcess,
      checkoutProcess,
      identifyUser,
      pageView,
      productAdded,
      productClick,
      productListView,
      heyPangoProductClick,
      productRemoved,
      productView,
      searched,
      addToShelf,
      shelfOp,
      shareShelf,
      viewShelf,
      likeShelf,
      generalTrack,
    },
  };
}
