export default function AppStoreButtonSVG() {
  return (
    <svg viewBox="0 0 136 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M124.819 0.0001495H10.806C10.3904 0.0001495 9.97977 0.0001495 9.56528 0.0024495C9.21831 0.0047495 8.8741 0.011431 8.5238 0.0170545C7.76278 0.0261416 7.00355 0.0940772 6.25271 0.220271C5.50293 0.349212 4.77663 0.592303 4.09838 0.941321C3.42097 1.29329 2.80199 1.75064 2.26391 2.29678C1.72301 2.84138 1.27213 3.47087 0.928597 4.16103C0.58412 4.84982 0.345259 5.58793 0.220263 6.34985C0.0940791 7.11081 0.0261782 7.88055 0.01717 8.65215C0.00665267 9.00474 0.005542 9.3585 0 9.71114V36.2925C0.005542 36.6496 0.00665267 36.9955 0.01717 37.3527C0.0261809 38.1243 0.0940818 38.894 0.220263 39.6549C0.344914 40.4173 0.583789 41.1558 0.928597 41.8449C1.27197 42.5328 1.72292 43.1597 2.26391 43.7013C2.79995 44.2499 3.41933 44.7075 4.09838 45.0568C4.77662 45.4068 5.50284 45.6513 6.25271 45.7822C7.00368 45.9074 7.76283 45.9754 8.5238 45.9855C8.8741 45.9934 9.21831 45.9978 9.56528 45.9978C9.97976 46.0001 10.3904 46.0001 10.806 46.0001H124.819C125.227 46.0001 125.641 46.0001 126.048 45.9978C126.393 45.9978 126.747 45.9934 127.093 45.9855C127.852 45.9759 128.61 45.9079 129.359 45.7822C130.112 45.6504 130.841 45.4059 131.522 45.0568C132.2 44.7073 132.819 44.2497 133.355 43.7013C133.894 43.1576 134.346 42.5311 134.694 41.8449C135.036 41.1553 135.273 40.4168 135.396 39.6549C135.522 38.8939 135.592 38.1244 135.606 37.3527C135.61 36.9955 135.61 36.6496 135.61 36.2925C135.619 35.8747 135.619 35.4592 135.619 35.0347V10.9667C135.619 10.5456 135.619 10.1278 135.61 9.71114C135.61 9.3585 135.61 9.00474 135.606 8.65211C135.592 7.88044 135.522 7.11087 135.396 6.34981C135.273 5.58832 135.036 4.85028 134.694 4.16099C133.994 2.77762 132.885 1.65158 131.522 0.941217C130.841 0.593052 130.112 0.350026 129.359 0.220167C128.61 0.0934177 127.852 0.0254584 127.093 0.0168935C126.747 0.0112815 126.393 0.0045425 126.048 0.0023C125.641 0 125.227 0 124.819 0V0.0001495Z"
          fill="#A6A6A6"
        />
        <path
          d="M9.5709 44.9953C9.22559 44.9953 8.88863 44.9908 8.54604 44.983C7.83632 44.9736 7.12827 44.9109 6.42768 44.7954C5.77441 44.6813 5.14158 44.4689 4.55004 44.1654C3.96393 43.8643 3.42934 43.4696 2.96677 42.9963C2.4975 42.5285 2.10682 41.986 1.81019 41.3903C1.5103 40.7907 1.30276 40.1479 1.19479 39.4845C1.0782 38.7716 1.01512 38.0508 1.00609 37.3283C0.998909 37.0857 0.989502 36.2782 0.989502 36.2782V9.71255C0.989502 9.71255 0.999521 8.91743 1.00615 8.68383C1.01479 7.96242 1.07751 7.24271 1.19375 6.53095C1.30192 5.8657 1.50962 5.22112 1.80967 4.61954C2.10521 4.0242 2.49372 3.4813 2.96016 3.01189C3.42608 2.53803 3.96237 2.14126 4.54948 1.83605C5.13966 1.53347 5.7713 1.3226 6.42325 1.21052C7.12614 1.09387 7.83665 1.03081 8.54882 1.02185L9.57145 1.00781H126.042L127.077 1.02242C127.783 1.03093 128.487 1.09343 129.184 1.2094C129.842 1.32288 130.48 1.53522 131.077 1.83942C132.253 2.4545 133.21 3.4276 133.814 4.62234C134.109 5.21978 134.314 5.8591 134.421 6.5186C134.538 7.2362 134.604 7.96156 134.618 8.68889C134.621 9.01457 134.621 9.3644 134.621 9.71255C134.63 10.1438 134.63 10.5543 134.63 10.9681V35.0361C134.63 35.4539 134.63 35.8616 134.621 36.2726C134.621 36.6465 134.621 36.9891 134.617 37.3417C134.603 38.0561 134.539 38.7685 134.423 39.4732C134.317 40.1415 134.111 40.7892 133.811 41.3937C133.512 41.983 133.124 42.5208 132.66 42.9873C132.197 43.4631 131.661 43.8602 131.074 44.1632C130.479 44.4691 129.841 44.6822 129.184 44.7954C128.483 44.9115 127.775 44.9742 127.065 44.983C126.733 44.9908 126.386 44.9953 126.048 44.9953L124.82 44.9976L9.5709 44.9953Z"
          fill="black"
        />
        <path
          d="M28.0714 23.3471C28.0836 22.3873 28.3349 21.4461 28.8018 20.6113C29.2688 19.7764 29.9363 19.075 30.7422 18.5723C30.2302 17.8303 29.5548 17.2198 28.7696 16.789C27.9844 16.3583 27.1111 16.1193 26.2189 16.0909C24.3157 15.8882 22.4707 17.2465 21.5009 17.2465C20.5124 17.2465 19.0192 16.1111 17.4113 16.1446C16.3713 16.1787 15.3577 16.4856 14.4693 17.0354C13.5809 17.5851 12.848 18.359 12.342 19.2817C10.1501 23.1324 11.7851 28.7916 13.8847 31.9042C14.9352 33.4283 16.1629 35.1308 17.7693 35.0705C19.3413 35.0043 19.9284 34.0533 21.826 34.0533C23.7059 34.0533 24.2567 35.0705 25.8958 35.0321C27.5826 35.0043 28.6454 33.5012 29.659 31.9626C30.4138 30.8766 30.9946 29.6763 31.3799 28.4062C30.3999 27.9856 29.5636 27.2816 28.9753 26.3819C28.3869 25.4823 28.0726 24.4268 28.0714 23.3471Z"
          fill="white"
        />
        <path
          d="M24.9756 14.0416C25.8953 12.9212 26.3484 11.4812 26.2387 10.0273C24.8336 10.1771 23.5356 10.8585 22.6034 11.9359C22.1477 12.4622 21.7986 13.0745 21.5762 13.7378C21.3538 14.4011 21.2624 15.1023 21.3072 15.8015C22.0101 15.8088 22.7054 15.6543 23.3408 15.3494C23.9762 15.0446 24.5352 14.5974 24.9756 14.0416Z"
          fill="white"
        />
        <path
          d="M47.9426 31.2098H42.5781L41.2898 35.0698H39.0176L44.0988 20.7891H46.4595L51.5407 35.0698H49.2297L47.9426 31.2098ZM43.1337 29.4287H47.3859L45.2897 23.1643H45.231L43.1337 29.4287Z"
          fill="white"
        />
        <path
          d="M62.5143 29.8642C62.5143 33.0997 60.8077 35.1784 58.2322 35.1784C57.5798 35.2131 56.931 35.0606 56.36 34.7384C55.7891 34.4163 55.3191 33.9375 55.0038 33.3568H54.9551V38.5139H52.8489V24.6577H54.8876V26.3895H54.9263C55.2561 25.8116 55.734 25.3353 56.3093 25.0112C56.8847 24.6871 57.5358 24.5273 58.1935 24.5488C60.7977 24.5488 62.5143 26.6377 62.5143 29.8642ZM60.3495 29.8642C60.3495 27.7562 59.2759 26.3704 57.6379 26.3704C56.0287 26.3704 54.9462 27.7854 54.9462 29.8642C54.9462 31.962 56.0287 33.367 57.6379 33.367C59.2759 33.367 60.3495 31.9912 60.3495 29.8642Z"
          fill="white"
        />
        <path
          d="M73.8078 29.8642C73.8078 33.0997 72.1011 35.1784 69.5257 35.1784C68.8733 35.2131 68.2245 35.0606 67.6535 34.7384C67.0825 34.4162 66.6125 33.9374 66.2973 33.3568H66.2485V38.5139H64.1423V24.6577H66.181V26.3895H66.2197C66.5495 25.8116 67.0274 25.3353 67.6028 25.0112C68.1781 24.6871 68.8292 24.5273 69.4869 24.5488C72.0912 24.5488 73.8078 26.6377 73.8078 29.8642ZM71.6429 29.8642C71.6429 27.7562 70.5694 26.3704 68.9314 26.3704C67.3221 26.3704 66.2397 27.7854 66.2397 29.8642C66.2397 31.962 67.3221 33.367 68.9314 33.367C70.5694 33.367 71.643 31.9912 71.643 29.8642H71.6429Z"
          fill="white"
        />
        <path
          d="M81.2719 31.0906C81.4279 32.5068 82.7837 33.4366 84.6365 33.4366C86.4117 33.4366 87.6889 32.5068 87.6889 31.2298C87.6889 30.1214 86.9186 29.4577 85.0947 29.0028L83.2707 28.557C80.6864 27.9236 79.4866 26.6972 79.4866 24.7072C79.4866 22.2432 81.6028 20.5508 84.6077 20.5508C87.5815 20.5508 89.6202 22.2432 89.6889 24.7072H87.5627C87.4355 23.282 86.2744 22.4218 84.5778 22.4218C82.8811 22.4218 81.7201 23.2922 81.7201 24.5589C81.7201 25.5686 82.4616 26.1627 84.2756 26.6174L85.8262 27.0038C88.7138 27.6967 89.9135 28.8737 89.9135 30.9625C89.9135 33.6342 87.8162 35.3076 84.4804 35.3076C81.3593 35.3076 79.252 33.6735 79.1158 31.0905L81.2719 31.0906Z"
          fill="white"
        />
        <path
          d="M94.459 22.1953V24.6593H96.4102V26.3517H94.459V32.0916C94.459 32.9833 94.8497 33.3988 95.7074 33.3988C95.9391 33.3947 96.1704 33.3782 96.4003 33.3494V35.0317C96.0146 35.1048 95.6227 35.1379 95.2304 35.1305C93.153 35.1305 92.3429 34.3388 92.3429 32.3195V26.3517H90.851V24.6593H92.3428V22.1953H94.459Z"
          fill="white"
        />
        <path
          d="M97.5403 29.8657C97.5403 26.5898 99.4417 24.5312 102.407 24.5312C105.382 24.5312 107.274 26.5898 107.274 29.8657C107.274 33.1506 105.392 35.2002 102.407 35.2002C99.4228 35.2002 97.5403 33.1506 97.5403 29.8657ZM105.128 29.8657C105.128 27.6185 104.113 26.2922 102.407 26.2922C100.7 26.2922 99.6863 27.6286 99.6863 29.8657C99.6863 32.1219 100.7 33.4381 102.407 33.4381C104.113 33.4381 105.128 32.1219 105.128 29.8657H105.128Z"
          fill="white"
        />
        <path
          d="M109.011 24.6576H111.02V26.4298H111.068C111.204 25.8763 111.524 25.3872 111.973 25.0446C112.423 24.702 112.975 24.5269 113.536 24.5487C113.779 24.5478 114.021 24.5746 114.258 24.6284V26.6274C113.952 26.5324 113.632 26.4888 113.312 26.4983C113.006 26.4857 112.701 26.5404 112.418 26.6588C112.134 26.7771 111.88 26.9562 111.671 27.1838C111.463 27.4113 111.305 27.682 111.21 27.9772C111.114 28.2723 111.082 28.585 111.117 28.8938V35.0694H109.011L109.011 24.6576Z"
          fill="white"
        />
        <path
          d="M123.969 32.013C123.685 33.9031 121.871 35.2002 119.551 35.2002C116.566 35.2002 114.713 33.1708 114.713 29.9151C114.713 26.6493 116.576 24.5312 119.462 24.5312C122.301 24.5312 124.086 26.5101 124.086 29.6669V30.3992H116.839V30.5283C116.806 30.9115 116.853 31.2975 116.978 31.6607C117.103 32.0239 117.302 32.356 117.563 32.635C117.824 32.9139 118.141 33.1333 118.492 33.2785C118.843 33.4238 119.22 33.4916 119.599 33.4774C120.097 33.5248 120.596 33.4078 121.023 33.1439C121.45 32.8801 121.782 32.4834 121.969 32.013L123.969 32.013ZM116.849 28.9055H121.979C121.998 28.561 121.946 28.2162 121.828 27.8928C121.709 27.5694 121.526 27.2744 121.289 27.0263C121.053 26.7781 120.769 26.5823 120.454 26.4509C120.14 26.3196 119.802 26.2556 119.462 26.263C119.119 26.2609 118.779 26.3278 118.462 26.4597C118.144 26.5917 117.856 26.7861 117.613 27.0318C117.37 27.2775 117.177 27.5695 117.046 27.8911C116.915 28.2127 116.848 28.5575 116.849 28.9055V28.9055Z"
          fill="white"
        />
        <path
          d="M42.8696 10.0429C43.3112 10.0108 43.7543 10.0785 44.167 10.2411C44.5796 10.4038 44.9516 10.6573 45.256 10.9835C45.5604 11.3097 45.7896 11.7003 45.9272 12.1273C46.0648 12.5542 46.1073 13.0069 46.0516 13.4525C46.0516 15.6447 44.884 16.9048 42.8696 16.9048H40.427V10.0429H42.8696ZM41.4773 15.9344H42.7523C43.0678 15.9535 43.3836 15.901 43.6766 15.7807C43.9696 15.6604 44.2325 15.4753 44.4461 15.2389C44.6598 15.0025 44.8188 14.7208 44.9115 14.4142C45.0043 14.1076 45.0285 13.7838 44.9825 13.4665C45.0252 13.1504 44.9985 12.8287 44.9043 12.5243C44.8101 12.22 44.6507 11.9405 44.4376 11.706C44.2244 11.4715 43.9628 11.2877 43.6715 11.1678C43.3801 11.048 43.0662 10.995 42.7523 11.0126H41.4773V15.9344Z"
          fill="white"
        />
        <path
          d="M47.2381 14.3118C47.206 13.9715 47.2444 13.6282 47.3507 13.3038C47.4571 12.9794 47.629 12.6812 47.8556 12.4282C48.0822 12.1752 48.3585 11.9731 48.6666 11.8348C48.9747 11.6964 49.3078 11.625 49.6447 11.625C49.9816 11.625 50.3148 11.6964 50.6229 11.8348C50.931 11.9731 51.2072 12.1752 51.4338 12.4282C51.6604 12.6812 51.8324 12.9794 51.9388 13.3038C52.0451 13.6282 52.0835 13.9715 52.0514 14.3118C52.0841 14.6525 52.0462 14.9963 51.9401 15.3212C51.8341 15.6461 51.6622 15.9449 51.4355 16.1984C51.2088 16.4519 50.9324 16.6544 50.624 16.793C50.3156 16.9317 49.982 17.0033 49.6447 17.0033C49.3074 17.0033 48.9739 16.9317 48.6655 16.793C48.357 16.6544 48.0806 16.4519 47.854 16.1984C47.6273 15.9449 47.4554 15.6461 47.3494 15.3212C47.2433 14.9963 47.2054 14.6525 47.2381 14.3118ZM51.0155 14.3118C51.0155 13.1894 50.5185 12.5329 49.6464 12.5329C48.7709 12.5329 48.2785 13.1894 48.2785 14.3118C48.2785 15.4433 48.771 16.0947 49.6464 16.0947C50.5185 16.0947 51.0155 15.4388 51.0155 14.3118H51.0155Z"
          fill="white"
        />
        <path
          d="M58.4497 16.901H57.4049L56.3501 13.0872H56.2704L55.2201 16.901H54.1853L52.7786 11.7227H53.8001L54.7143 15.6741H54.7896L55.8388 11.7227H56.805L57.8542 15.6741H57.9339L58.8437 11.7227H59.8508L58.4497 16.901Z"
          fill="white"
        />
        <path
          d="M61.0339 11.7261H62.0035V12.5488H62.0787C62.2064 12.2533 62.4217 12.0056 62.6947 11.8403C62.9677 11.6749 63.2848 11.6001 63.6016 11.6262C63.85 11.6072 64.0993 11.6452 64.3312 11.7373C64.563 11.8295 64.7715 11.9733 64.9412 12.1583C65.1108 12.3433 65.2373 12.5646 65.3112 12.8059C65.3851 13.0472 65.4045 13.3023 65.368 13.5522V16.9045H64.3609V13.8088C64.3609 12.9767 64.0045 12.5628 63.2597 12.5628C63.0911 12.5549 62.9228 12.584 62.7663 12.6482C62.6098 12.7124 62.4689 12.8102 62.3532 12.9348C62.2374 13.0594 62.1496 13.208 62.0957 13.3703C62.0419 13.5326 62.0232 13.7048 62.0411 13.8751V16.9045H61.0339L61.0339 11.7261Z"
          fill="white"
        />
        <path
          d="M66.9729 9.70312H67.9801V16.903H66.9729V9.70312Z"
          fill="white"
        />
        <path
          d="M69.3801 14.3119C69.3481 13.9716 69.3865 13.6282 69.4928 13.3038C69.5992 12.9794 69.7712 12.6812 69.9979 12.4282C70.2245 12.1752 70.5007 11.9731 70.8088 11.8348C71.117 11.6965 71.4502 11.625 71.7871 11.625C72.124 11.625 72.4572 11.6965 72.7653 11.8348C73.0735 11.9731 73.3497 12.1752 73.5763 12.4282C73.8029 12.6812 73.9749 12.9794 74.0813 13.3038C74.1877 13.6282 74.2261 13.9716 74.194 14.3119C74.2267 14.6526 74.1887 14.9964 74.0826 15.3213C73.9765 15.6462 73.8046 15.945 73.5779 16.1985C73.3512 16.452 73.0748 16.6545 72.7664 16.7931C72.458 16.9317 72.1244 17.0033 71.7871 17.0033C71.4498 17.0033 71.1162 16.9317 70.8078 16.7931C70.4993 16.6545 70.2229 16.452 69.9962 16.1985C69.7695 15.945 69.5976 15.6462 69.4915 15.3213C69.3854 14.9964 69.3475 14.6526 69.3801 14.3119ZM73.1575 14.3119C73.1575 13.1895 72.6606 12.533 71.7885 12.533C70.913 12.533 70.4205 13.1895 70.4205 14.3119C70.4205 15.4434 70.913 16.0948 71.7885 16.0948C72.6606 16.0948 73.1575 15.4389 73.1575 14.3119H73.1575Z"
          fill="white"
        />
        <path
          d="M75.2543 15.4389C75.2543 14.5067 75.9383 13.9694 77.1524 13.893L78.5347 13.8121V13.3652C78.5347 12.8182 78.1784 12.5094 77.4899 12.5094C76.9277 12.5094 76.5381 12.7188 76.4263 13.085H75.4513C75.5542 12.1955 76.3788 11.625 77.5364 11.625C78.8159 11.625 79.5375 12.2713 79.5375 13.3652V16.9033H78.5679V16.1756H78.4883C78.3265 16.4366 78.0994 16.6494 77.8301 16.7923C77.5608 16.9351 77.2589 17.0029 76.9554 16.9886C76.7411 17.0112 76.5246 16.9881 76.3197 16.9206C76.1149 16.8531 75.9262 16.7427 75.766 16.5967C75.6057 16.4507 75.4774 16.2722 75.3892 16.0728C75.3011 15.8734 75.2551 15.6574 75.2543 15.4389ZM78.5347 14.9964V14.5634L77.2885 14.6443C76.5857 14.692 76.267 14.9346 76.267 15.3911C76.267 15.8572 76.6654 16.1284 77.2133 16.1284C77.3738 16.1449 77.536 16.1284 77.6901 16.08C77.8443 16.0316 77.9872 15.9523 78.1105 15.8466C78.2338 15.741 78.3349 15.6113 78.4078 15.4653C78.4807 15.3192 78.5238 15.1597 78.5347 14.9964Z"
          fill="white"
        />
        <path
          d="M80.8612 14.3116C80.8612 12.6753 81.6902 11.6387 82.9796 11.6387C83.2985 11.6238 83.615 11.7013 83.8922 11.8622C84.1693 12.0231 84.3956 12.2607 84.5445 12.5472H84.6198V9.70312H85.6269V16.903H84.6619V16.0848H84.5822C84.4217 16.3694 84.1875 16.6041 83.905 16.7633C83.6225 16.9226 83.3025 17.0004 82.9796 16.9883C81.6813 16.9884 80.8612 15.9518 80.8612 14.3116ZM81.9016 14.3116C81.9016 15.4099 82.4118 16.0708 83.2651 16.0708C84.114 16.0708 84.6386 15.4004 84.6386 14.3161C84.6386 13.2368 84.1085 12.5568 83.2651 12.5568C82.4173 12.5568 81.9016 13.2222 81.9016 14.3116H81.9016Z"
          fill="white"
        />
        <path
          d="M89.794 14.3118C89.7619 13.9715 89.8003 13.6282 89.9066 13.3038C90.013 12.9794 90.185 12.6812 90.4116 12.4282C90.6382 12.1752 90.9144 11.9731 91.2225 11.8348C91.5306 11.6964 91.8637 11.625 92.2007 11.625C92.5376 11.625 92.8707 11.6964 93.1788 11.8348C93.4869 11.9731 93.7631 12.1752 93.9897 12.4282C94.2163 12.6812 94.3883 12.9794 94.4947 13.3038C94.601 13.6282 94.6394 13.9715 94.6073 14.3118C94.64 14.6525 94.6021 14.9963 94.496 15.3212C94.39 15.6461 94.2181 15.9449 93.9914 16.1984C93.7648 16.4519 93.4883 16.6544 93.1799 16.793C92.8715 16.9317 92.538 17.0033 92.2007 17.0033C91.8633 17.0033 91.5298 16.9317 91.2214 16.793C90.913 16.6544 90.6365 16.4519 90.4099 16.1984C90.1832 15.9449 90.0113 15.6461 89.9053 15.3212C89.7992 14.9963 89.7613 14.6525 89.794 14.3118ZM93.5714 14.3118C93.5714 13.1894 93.0744 12.5329 92.2023 12.5329C91.3269 12.5329 90.8344 13.1894 90.8344 14.3118C90.8344 15.4433 91.3269 16.0947 92.2023 16.0947C93.0744 16.0947 93.5714 15.4388 93.5714 14.3118Z"
          fill="white"
        />
        <path
          d="M95.9587 11.7261H96.9283V12.5488H97.0035C97.1312 12.2533 97.3465 12.0056 97.6195 11.8403C97.8925 11.6749 98.2096 11.6001 98.5265 11.6262C98.7748 11.6072 99.0241 11.6452 99.256 11.7373C99.4879 11.8295 99.6963 11.9733 99.866 12.1583C100.036 12.3433 100.162 12.5646 100.236 12.8059C100.31 13.0472 100.329 13.3023 100.293 13.5522V16.9045H99.2857V13.8088C99.2857 12.9767 98.9293 12.5628 98.1845 12.5628C98.0159 12.5549 97.8476 12.584 97.6911 12.6482C97.5346 12.7124 97.3937 12.8102 97.278 12.9348C97.1622 13.0594 97.0744 13.208 97.0205 13.3703C96.9667 13.5326 96.948 13.7048 96.9659 13.8751V16.9045H95.9587V11.7261Z"
          fill="white"
        />
        <path
          d="M105.984 10.4336V11.7464H107.09V12.6073H105.984V15.27C105.984 15.8124 106.204 16.0499 106.705 16.0499C106.834 16.0495 106.962 16.0417 107.09 16.0264V16.8776C106.909 16.9105 106.725 16.9279 106.542 16.9298C105.422 16.9298 104.976 16.53 104.976 15.5317V12.6072H104.165V11.7464H104.976V10.4336H105.984Z"
          fill="white"
        />
        <path
          d="M108.465 9.70312H109.464V12.5568H109.543C109.677 12.2586 109.898 12.0095 110.177 11.8435C110.455 11.6775 110.777 11.6026 111.099 11.6292C111.346 11.6155 111.593 11.6574 111.822 11.7517C112.052 11.8461 112.258 11.9906 112.425 12.175C112.593 12.3594 112.719 12.5791 112.794 12.8184C112.868 13.0578 112.89 13.3109 112.857 13.5597V16.903H111.849V13.8118C111.849 12.9847 111.469 12.5658 110.757 12.5658C110.584 12.5514 110.41 12.5755 110.247 12.6365C110.084 12.6975 109.936 12.7939 109.814 12.919C109.692 13.044 109.598 13.1947 109.539 13.3605C109.48 13.5262 109.457 13.7031 109.472 13.8786V16.903H108.465L108.465 9.70312Z"
          fill="white"
        />
        <path
          d="M118.729 15.5052C118.593 15.9785 118.296 16.3879 117.892 16.6617C117.488 16.9356 117.001 17.0565 116.518 17.0034C116.182 17.0124 115.848 16.947 115.539 16.8119C115.23 16.6767 114.954 16.475 114.73 16.2207C114.505 15.9663 114.338 15.6656 114.24 15.3392C114.142 15.0128 114.115 14.6686 114.161 14.3305C114.116 13.9914 114.143 13.6465 114.241 13.3191C114.339 12.9917 114.506 12.6895 114.729 12.433C114.952 12.1765 115.227 11.9716 115.535 11.8322C115.843 11.6928 116.176 11.6222 116.514 11.6251C117.934 11.6251 118.79 12.6095 118.79 14.2356V14.5922H115.187V14.6495C115.171 14.8395 115.194 15.0308 115.256 15.211C115.317 15.3913 115.415 15.5566 115.543 15.6962C115.671 15.8359 115.826 15.9469 115.999 16.0221C116.172 16.0973 116.358 16.1351 116.546 16.133C116.787 16.1623 117.031 16.1183 117.247 16.0065C117.463 15.8947 117.642 15.7202 117.76 15.5052L118.729 15.5052ZM115.187 13.8364H117.764C117.777 13.6626 117.754 13.488 117.696 13.3239C117.638 13.1597 117.547 13.0097 117.429 12.8834C117.311 12.757 117.168 12.6572 117.009 12.5903C116.85 12.5234 116.68 12.4909 116.508 12.4949C116.334 12.4927 116.161 12.5259 116 12.5925C115.838 12.6591 115.692 12.7579 115.569 12.8829C115.445 13.0079 115.348 13.1567 115.283 13.3204C115.217 13.4842 115.184 13.6596 115.187 13.8364H115.187Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2766_64557">
          <rect width="136" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
