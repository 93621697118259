'use client';

import { UserProfile } from 'types';
import classNames from 'classnames';
import { pluralize } from 'sdk';

import COLORS from '../lib/helpers/color-helper';

import styles from '../styles/components/user-review-stars.module.scss';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import BuyerReviews from './buyer-reviews';
import StarRating from './star-rating';
import Tooltip from './tooltip';

export default function UserReviewStars({
  user,
  withCountText,
  inline,
  cssClass,
}: {
  user: UserProfile;
  withCountText?: boolean;
  inline?: boolean;
  cssClass?: any;
}) {
  const { dispatch } = useGlobalState();

  if (!user?.seller_review?.num_ratings) return null;

  const handleShowReviews = () => {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        component: <BuyerReviews seller={user} />,
      },
    });
  };

  return (
    <div
      className={classNames(styles.container, {
        [cssClass]: !!cssClass,
        [styles['container--inline']]: !!inline,
      })}
      onClick={handleShowReviews}
    >
      <Tooltip content={`${user.seller_review.avg_rating.toString()} out of 5 stars`} position='bottom'>
        <div className={styles.stars}>
          <StarRating
            maxStars={5}
            initialRating={Number(user.seller_review.avg_rating)}
            disabled
            emptyColor={COLORS.lighterGrey}
            size={22}
          />
        </div>
      </Tooltip>
      {withCountText && <span className={styles.count}>({user?.seller_review?.num_ratings?.toString()})</span>}
    </div>
  );
}
