'use client';

import { createContext, useContext } from 'react';
import { useABTest } from '@/lib/hooks/use-ab-test';

interface ABTestContextProps {
  testGroups: string[];
  loaded: boolean;
}

export const ABTestContext = createContext<ABTestContextProps>({
  testGroups: [],
  loaded: false,
});

export default function ABTestProvider({ children }: { children: React.ReactNode; }) {

  // const { testGroup: retailPriceTestGroup } = useABTest({
  //   testGroups: ['retail-price-control', 'retail-price-test'],
  //   testId: 'retail-price'
  // });

  return (
    <ABTestContext.Provider
      value={{
        testGroups: [], // add testgroups here i.e. [retailPriceTestGroup]
        loaded: true, // set to true when testgroups are loaded i.e. !!retailPriceTestGroup
      }}
    >
      {children}
    </ABTestContext.Provider>
  );
}

export function useABTestGroups() {
  return useContext(ABTestContext);
}