interface Props {
  fillColor?: string;
  size?: number;
}

export default function InfoMoneySVG({ fillColor = '#A5ABC0', size = 16 }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 10.6134H8.93333C9.36667 10.6134 9.72667 10.2267 9.72667 9.76005C9.72667 9.18005 9.52 9.06671 9.18 8.94671L8.50667 8.71338V10.6134H8.5Z" fill={fillColor} />
      <path d="M7.9807 1.26661C4.3007 1.27995 1.3207 4.27328 1.33403 7.95328C1.34736 11.6333 4.3407 14.6133 8.0207 14.5999C11.7007 14.5866 14.6807 11.5933 14.6674 7.91328C14.654 4.23328 11.6607 1.25995 7.9807 1.26661ZM9.50736 7.99995C10.0274 8.17995 10.7274 8.56661 10.7274 9.75995C10.7274 10.7866 9.9207 11.6133 8.93403 11.6133H8.5007V11.9999C8.5007 12.2733 8.27403 12.4999 8.0007 12.4999C7.72736 12.4999 7.5007 12.2733 7.5007 11.9999V11.6133H7.2607C6.16736 11.6133 5.2807 10.6933 5.2807 9.55995C5.2807 9.28661 5.50736 9.05995 5.7807 9.05995C6.05403 9.05995 6.2807 9.28661 6.2807 9.55995C6.2807 10.1399 6.7207 10.6133 7.2607 10.6133H7.5007V8.35995L6.49403 7.99995C5.97403 7.81995 5.27403 7.43328 5.27403 6.23995C5.27403 5.21328 6.0807 4.38661 7.06736 4.38661H7.5007V3.99995C7.5007 3.72661 7.72736 3.49995 8.0007 3.49995C8.27403 3.49995 8.5007 3.72661 8.5007 3.99995V4.38661H8.7407C9.83403 4.38661 10.7207 5.30661 10.7207 6.43995C10.7207 6.71328 10.494 6.93995 10.2207 6.93995C9.94736 6.93995 9.7207 6.71328 9.7207 6.43995C9.7207 5.85995 9.2807 5.38661 8.7407 5.38661H8.5007V7.63995L9.50736 7.99995Z" fill={fillColor} />
      <path d="M6.2793 6.24672C6.2793 6.82672 6.48596 6.94005 6.82596 7.06005L7.4993 7.29339V5.38672H7.06596C6.63263 5.38672 6.2793 5.77339 6.2793 6.24672Z" fill={fillColor} />
    </svg>
  );
}

