import React from 'react';

export default function ShelfIcon({ height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 121 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M88.6267 94.2767L100.795 73.9922H96.4132L90.8144 82.5121C90.6182 82.8108 90.1536 82.6718 90.1536 82.3145V73.9922H86.3979V93.6595C86.3979 94.8765 88.0007 95.3204 88.6267 94.2767Z'
        fill='#3B61EB'
        fillOpacity='0.25'
      />
      <path
        d='M32.1682 94.2767L20 73.9922H24.3817L29.9805 82.5121C30.1768 82.8108 30.6413 82.6718 30.6413 82.3145V73.9922H34.397V93.6595C34.397 94.8765 32.7943 95.3204 32.1682 94.2767Z'
        fill='#3B61EB'
        fillOpacity='0.25'
      />
      <g clipPath='url(#clip0_5788_14842)'>
        <path
          d='M7 71.6217V69.3627C7 68.0535 8.06131 66.9922 9.3705 66.9922H111.63C112.939 66.9922 114 68.0535 114 69.3627V71.6217C114 72.9309 112.939 73.9922 111.629 73.9922H9.3705C8.06131 73.9922 7 72.9309 7 71.6217Z'
          fill='#7D98F8'
        />
        <path
          d='M5.88629 70.6875C16.582 70.0262 27.8642 71.4304 42.8905 74.6875M51.8071 74.6875C76.0062 72.2987 99.5457 69.6402 110.211 66.6875'
          stroke='#C4CFF6'
        />
        <path
          d='M10.7905 66.1875C37.9863 70.1875 51.2103 69.687 61.1696 66.1875'
          stroke='#C4CFF6'
        />
      </g>
      <path
        d='M63.2239 53.4002C65.8522 32.0962 94.0188 33.0161 92.1082 45.3715C90.6168 55.0161 60.9984 46.4794 73.2729 26.6651C77.3967 20.0157 86.0875 16.1477 97.7567 17.2967'
        stroke='#7D98F8'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='3.71 3.71'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M110.39 15.1571L110.39 15.1571C110.823 15.4123 111.237 15.6555 111.624 15.8849C112.569 16.444 113.367 16.9263 113.908 17.2914C114.172 17.4702 114.406 17.6405 114.565 17.7903C114.637 17.8575 114.745 17.9667 114.818 18.107C114.856 18.1793 114.912 18.3116 114.905 18.4816C114.898 18.6798 114.808 18.8602 114.663 18.9858C114.589 19.0497 114.502 19.0955 114.41 19.1209C114.382 19.1443 114.355 19.1646 114.332 19.1817C114.156 19.3123 113.905 19.4549 113.622 19.6023C113.043 19.9032 112.195 20.2912 111.193 20.7388C110.782 20.9224 110.343 21.1168 109.883 21.3207L109.883 21.3207C108.084 22.118 105.958 23.06 103.888 24.0648C102.345 24.8135 101.104 25.1754 100.254 25.1845C99.8518 25.1888 99.3756 25.1159 99.0546 24.785C98.668 24.3865 98.7245 23.8714 98.8893 23.4995L98.9131 23.4457C99.8727 21.28 100.345 20.2147 101.048 17.6356C101.067 17.5664 101.097 17.5024 101.135 17.4453C100.754 15.0591 100.407 13.9477 99.7342 11.7932L99.7167 11.737C99.5955 11.3488 99.5981 10.8305 100.028 10.4788C100.384 10.1868 100.866 10.1687 101.265 10.219C102.108 10.325 103.3 10.8264 104.747 11.7463C106.689 12.981 108.693 14.1596 110.39 15.1571ZM112.113 17.6927L110.753 17.6227L102.395 17.0949C102.014 14.7495 101.652 13.5749 101.004 11.4994C101.033 11.5012 101.066 11.5042 101.103 11.5089C101.659 11.5788 102.655 11.9569 104.05 12.8434C106.013 14.0916 108.043 15.2852 109.74 16.2833L109.74 16.2833C110.171 16.5368 110.581 16.7776 110.962 17.0035C111.384 17.2534 111.77 17.4839 112.113 17.6927ZM110.679 18.9207L102.162 18.4826C101.515 20.769 101.022 21.8947 100.141 23.8827C100.17 23.8842 100.203 23.8849 100.241 23.8845C100.801 23.8785 101.834 23.6167 103.32 22.8952C105.413 21.8795 107.566 20.9255 109.366 20.1278L109.366 20.1278L109.367 20.1274C109.824 19.925 110.258 19.7326 110.663 19.5518C111.112 19.3511 111.523 19.1657 111.888 18.997L110.679 18.9207Z'
        fill='#7D98F8'
      />
      <defs>
        <clipPath id='clip0_5788_14842'>
          <rect
            width='107'
            height='7'
            fill='white'
            transform='translate(7 66.9922)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
