import { Action, ActionType, GlobalState, initialState } from '../types';

export interface UiState {
  routeIsTransitioning?: boolean;
  adminPageEditing?: boolean;
}

const uiReducer = (
  state: GlobalState,
  action?: Action<UiState>
): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_UI_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          data: {
            ...state.ui.data,
            ...payload,
          },
        },
      };
    }

    case ActionType.FLUSH_UI_DATA: {
      return {
        ...state,
        ui: {
          data: initialState.ui.data,
        },
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
