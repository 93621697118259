import COLORS from "~/lib/helpers/color-helper";

interface Props {
  leftHalfColor?: string;
  rightHalfColor?: string;
  size?: number;
}

export default function StarHalf({ leftHalfColor = COLORS.copy, rightHalfColor = COLORS.copy, size = 18 }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.2625V2C11.3717 2.00061 10.7447 2.47809 10.27 3.4325L8.51001 6.95251C8.26999 7.4425 7.63 7.91248 7.09 8.0025L3.89999 8.53247C2.71066 8.73071 2.05157 9.31641 2 10.0597V10.2516C2.03265 10.7362 2.30951 11.2757 2.85001 11.8125L5.32999 14.2925C5.75 14.7125 5.98001 15.5225 5.85001 16.1025L5.14001 19.1725C4.57999 21.6025 5.87 22.5425 8.01999 21.2725L11.01 19.5025C11.2809 19.3419 11.64 19.262 12 19.2625Z"
        fill={leftHalfColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.2625V2C12.6283 2.00063 13.2553 2.47813 13.73 3.4325L15.49 6.9525C15.73 7.4425 16.37 7.9125 16.91 8.0025L20.1 8.5325C21.2893 8.73072 21.9484 9.31643 22 10.0597V10.2516C21.9674 10.7362 21.6905 11.2757 21.15 11.8125L18.67 14.2925C18.25 14.7125 18.02 15.5225 18.15 16.1025L18.86 19.1725C19.42 21.6025 18.13 22.5425 15.98 21.2725L12.99 19.5025C12.7191 19.3419 12.36 19.2619 12 19.2625Z"
        fill={rightHalfColor}
      />
    </svg>
  );
}