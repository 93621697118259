import { UserProfile, UserPrivate } from 'types';
import { Action, ActionType, GlobalState, initialState } from '../types';

export interface UserState extends Omit<UserProfile, 'email'>, UserPrivate {
  isAdmin?: boolean;
  shouldTrack?: boolean; // Add this line
}

const userReducer = (state: GlobalState, action?: Action<UserState>): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_USER_DATA: {
      const newState = {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...payload,
          },
        },
      };
      return newState;
    }

    case ActionType.FLUSH_USER_DATA: {
      return {
        ...state,
        user: {
          data: initialState.user.data,
        },
      };
    }

    case ActionType.SET_SHOULD_TRACK: {
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            shouldTrack: payload?.shouldTrack,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
