import classNames from 'classnames';
import styles from '@/styles/components/typography/heading.module.scss';
import { ChildrenLayoutProps } from 'types';
import { spacing } from '@/lib/helpers/spacing';
import { HTMLAttributes } from 'react';
import { omit } from 'sdk';

type ParagraphLevel = 'p' | 'span';

interface ParagraphProps extends ChildrenLayoutProps, HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  as?: ParagraphLevel;
  color?: 'primary' | 'copy' | 'copyDark' | 'white' | 'green' | 'red';
  link?: boolean;
  copy?: boolean;
  font?: 'light' | 'normal' | 'bold';
  size?: 'xtraTiny' | 'tiny' | 'small' | 'base' | 'medium' | 'large' | 'jumbo';
  align?: 'left' | 'center' | 'right';
  strikeThru?: boolean;
  underline?: boolean;
}

export default function Text({
  m = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mt = 0,
  p = 0,
  pb = 0,
  pl = 0,
  pr = 0,
  pt = 0,
  children,
  align = 'left',
  as = 'p',
  color = 'copyDark',
  link = false,
  copy = false,
  font = 'normal',
  size = 'base',
  strikeThru = false,
  underline = false,
  ...props
}: ParagraphProps) {

  const dynamicStyles = {
    ...(!!m && { margin: spacing.padding * m }),
    ...(!!mb && { marginBottom: spacing.padding * mb }),
    ...(!!ml && { marginLeft: spacing.padding * ml }),
    ...(!!mr && { marginRight: spacing.padding * mr }),
    ...(!!mt && { marginTop: spacing.padding * mt }),
    ...(!!p && { padding: spacing.padding * p }),
    ...(!!pb && { paddingBottom: spacing.padding * pb }),
    ...(!!pl && { paddingLeft: spacing.padding * pl }),
    ...(!!pr && { paddingRight: spacing.padding * pr }),
    ...(!!pt && { paddingTop: spacing.padding * pt }),
    ...(!!align && { textAlign: align })
  };

  const ParagraphTag = as;
  return (
    <ParagraphTag
      style={dynamicStyles}
      className={classNames(
        styles.paragraphContainer,
        styles[color],
        styles[font],
        styles[size],
        props.className,
        {
          [styles.link]: link,
          [styles.asCopy]: copy,
          [styles.strikeThru]: strikeThru,
          [styles.underline]: underline,
        }
      )}
      // Removed props.className from spreading to avoid duplication
      {...omit(props, ['className'])}
    >
      {children}
    </ParagraphTag>
  );
}

