import { useMemo } from 'react';
import Firebase from '@/clients/firebase/firebase-react-query';
import {
  addCartItemToFirestore,
  removeCartItemFromFirestore,
  updateFirestoreDoc,
} from '@/clients/firebase/client';
import { GroupedCartItem } from '@/clients/types';
import { Book, ICartItem, ProductAddedSource, ProductViewSource } from 'types';
import { ActionType } from '@/state/types';
import { useGlobalState } from '@/state';
import { usePathname, useSearchParams } from 'next/navigation';
import useAnalytics from './use-analytics';
import { analyticsBookData } from 'sdk';
import groupCartBySellerHelper from '@/lib/helpers/checkout/group-cart-by-seller';

interface ReplaceCartItemProps {
  oldItem: Book;
  newItem: Book;
}

export default function useCart() {
  const { state, dispatch } = useGlobalState();
  const { analyticEvents } = useAnalytics();

  const uid = state?.user?.data?.uid;

  const searchParams = useSearchParams();
  const pathname = usePathname();
  const tempCartId = searchParams?.get('tempId') && pathname?.startsWith('/cart') ? searchParams.get('tempId') : null;

  const {
    data: tempCartData
  } = Firebase<ICartItem>().fetchCollectionDocsByQuery(
    `cart/${uid}/temp_cart/${tempCartId}/temp_cart`,
    [],
    { enabled: Boolean(uid && tempCartId) }
  );

  const cart = useMemo(
    () => {
      if (tempCartId) return tempCartData?.length ? tempCartData : [];
      return state?.cart?.data?.items?.length ? state?.cart?.data?.items : [];
    },
    [state?.cart?.data?.items, tempCartData, tempCartId]
  );

  const cartCount = useMemo(() => (state?.cart?.data?.loaded ? cart.length : null), [state?.cart?.data, cart.length]);

  const cartTotal = useMemo(() => cart.reduce((count, item) => count + item.amount, 0), [cart]);

  const groupCartBySeller = () => {
    return groupCartBySellerHelper({ books: cart as Book[] }) as GroupedCartItem[];
  };

  const getCartWithSellerId = () => {
    if (!cartCount) {
      return [];
    }
    const cartWithSellerId: GroupedCartItem[] = [];
    cart.forEach((item) => {
      const { seller_id } = item;
      cartWithSellerId.push({ seller_id, items: [item] });
    });
    return cartWithSellerId;
  };

  const isInCart = (item?: Book) => {
    return Boolean(cart.find((cartItem) => item?.id === cartItem?.id));
  };

  const addToCart = async ({
    item,
    selectedListing,
    source,
  }: {
    item: Book;
    selectedListing?: boolean;
    source: ProductAddedSource | ProductViewSource;
  }) => {
    if (uid === item?.seller_id) return;
    if (!isInCart(item)) {
      try {
        await addCartItemToFirestore(item, uid);

        // dont show the modal in the cart
        if (!pathname?.includes('/cart')) {
          dispatch({
            type: ActionType.ADD_SNACK_DATA,
            payload: {
              title: `Added to your cart`,
              message: `${item.title}`
            },
          });
        }

        await analyticEvents.productAdded({
          product: analyticsBookData(item),
          source,
          selectedListing: selectedListing || false,
          index: 'books'
        });
      } catch (e: any) {
        throw new Error(e);
      }
    }
  };

  const removeFromCart = async (item: Book) => {
    if (isInCart(item)) {
      try {
        await removeCartItemFromFirestore(item, uid);

        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: `Removed from your cart`,
            message: `${item.title}`
          },
        });

        const source: ProductAddedSource = pathname?.includes('/cart') ? 'cart' : 'cart-nav';
        await analyticEvents.productRemoved({ product: analyticsBookData(item), source });
      } catch (e) {
        // *FIXME* handle error
      }
    }
  };

  const removeManyFromCart = async (items: Book[]) => {
    try {
      const promises: Promise<void>[] = [];
      for (const item of items) {
        if (isInCart(item)) {
          promises.push(removeCartItemFromFirestore(item, uid));
        }
      }
      await Promise.all(promises);
    } catch (e) {
      // handle error
    }
  };

  const markCartItemAsSold = async (item: Book, sold: boolean = true) => {
    try {
      await updateFirestoreDoc(`cart/${uid}/cart`, item.id, { sold });
      return;
    } catch (e) {
      // err
    }
  };

  const replaceCartItem = async ({ oldItem, newItem }: ReplaceCartItemProps) => {
    await removeCartItemFromFirestore(oldItem, uid);
    await addCartItemToFirestore(newItem, uid);
  };

  return {
    cart,
    cartCount,
    cartTotal,
    tempCartId,
    isInCart,
    addToCart,
    removeFromCart,
    removeManyFromCart,
    groupCartBySeller,
    markCartItemAsSold,
    getCartWithSellerId,
    replaceCartItem,
  };
}
