import { AlgoliaOptions, TypesenseSearchProps } from 'types';
import { algoliaToTypesenseOptions, mapQueryBy, mapSortBy } from './algolia-to-typesense-options';
import { parseUrl } from './helpers';

export function urlToTypesenseOptions({ url }: { url: string; }): TypesenseSearchProps {
  // convert from url to algolia options. Can remove this step if we change the url query structure
  const unknownOptions = parseUrl(url);
  if (!unknownOptions?.index) {
    return {
      index: 'books',
      params: {
        q: '',
        query_by: mapQueryBy('books'),
        sort_by: mapSortBy('books'),
        filter_by: '',
      },
      options: {}
    };
  }
  // decide if these are typesense or algolia options
  if (unknownOptions?.index && unknownOptions?.params) {
    return unknownOptions as TypesenseSearchProps;
  }
  // convert from algolia options to typesense options
  return algoliaToTypesenseOptions({ algoliaOptions: unknownOptions as AlgoliaOptions }) as TypesenseSearchProps;
}