import COLORS from '~/lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}
export default function DollarSignSVG({ fillColor = COLORS.green }: Props) {
  return (
    <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.186 0C5.012 0 0 5.012 0 11.186C0 17.36 5.012 22.372 11.186 22.372C17.36 22.372 22.372 17.36 22.372 11.186C22.358 5.012 17.36 0 11.186 0ZM9.87 9.618L13.244 10.794C14.462 11.228 15.05 12.082 15.05 13.398C15.05 14.91 13.846 16.156 12.376 16.156H12.25V16.226C12.25 16.8 11.774 17.276 11.2 17.276C10.626 17.276 10.15 16.8 10.15 16.226V16.142C8.596 16.072 7.35 14.77 7.35 13.146C7.35 12.572 7.826 12.096 8.4 12.096C8.974 12.096 9.45 12.572 9.45 13.146C9.45 13.65 9.814 14.056 10.262 14.056H12.362C12.684 14.056 12.936 13.762 12.936 13.398C12.936 12.908 12.852 12.88 12.53 12.768L9.156 11.592C7.952 11.172 7.35 10.318 7.35 8.988C7.35 7.476 8.554 6.23 10.024 6.23H10.15V6.174C10.15 5.6 10.626 5.124 11.2 5.124C11.774 5.124 12.25 5.6 12.25 6.174V6.258C13.804 6.328 15.05 7.63 15.05 9.254C15.05 9.828 14.574 10.304 14 10.304C13.426 10.304 12.95 9.828 12.95 9.254C12.95 8.75 12.586 8.344 12.138 8.344H10.038C9.716 8.344 9.464 8.638 9.464 9.002C9.45 9.478 9.534 9.506 9.87 9.618Z"
        fill={fillColor}
      />
    </svg>
  );
}