import { AnalyticsBookData, Book, Order } from 'types';

// Remove these types and refator if we actually need this
interface BookWithDoc extends Book {
  docSnap?: any;
  _highlightResult?: any;
}

interface OrderWithDoc extends Order {
  docSnap?: any;
}

export function cleanBookData(book: BookWithDoc) {
  if (book?.docSnap) {
    delete book.docSnap;
  }
  if (book?._highlightResult) {
    delete book._highlightResult;
  }
  // TODO add any more gnarly fields that need to be removed
  return book;
}

export function cleanOrderData(order: OrderWithDoc) {
  if (order.docSnap) {
    delete order.docSnap;
  }
  return order;
}

export function analyticsBookData(book: Book): AnalyticsBookData {
  const price = parseFloat((book.amount / 100).toFixed(2));
  // if a title_id exists we want to send the event to the titles index
  const index = book.title_id ? 'titles' : 'books';
  const objectIDs = book.title_id ? [book.title_id] : [book.id];
  return {
    price,
    value: price,
    title: book.title,
    amount: book.amount,
    seller_id: book.seller_id,
    currency: 'USD',
    id: book.id,
    category: book.genre?.display,
    author: book.authors?.[0]?.id || '',
    objectID: book.id,
    objectIDs,
    index,
    ...(book.title_id && { title_id: book.title_id })
  };
}
