import classNames from 'classnames';
import { Order } from 'types';
import styles from '../styles/components/status-badge.module.scss';
import moment from 'moment';

interface StatusBadgeProps {
  order: Order;
}

const calendarConfig = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[Last] dddd, MMM Do',
  nextWeek: 'dddd [at] LT',
  sameElse: 'L',
};

export default function StatusBadge(props: StatusBadgeProps) {
  const { order } = props;
  const { status } = order;

  let message = '';
  switch (status) {
    case 'purchased':
    case 'no_return_address':
    case 'shipment_failed':
    case 'shipment_created':
      message = 'Waiting shipment';
      break;
    case 'cancel_request':
      message = 'Cancel Request. Waiting for sellers response.';
      break;
    case 'canceled':
      message = 'Canceled';
      break;
    case 'shipment_confirmed':
      message = 'Seller confirmed shipment';
      break;
    case 'shipped':
      let ship_date =
        // @ts-ignore
        new Date((order?.seller_confirmed_shipment?.seconds || order?.seller_confirmed_shipment?._seconds) ||
          // @ts-ignore
          (order?.shipped_on?.seconds || order?.shipped_on?._seconds) * 1000);
      if (order?.shipped_on && order?.seller_confirmed_shipment) {
        // if they both exist, choose the earliest
        // @ts-ignore
        const diff = moment((order.seller_confirmed_shipment?.seconds || order.seller_confirmed_shipment?._seconds) * 1000).diff(
          // @ts-ignore
          new Date((order.shipped_on?.seconds || order.shipped_on?._seconds) * 1000)
        );
        if (diff <= 0) {
          // @ts-ignore
          ship_date = new Date((order.seller_confirmed_shipment?.seconds || order.seller_confirmed_shipment?._seconds) * 1000)
        } else {
          // @ts-ignore
          ship_date = new Date((order.shipped_on?.seconds || order.shipped_on?._seconds) * 1000)
        }
      }
      message = `Shipped ${ship_date ? moment(ship_date).calendar(null, calendarConfig) : ''
        }`;
      break;
    case 'delivered':
      message = `Delivered ${order?.delivered_on
        // @ts-ignore
        ? moment((order?.delivered_on?.seconds || order?.delivered_on?._seconds) * 1000).calendar(
          null,
          calendarConfig
        )
        : ''
        }`;
      break;
    default:
      break;
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.badge, {
          [styles['badge--shipment']]:
            status === 'shipment_created' ||
            status === 'shipment_failed' ||
            status === 'no_return_address' ||
            status === 'shipment_confirmed' ||
            status === 'purchased',
          [styles['badge--canceled']]:
            status === 'cancel_request' || status === 'canceled',
          [styles['badge--shipped']]: status === 'shipped',
          [styles['badge--delivered']]: status === 'delivered',
        })}
      />
      <div className={styles.text}>{message}</div>
    </div>
  );
}
