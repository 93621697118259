import { DocumentSchema, SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';
import { typesense } from './client';
import { TypesenseMultiSearchProps, TypesenseSearchProps } from 'types';
import { parseParams } from './parse-query';
import { MultiSearchResponse } from 'typesense/lib/Typesense/MultiSearch';
import { facetMap } from './helpers';

const mapFacets = (index: string) => {
  return facetMap[index] || '';
};

export async function search<D extends DocumentSchema>({
  index,
  params,
  options
}: TypesenseSearchProps): Promise<SearchResponse<D>> {
  params = parseParams(params as SearchParams, index);
  const res = await typesense
    .multiSearch
    .perform(
      {
        searches: [
          {
            collection: index,
            ...params,
            ...options,
          }
        ]
      },

    ) as unknown as MultiSearchResponse<D[]>;

  return res.results[0];
}

export async function searchWithFaceting<D extends DocumentSchema>({
  index,
  params,
  options
}: TypesenseSearchProps): Promise<SearchResponse<D>> {
  params = parseParams(params as SearchParams, index);

  const res = await typesense
    .multiSearch
    .perform(
      {
        searches: [
          {
            collection: index,
            per_page: 24,
            facet_by: mapFacets(index),
            max_facet_values: 250,
            ...params,
            ...options,
            page: params.page || 1
          },

        ],

      },

    ) as unknown as MultiSearchResponse<D[]>;

  return res.results[0];
}

export async function multiSearch<D extends DocumentSchema>({
  searchRequests,
  commonParams
}: TypesenseMultiSearchProps): Promise<MultiSearchResponse<D[]>> {
  searchRequests.searches = searchRequests.searches.map((searchRequest) => {
    searchRequest = parseParams(searchRequest as SearchParams, searchRequest.collection as string);
    return searchRequest;
  });
  return typesense
    .multiSearch
    .perform(
      searchRequests,
      commonParams
    ) as unknown as Promise<MultiSearchResponse<D[]>>;
}