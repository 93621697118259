import { motion } from 'framer-motion';

const animate = {
  fadeIn: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  fadeInUp: {
    initial: {
      y: 50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  },
  fadeInDown: {
    initial: {
      y: -50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: -50,
      opacity: 0,
    },
  },
};

interface AnimatedProps extends JSX.ElementChildrenAttribute {
  animation: 'fadeInUp' | 'fadeIn' | 'fadeInUpBig' | 'fadeInDown';
  duration: number;
}

export default function Animated({ animation = 'fadeInUp', duration = 0.2, children }: AnimatedProps) {
  return (
    <motion.div
      initial={animate[animation].initial}
      animate={animate[animation].animate}
      exit={animate[animation].exit}
      transition={{ duration }}
    >
      {children as any}
    </motion.div>
  );
}
