'use client';

import { QueryClient as ReactQueryClient, QueryClientProvider } from '@tanstack/react-query';

export default function QueryClient({ children }: { children: React.ReactNode; }) {
  const queryClient = new ReactQueryClient();

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

