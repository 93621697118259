import { Analytics } from '@vercel/analytics/react';
import QueryClient from '@/components/providers/query-client';
import { StateProvider } from '@/state';
import CartManager from '@/components/providers/cart-manager';
import GoogleTagManager from '@/components/providers/google-tag-manager';
import Modal from '@/components/modal';
import Segment from '@/components/providers/segment';
import ShelvesManager from '@/components/providers/shelves-manager';
import Snack from '@/components/snack';
import UserManager from '@/components/providers/user-manager';
import { SpeedInsights } from '@vercel/speed-insights/next';
import ABTestProvider from '@/state/ab-test';
import ReferrerTracking from './referrer-tracking';

export default function Providers({ children }: { children: React.ReactNode; }) {
  return (
    <StateProvider>
      <Segment />
      <GoogleTagManager />
      <QueryClient>
        <ABTestProvider>
          <UserManager key='user-manager' />
          <CartManager />
          <ShelvesManager />
          <ReferrerTracking />
          <Modal key='modal' />
          <Snack key='snack' />
          {children}
          <Analytics />
          <SpeedInsights />
        </ABTestProvider>
      </QueryClient>
    </StateProvider>
  );
}

