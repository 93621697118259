import { ButtonProps } from '../../components/button';
import { Action, ActionType, GlobalState } from '../types';

export interface ModalState {
  callback?: ButtonProps;
  /**
   * Use secondary callback for an addition button/callback that needs to be rendered within the Modal
   * If you find yourself needing more than two callbacks, you can pass in a custom components instead
   */
  secondaryCallback?: ButtonProps;
  showDismiss?: boolean;
  dismissText?: string;
  onModalClose?: any;
  active?: boolean;
  title?: string;
  message?: string;
  component?: JSX.Element;
  icon?: any;
  large?: boolean;
  persist?: boolean; // if true, modal will not close on click outside
  fullScreen?: boolean;
  hideCloseButton?: boolean;
  removeBottomPadding?: boolean;
}

const modalReducer = (
  state: GlobalState,
  action?: Action<ModalState>
): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_MODAL_DATA: {
      return {
        ...state,
        modal: {
          ...state.modal,
          data: {
            ...state.modal.data,
            active: true,
            ...payload,
          },
        },
      };
    }
    case ActionType.FLUSH_MODAL_DATA:
      return {
        ...state,
        modal: { data: { active: false } },
      };
    default:
      return state;
  }
};

export default modalReducer;
