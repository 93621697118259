import COLORS from "~/lib/helpers/color-helper";

interface Props {
  fillColor?: string;
  checkColor?: string;
  width?: string;
  height?: string;
}

export default function SnackSvgCheck({ fillColor = COLORS.primary, checkColor = 'white', width = '48', height = '48' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="8" fill={fillColor} />
      <path d="M24 14C18.49 14 14 18.49 14 24C14 29.51 18.49 34 24 34C29.51 34 34 29.51 34 24C34 18.49 29.51 14 24 14ZM28.78 21.7L23.11 27.37C22.97 27.51 22.78 27.59 22.58 27.59C22.38 27.59 22.19 27.51 22.05 27.37L19.22 24.54C18.93 24.25 18.93 23.77 19.22 23.48C19.51 23.19 19.99 23.19 20.28 23.48L22.58 25.78L27.72 20.64C28.01 20.35 28.49 20.35 28.78 20.64C29.07 20.93 29.07 21.4 28.78 21.7Z" fill={checkColor} />
      <path opacity="0.4" d="M5 13V9C5 6.79086 6.79086 5 9 5H13" stroke={checkColor} strokeWidth="1.5" strokeLinecap="round" />
      <path opacity="0.4" d="M43 35V39C43 41.2091 41.2091 43 39 43H35" stroke={checkColor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
