import { Action, ActionType, GlobalState } from '../types';

export interface WishlistState {
  ids: string[];
}

const wishlistReducer = (state: GlobalState, action?: Action<WishlistState>): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_WISHLIST_DATA: {
      if (!payload) return state;

      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          loading: false,
          data: payload || { ids: [] },
        },
      };
    }
    default:
      return state;
  }
};

export default wishlistReducer;
