export function removeItemFromArray(index: number, array: any[]) {
  return array.filter((_, i) => i !== index);
}

export function addItemToArray(array: any[], item: any) {
  return array.concat(item);
}

export function replaceItemInArray(array: any[], indexOfItemToReplace: number, replacedItem: any) {
  const newItemArray = array.slice();
  newItemArray[indexOfItemToReplace] = replacedItem;

  return newItemArray;
}

// Flattens an array of arrays to a single list
export function flatten(arr: any) {
  return [].concat(...arr);
}
