import { Action, ActionType, GlobalState } from '../types';

export interface ShelvesState {
  titleShelfIds: string[];
  preference: {
    pinned_copy: boolean;
    shelf: string;
    shelf_title: string;
  };
}

const shelvesReducer = (state: GlobalState, action?: Action<ShelvesState>): GlobalState => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case ActionType.ADD_SHELVES_DATA: {
      if (!payload) return state;

      return {
        ...state,
        shelves: {
          ...state.shelves,
          loading: false,
          data: payload || { titleShelfIds: [], preference: { pinned_copy: true, shelf: '', shelf_title: '' } },
        }
      };
    }
    default:
      return state;
  }
};

export default shelvesReducer;