import classNames from 'classnames';

import { DetailedHTMLProps, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import styles from '../../styles/components/form/input.module.scss';

export interface InputProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  children?: React.ReactNode;
  // TODO - check the usage of onChange in settings/index and if it returns a change event
  onChange: any;
  className?: string;
  message?: string;
  error?: string;
}

export default function TextArea(props: InputProps) {
  return (
    <div style={{ width: '100%' }}>
      <textarea
        {...props}
        className={classNames(props.className, styles.input, {
          [styles['input-disabled']]: props.disabled,
        })}
      />
      {props.message !== undefined && (
        <label className={styles['message-label']}>{props.message}</label>
      )}
      {props.error !== undefined && (
        <label className={styles['error-label']}>{props.error}</label>
      )}
    </div>
  );
}
