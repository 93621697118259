import { SavedSearchOptions, TypesenseSearchProps } from 'types';
import { SAVED_SEARCH_SORT_BY, mapQueryBy } from './algolia-to-typesense-options';
import { parseFilterString } from './helpers';
import { formatMoney } from '../../helpers/money';

export function cleanOptionsForSavedSearch({ typesenseOptions }: { typesenseOptions: TypesenseSearchProps; }) {
  const cleanedOptions: TypesenseSearchProps = {
    index: 'books',
    params: {
      q: typesenseOptions.params.q || '*',
      query_by: typesenseOptions.params.query_by || mapQueryBy('books'),
      sort_by: SAVED_SEARCH_SORT_BY,
      filter_by: typesenseOptions.params.filter_by || '',
    },
    options: {}
  };
  return cleanedOptions;
}

export function serializeTypesenseOptions({ typesenseOptions }: { typesenseOptions: TypesenseSearchProps; }) {
  const cleanedOptions = cleanOptionsForSavedSearch({ typesenseOptions });

  const index = 'books';
  const trimmedQuery = cleanedOptions.params.q?.trim()?.split(' ').join('')?.toLowerCase();
  const trimmedQueryBy = (cleanedOptions.params.query_by as string)?.trim()?.split(' ').join('');
  const trimmedSortBy = (cleanedOptions.params.sort_by as string)?.trim()?.split(' ').join('');
  const trimmedAndSortedFilters = parseFilterString(cleanedOptions.params.filter_by as string)
    .map(filter => `${filter.field}${filter.operator}${filter.value}`)
    .sort()
    .join('&&')
    .trim();

  const serializedOptions = `${index}-${trimmedQuery}-${trimmedQueryBy}-${trimmedSortBy}-${trimmedAndSortedFilters}`;

  const defaultSearchName = cleanedOptions?.params?.q !== '*' ? cleanedOptions?.params?.q : 'Filtered Search';

  return {
    serializedOptions,
    searchOptions: cleanedOptions,
    defaultSearchName
  };
}

export function trimAlgoliaOptionsForSavedSearch({ searchOptions }: { searchOptions?: any; }) {
  const newSearchOptions = {
    query: searchOptions?.query ?? '',
    facetFilters: (searchOptions?.facetFilters as string[][]) ?? [],
    numericFilters: (searchOptions?.numericFilters as string[]) ?? [],
    filters: searchOptions?.filters ?? ''
  };
  return newSearchOptions;
}

export function parseAlgoliaSavedSearch({ stringifiedSearchOptions }: { stringifiedSearchOptions: string; }) {

  let { facetFilters, numericFilters, query } = trimAlgoliaOptionsForSavedSearch({ searchOptions: JSON.parse(stringifiedSearchOptions) });

  const parsedFilterFacets: { [key: string]: string[]; } = {};

  facetFilters.forEach((filterArr: string[]) => {
    let key = '';
    const valueArr: string[] = [];
    filterArr.forEach((item: string) => {
      const splitItem = item.split(':');
      valueArr.push(splitItem[1]);
      key = splitItem[0].split('.')[0];
    });
    parsedFilterFacets[key] = valueArr;
  });

  let amount = { min: 0, max: 0 };

  if (typeof numericFilters === 'string') numericFilters = JSON.parse(numericFilters);
  if (numericFilters.length) {
    const numericArr: number[] = [];
    numericFilters.forEach((filter: string) => {
      let amount = filter.split(/[<|>|=]/);
      const amountNum = Number(amount[amount.length - 1]);
      numericArr.push(amountNum);
    });

    amount = numericArr[0] >= numericArr[1] ? { min: numericArr[1], max: numericArr[0] } : { min: numericArr[0], max: numericArr[1] };
  }

  const title_id = parsedFilterFacets.title_id?.length ? parsedFilterFacets.title_id?.[0] : '';
  const hashtags = parsedFilterFacets.hashtags ? parsedFilterFacets.hashtags : [];
  const format = parsedFilterFacets.format?.length ? parsedFilterFacets.format?.[0] : '';
  const genre = parsedFilterFacets.genre?.length ? parsedFilterFacets.genre?.[0] : '';
  const condition = parsedFilterFacets.condition ? parsedFilterFacets.condition : [];

  const savedSearch: SavedSearchOptions = {
    query: query as string,
    title_id,
    hashtags,
    format,
    genre,
    condition,
    amount,
    stringify: () => {
      const tags = parsedFilterFacets.hashtags ? parsedFilterFacets.hashtags.join(', ') : '';
      const condition = parsedFilterFacets.condition ? parsedFilterFacets.condition.join(', ') : '';
      const priceRange = amount.min && amount.max ? `${formatMoney(amount.min)} - ${formatMoney(amount.max)}` : '';
      let stringifiedSearch = `${tags && `${tags}, `}${genre && `${genre}, `}${format && `${format}, `}${condition && `${condition}, `}${priceRange && `${priceRange}`}`;
      // remove trailing comma and whitespace
      stringifiedSearch = stringifiedSearch.replace(/,\s*$/, "");
      return stringifiedSearch;
    },
    containsFilters: () => {
      return Boolean(Object.keys(parsedFilterFacets).length) || Boolean(amount.min) || Boolean(amount.max);
    }
  };

  return savedSearch;
}

export function parseTypesenseSavedSearch({ stringifiedSearchOptions }: { stringifiedSearchOptions: string; }) {
  const {
    params: {
      q,
      filter_by
    }
  } = cleanOptionsForSavedSearch({ typesenseOptions: JSON.parse(stringifiedSearchOptions) });

  const filters = parseFilterString(filter_by || '');

  const query = q?.replace('*', '');
  const title_id = filters?.find(filter => filter.field === 'title_id')?.value || '';
  const hashtags = filters?.filter(filter => filter.field === 'hashtags')?.map(filter => filter.value) || [];
  const format = filters?.find(filter => filter.field === 'format.display')?.value || '';
  const genre = filters?.find(filter => filter.field === 'genre.display')?.value || '';
  const condition = filters?.filter(filter => filter.field === 'condition')?.map(filter => filter.value) || [];
  const amount = {
    min: parseInt(filters?.find(filter => filter.field === 'amount')?.value?.split('..')[0] || '0')
      || parseInt(filters?.find(filter => filter.field === 'amount' && (filter.operator === ':>' || filter.operator === ':>='))?.value || '0')
      || 0,
    max: parseInt(filters?.find(filter => filter.field === 'amount')?.value?.split('..')[1] || '0')
      || parseInt(filters?.find(filter => filter.field === 'amount' && (filter.operator === ':<' || filter.operator === ':<='))?.value || '0')
      || 0,
  };

  const savedSearch: SavedSearchOptions = {
    query: query || '',
    title_id,
    hashtags,
    format,
    genre,
    condition,
    amount,
    stringify: () => {
      const hashtagString = hashtags?.length ? hashtags.join(', ') : '';
      const conditionString = condition?.length ? condition.join(', ') : '';
      const priceRange = amount.min && amount.max ? `${formatMoney(amount.min)} - ${formatMoney(amount.max)}` : '';
      const stringifiedSearch = `${hashtagString && `${hashtagString}, `}${genre && `${genre}, `}${format && `${format}, `}${conditionString && `${conditionString}, `}${priceRange && `${priceRange}`}`;
      return stringifiedSearch.replace(/,\s*$/, '');
    },
    containsFilters: () => {
      return Boolean(filters?.length);
    }
  };

  return savedSearch;
}

export function parsePossiblyStringifiedTypesenseOptions({ typesenseOptions }: { typesenseOptions: TypesenseSearchProps | string; }) {
  if (typeof typesenseOptions === 'string') {
    typesenseOptions = JSON.parse(typesenseOptions) as TypesenseSearchProps;
  }
  return typesenseOptions;
}

export function parsePossiblyStringifiedAlgoliaOptions({ algoliaOptions }: { algoliaOptions?: any; }) {
  if (typeof algoliaOptions?.options === 'string') {
    algoliaOptions.options = JSON.parse(algoliaOptions.options);
  }
  return algoliaOptions;
}