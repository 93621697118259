'use client';

import { useEffect } from 'react';
import PangoUser from '@/clients/pango';
import { useGlobalState } from '@/state';
import { ActionType } from '@/state/types';
import useAnalytics from '@/lib/hooks/use-analytics';
import { setUserToken } from '@/lib/session';
import { isBot } from '@/lib/helpers/is-bot';
import { UserState } from '@/state/reducers/user';
import Firebase from '@/clients/firebase/firebase-react-query';

export default function UserManager() {
  const { dispatch, state } = useGlobalState();
  const { analyticEvents, analyticsLoaded } = useAnalytics();

  const userData = state.user.data;

  const { data: ipAddressData } = Firebase<{ ipAddress: string; }>().backendFunction(
    'analytics-getIpAddress',
    {
      uid: userData.uid,
    }
  );

  useEffect(() => {
    const unsubscribe = PangoUser.onUserChange(async (user) => {
      if (user) {
        // logged in
        dispatch({
          type: ActionType.ADD_USER_DATA,
          payload: user,
        });
        if (user.isAnonymous) return;
        const token = await user.getIdToken();
        await setUserToken(token);

        const tokenResult = await user.getIdTokenResult();
        if (tokenResult?.claims?.admin) {
          dispatch({
            type: ActionType.ADD_USER_DATA,
            payload: { isAdmin: true } as any, // ok not to be full user object here since in the reducer it will be spread after the existing user object
          });
        }
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load any profile data changes and identify user to segment
  useEffect(() => {
    if (!userData.uid || !analyticsLoaded || !ipAddressData?.ipAddress) return;

    // dont send identify for bots
    const userAgent = window?.navigator?.userAgent;
    if (!userAgent || isBot({ userAgent })) {
      dispatch({
        type: ActionType.SET_SHOULD_TRACK,
        payload: { shouldTrack: false } as any,
      });
      return;
    }

    dispatch({
      type: ActionType.SET_SHOULD_TRACK,
      payload: { shouldTrack: true } as any,
    });

    if (userData.isAnonymous) {
      // log anonymous user identify event
      analyticEvents.identifyUser({ user: userData, anonymous: true, ipAddress: ipAddressData?.ipAddress });
      return;
    }

    const unsubscribe = PangoUser.onUserProfile(userData.uid, async (user) => {
      dispatch({
        type: ActionType.ADD_USER_DATA,
        payload: user as UserState,
      });

      // log identify user event with user data
      analyticEvents.identifyUser({ user: user as UserState, ipAddress: ipAddressData?.ipAddress });
    });

    const unsubscribe2 = PangoUser.onUserPrivate(userData.uid, async (userPrivate) => {
      dispatch({
        type: ActionType.ADD_USER_DATA,
        payload: userPrivate,
      });
    });

    return () => {
      if (unsubscribe) unsubscribe();
      if (unsubscribe2) unsubscribe2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.uid, analyticsLoaded, ipAddressData?.ipAddress]); // ignoring missing dependencies error here as including them causes infinite renders

  return null;
}
