import merge from 'deepmerge';
import qs from 'qs';

function mergeObjectsDeep(obj1: any, obj2: any) {
  return merge(obj1, obj2);
}

function transformIntoQueryString(params: any) {
  return qs.stringify(params);
}

function removeItemsWithNullValues(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null || v != undefined));
}

function getObjectFromString(str: string) {
  if (!str) return {};
  // find string with opening and closing curly brackets
  const objArray = str.match(/{.*}/g);
  if (!objArray?.length) return {};
  const objString = objArray[0];
  if (!objString) return {};
  try {
    const obj = JSON.parse(objString);
    return obj;
  } catch (e) {
    return {};
  }
}

function omit(obj: object, keysToOmit: string[]) {
  return Object.keys(obj).reduce((acc, key) => {
    if (!keysToOmit.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export {
  getObjectFromString,
  removeItemsWithNullValues,
  transformIntoQueryString,
  mergeObjectsDeep,
  omit
};