import { stringify } from 'qs';
const BASE_URL = 'https://images.pangobooks.com'; // use 'http://images-dev.pangobooks.com' to test sell flow on dev
const DEFAULT_IMAGE_QUALITY = 85;

/**
 * https://developer.fastly.com/reference/io/dpr
 */
interface FastlyTransformationParams {
  width?: number;
  height?: number;
  auto?: 'webp';
  blur?: number;
  crop?: 'tile' | 'square' | string;
  format?: 'gif' | 'png' | 'png8' | 'jpg' | 'jpeg' | 'pjpg' | 'bjpg' | 'webp' | 'webpll' | 'webply' | 'mp4';
  quality?: number;
}

export function photoUrl(photoPath: string, transformationParams?: FastlyTransformationParams) {
  if (!photoPath) return null;
  let transformations: any = transformationParams;

  if (transformations?.crop === 'square') {
    transformations.crop = '1:1';
  } else if (transformationParams?.crop === 'tile') {
    transformations.crop = '5:6';
  }

  const params = stringify(transformations);

  if (params) {
    return `${BASE_URL}/${photoPath}?${params}`;
  }

  return `${BASE_URL}/${photoPath}`;
}

export function images(photoPath: string) {
  return {
    book: {
      tiny: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        width: 150,
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'square',
      }),
      tile: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        height: 300,
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'tile',
      }),
      thumb: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        width: 300,
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'square',
      }),
    },
    imageCarousel: {
      main: photoUrl(photoPath, {
        width: 800,
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'square',
      }),
      thumb: photoUrl(photoPath, {
        width: 250,
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'square',
      }),
    },
    user: {
      profileImage: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        quality: DEFAULT_IMAGE_QUALITY,
        crop: 'square',
        width: 200,
      }),
    },
    seo: {
      ogImage: photoUrl(photoPath, {
        quality: DEFAULT_IMAGE_QUALITY,
        width: 1200,
        crop: 'square',
      }),
      twitterImage: photoUrl(photoPath, {
        quality: DEFAULT_IMAGE_QUALITY,
        width: 1200,
        crop: '28:15',
      }),
    },
    marketing: {
      blur: photoUrl(photoPath, {
        width: 300,
        blur: 10,
        quality: 60,
      }),
      default: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        width: 800,
        quality: DEFAULT_IMAGE_QUALITY,
      }),
      transparent: photoUrl(photoPath, {
        width: 800,
      }),
      hero: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        width: 1200,
        quality: DEFAULT_IMAGE_QUALITY,
      }),
      appScreenshot: photoUrl(photoPath, {
        auto: 'webp',
        format: 'webp',
        // width: 300,
        quality: DEFAULT_IMAGE_QUALITY,
      })
    },
  };
}

export const NO_IMAGE_PATH = 'images/no-image-placeholder.png';
