interface Props {
  fillColor?: string;
  strokeColor?: string;
}

export default function CartButtonEmptySVG({ fillColor = '#3B61EB', strokeColor = '#3B61EB' }: Props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill={fillColor} fillOpacity="0.05" />
      <path d="M17.0833 21.875C17.0833 23.475 18.3999 24.7917 19.9999 24.7917C21.5999 24.7917 22.9166 23.475 22.9166 21.875" stroke={strokeColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.3416 11.6665L14.325 14.6915" stroke={strokeColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.6582 11.6665L25.6749 14.6915" stroke={strokeColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6665 16.5417C11.6665 15 12.4915 14.875 13.5165 14.875H26.4832C27.5082 14.875 28.3332 15 28.3332 16.5417C28.3332 18.3333 27.5082 18.2083 26.4832 18.2083H13.5165C12.4915 18.2083 11.6665 18.3333 11.6665 16.5417Z" stroke={strokeColor} strokeWidth="1.5" />
      <path d="M12.9167 18.3335L14.0917 25.5335C14.3584 27.1502 15.0001 28.3335 17.3834 28.3335H22.4084C25.0001 28.3335 25.3834 27.2002 25.6834 25.6335L27.0834 18.3335" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}